import _ from 'lodash';
import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    multipartPostRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';
import { getAccountSettings } from '../maintenance';
const basePath = '/clients';

export const linkToOptions = {
    Client: 'Client',
    Residence: 'Residence',
};

export const getLinkToOptionLabel = (t, value) => {
    if (_.isNull(value)) {
        return '';
    }

    if (value.toLowerCase() === 'client') {
        return t('62608e08adc29a8d6dbc9754e659f125', 'Client');
    } else if (value.toLowerCase() === 'residence') {
        return t('cad4811efcdb1000aae7a6841d995713', 'Residence');
    } else {
        return '-';
    }
};

export const getCombinedName = (person) => {
    const nameParts = [];

    if (person?.prefixId?.text) {
        nameParts.push(person.prefixId.text);
    }

    if (person?.firstName) {
        nameParts.push(person.firstName);
    }

    if (person?.insertion) {
        nameParts.push(person.insertion);
    }

    if (person?.lastName) {
        nameParts.push(person.lastName);
    }

    return nameParts.join(' ');
};

export const getClientTitle = (client) => {
    let result = client?.person?.combinedName ?? '';

    const age = client?.age;
    if (_.isNumber(age)) {
        result += ` (${age})`;
    }

    return result;
};

export const getFullGroup = (client) => {
    let groups = [
        client?.group?.name,
        client?.groupId?.text,
        client?.subgroup?.name,
        client?.subgroupId?.text,
        client?.project?.name,
        client?.projectId?.text,
        client?.subproject?.name,
        client?.subprojectId?.text,
    ];

    return groups.filter((g) => !_.isNil(g) && !_.isEmpty(g)).join(' > ');
};

export const getAccountValidationMethod = async () => {
    const settings = await getAccountSettings();

    if (_.isNil(settings?.ssnExternalIdentifierType)) {
        return {};
    }

    const externalIdentifierType = settings.ssnExternalIdentifierType;
    const validationMethod = externalIdentifierType?.validationMethod;

    return {
        externalIdentifierTypeId: externalIdentifierType?.id,
        isUnique: externalIdentifierType?.isUnique,
        validationMethodId: validationMethod?.id ?? null,
        description: validationMethod?.description ?? null,
    };
};

export const getClientSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const saveClient = async (client, params) => {
    return postRequest(`${basePath}`, client, params);
};

export const patchClient = async (id, client) => {
    return patchRequest(`${basePath}/${id}`, client);
};

export const getClients = async (params) => {
    return getRequest(`${basePath}/`, params);
};

export const getClient = async (id) => {
    return getRequest(`${basePath}/${id}`);
};
export const getClientByUID = async (uid) => {
    return getRequest(`${basePath}/uid/${uid}`);
};

export const getClientPhoneNumbers = async (id) => {
    return getRequest(`${basePath}/${id}/phone-numbers`);
};

export const postClientPhoneNumber = async (id, number) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, number);
};

export const postLinkClientToDevice = async (clientId, deviceId, params) => {
    return postRequest(
        `${basePath}/${clientId}/devices/${deviceId}/link`,
        null,
        params
    );
};

export const getClientLinkToProfessionalCaregiver = async (
    clientId,
    professionalCaregiverId
) => {
    return getRequest(
        `${basePath}/${clientId}/professional-caregivers/${professionalCaregiverId}/link`
    );
};

export const postLinkClientToProfessionalCaregiver = async (
    clientId,
    professionalCaregiverId,
    data
) => {
    return postRequest(
        `${basePath}/${clientId}/professional-caregivers/${professionalCaregiverId}/link`,
        data
    );
};

export const putClientLinkToProfessionalCaregiver = async (
    clientId,
    professionalCaregiverId,
    data
) => {
    return putRequest(
        `${basePath}/${clientId}/professional-caregivers/${professionalCaregiverId}/link`,
        data
    );
};

export const postLinkClientToProfessionalCaregiverAsPersonal = async (
    clientId,
    professionalCaregiverId,
    data
) => {
    return postRequest(
        `${basePath}/${clientId}/professional-caregivers/${professionalCaregiverId}/link/personal`,
        data
    );
};

export const postClientPersonalCaregiver = async (clientId, data) => {
    return postRequest(`${basePath}/${clientId}/personal-caregivers`, data);
};

export const getClientLinkToCareteam = async (clientId, careTeamId) => {
    return getRequest(`${basePath}/${clientId}/care-teams/${careTeamId}/link`);
};

export const postLinkClientToCareTeam = async (clientId, careTeamId, data) => {
    return postRequest(
        `${basePath}/${clientId}/care-teams/${careTeamId}/link`,
        data
    );
};

export const putClientLinkToCareTeam = async (clientId, careTeamId, data) => {
    return putRequest(
        `${basePath}/${clientId}/care-teams/${careTeamId}/link`,
        data
    );
};

export const deleteUnlinkClientToProfessionalCaregiver = async (
    clientId,
    professionalCaregiverId
) => {
    return deleteRequest(
        `${basePath}/${clientId}/professional-caregivers/${professionalCaregiverId}/unlink`
    );
};

export const deleteUnlinkClientToCareTeam = async (clientId, careTeamId) => {
    return deleteRequest(
        `${basePath}/${clientId}/care-teams/${careTeamId}/unlink`
    );
};

export const getClientDevices = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/devices`);
};

export const postClientDefaultDevice = async (clientId, deviceId) => {
    return postRequest(`${basePath}/${clientId}/default-device/${deviceId}`);
};

export const getClientDocuments = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/documents`);
};

export const postClientDocument = async (
    clientId,
    file,
    cbProgress,
    abortController
) => {
    let form = new FormData();
    form.append('file', file);
    let customHeaders = {};
    return multipartPostRequest(
        `${basePath}/${clientId}/document`,
        form,
        customHeaders,
        cbProgress,
        abortController
    );
};

export const getClientDocumentUrl = (clientId, clientDocumentId, accountId) => {
    return `/api/clients/${clientId}/document/${clientDocumentId}?account_id=${accountId}`;
};

export const deleteClientDocument = async (clientId, clientDocumentId) => {
    return deleteRequest(
        `${basePath}/${clientId}/document/${clientDocumentId}`
    );
};

export const getClientNotes = async (clientId, params) => {
    return getRequest(`${basePath}/${clientId}/notes`, params);
};

export const postClientNote = async (clientId, note) => {
    return postRequest(`${basePath}/${clientId}/notes`, note);
};

export const getClientTags = async (clientId, params) => {
    return getRequest(`${basePath}/${clientId}/tags`, params);
};

export const postClientTag = async (clientId, tag) => {
    return postRequest(`${basePath}/${clientId}/tags`, tag);
};

export const getClientExternalIdentifiers = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/external-identifiers`);
};

export const postClientExternalIdentifier = async (
    clientId,
    externalIdentifier
) => {
    return postRequest(
        `${basePath}/${clientId}/external-identifiers`,
        externalIdentifier
    );
};

export const getClientSubscriptions = async (clientId, params) => {
    return getRequest(`${basePath}/${clientId}/subscriptions`, params);
};

export const getClientSubscription = async (clientId, clientSubscriptionId) => {
    return getRequest(
        `${basePath}/${clientId}/subscriptions/${clientSubscriptionId}`
    );
};

export const postClientSubscriptionValidate = async (clientSubscription) => {
    if (!_.isNil(clientSubscription.clientSubscriptionLinkId)) {
        return postRequest(
            `${basePath}/${clientSubscription.clientId}/subscriptions/${clientSubscription.clientSubscriptionLinkId}/validate-link`,
            clientSubscription
        );
    } else {
        return postRequest(
            `${basePath}/${clientSubscription.clientId}/subscriptions/${clientSubscription.subscriptionId}/validate`,
            clientSubscription
        );
    }
};

export const postClientSubscription = async (clientSubscription) => {
    return postRequest(
        `${basePath}/${clientSubscription.clientId}/subscriptions/${clientSubscription.subscriptionId}`,
        clientSubscription
    );
};

export const putClientSubscription = async (clientSubscription) => {
    return putRequest(
        `${basePath}/${clientSubscription.clientId}/subscriptions/${clientSubscription.id}`,
        clientSubscription
    );
};

export const deleteClientSubscription = async (
    clientId,
    clientSubscriptionId
) => {
    return deleteRequest(
        `${basePath}/${clientId}/subscriptions/${clientSubscriptionId}`
    );
};

export const postClientGroupSubscriptionsApply = async (data) => {
    return postRequest(
        `${basePath}/${data.clientId}/group/subscriptions/apply`,
        data
    );
};

export const getClientPersonalCaregivers = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/personal-caregivers`);
};

export const getClientProfessionalCaregivers = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/professional-caregivers`);
};

export const getClientAllMedicalData = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/medical-data`);
};

export const postClientMedicalData = async (clientId, medicalData) => {
    return postRequest(`${basePath}/${clientId}/medical-data`, medicalData);
};

export const deleteClientMedicalData = async (clientMedicalDataId) => {
    return deleteRequest(`${basePath}/medical-data/${clientMedicalDataId}`);
};

export const getClientMedicines = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/medicines`);
};

export const postClientMedicine = async (clientId, medicine) => {
    return postRequest(`${basePath}/${clientId}/medicines`, medicine);
};

export const deleteClientMedicine = async (clientMedicineId) => {
    return deleteRequest(`${basePath}/medicines/${clientMedicineId}`);
};

export const getClientStateHistory = async (clientId) => {
    return getRequest(`${basePath}/${clientId}/state-history`);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'clients'),
};

export const clientSearchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'clients'),
};

export const deleteUnlinkCientFromDevice = (clientId, deviceId, params) => {
    return deleteRequest(
        `${basePath}/${clientId}/devices/${deviceId}/unlink`,
        params
    );
};
