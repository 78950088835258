import { getPeripheral } from '../../../../features';
import { redirectPageLoaderError } from '../../../../features/utils/loader';

export const pageLoader = async ({ params }) => {
    try {
        return await getPeripheral(params.peripheralId);
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};
