import { forwardRef, useEffect, useRef, useState } from 'react';
import {
    EdsCheckbox,
    EdsDatePicker,
    EdsDropdown,
    EdsDropdownType,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
    EdsTextArea,
    EdsTextInput,
} from '../../../eds';
import { useTranslation } from 'react-i18next';
import {
    getDate,
    getGroupSubscription,
    getGroupSubscriptions,
    getSubscriptions,
    isValidDate,
    postClientSubscription,
    postClientSubscriptionValidate,
    putClientSubscription,
    toJSDate,
    useNotifications,
} from '../../../../features';
import { CheckboxGroup, Row } from '@carbon/react';
import _ from 'lodash';
import { DateTime } from 'luxon';

const UmoSubscriptionForm = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { showSuccess, showError } = useNotifications();
    const endDateRef = useRef(null);
    const remarkRef = useRef(null);
    const [isGroupSubscription, setIsGroupSubscription] = useState(false);
    const [startDate, setStartDate] = useState(
        props?.initValues?.startDate?.value ?? ''
    );
    const [endDate, setEndDate] = useState(
        props?.initValues?.endDate?.value ?? ''
    );

    const [subscriptionData, setSubscriptionData] = useState({
        description: props?.initValues?.subscriptionId?.description ?? '',
        isOneTimeCost:
            props?.initValues?.subscriptionId?.isOneTimeCost ?? undefined,
        remark: props?.initValues?.subscriptionId?.remark ?? '',
    });

    const subscriptionMappingCallback = (data) => {
        return {
            id: `${data.id}`,
            text: data.name,
            description: data.description,
            isOneTimeCost: data.isOneTimeCost,
        };
    };

    const getApplicableSubscriptions = async () => {
        let applicableSubscriptions = [];
        if (!_.isNil(props?.groupId)) {
            applicableSubscriptions = await getGroupSubscriptions(
                props?.groupId
            );
        }

        setIsGroupSubscription(!_.isEmpty(applicableSubscriptions));

        if (_.isEmpty(applicableSubscriptions)) {
            applicableSubscriptions = await getSubscriptions({
                isActive: true,
            });
        }

        return applicableSubscriptions;
    };

    const validateSubscription = async (testContext, field) => {
        let linkId = props?.initValues?.id?.value;
        let subscriptionId =
            testContext?.options?.context?.form?.subscriptionId?.id;
        let startDate = testContext?.options?.context?.form?.startDate;
        let endDate = testContext?.options?.context?.form?.endDate;

        if (_.isNil(subscriptionId) && _.isNil(linkId)) {
            return true;
        }

        let response = await postClientSubscriptionValidate({
            clientSubscriptionLinkId: linkId,
            clientId: props.clientId,
            subscriptionId: subscriptionId,
            startDate: toJSDate(startDate, true),
            endDate: toJSDate(endDate, true),
        });

        if (_.isArray(response) && _.isEmpty(response)) {
            return true;
        }

        if (field === 'subscriptionId') {
            if (
                _.findIndex(response, [
                    'code',
                    'B1132', // The client already has the selected subscription linked
                ]) !== -1
            ) {
                return testContext.createError({
                    message: t(
                        'a5600b675a48cdbf00fd3ba6a1618fa9',
                        'The client already has the selected subscription linked'
                    ),
                });
            }
        } else if (field === 'startDate' || field === 'endDate') {
            if (
                _.findIndex(response, [
                    'code',
                    'B1097', // Start date must be before end date
                ]) !== -1
            ) {
                return testContext.createError({
                    message: t(
                        '4f5794d9035f48e0a041c98b1add9971',
                        'Start date must be before end date'
                    ),
                });
            }
        }

        return true;
    };

    const formDefinition = {
        subscriptionId: {
            value: { id: undefined, text: '' },
            validation: {
                type: 'dropdown',
                required: true,
                tests: [
                    {
                        name: 'subscription-id',
                        message: '', // Custom error using testContext.createError(...)
                        func: async (value, testContext) => {
                            return await validateSubscription(
                                testContext,
                                'subscriptionId'
                            );
                        },
                    },
                ],
            },
            mapping: 'id',
            dependents: ['startDate', 'endDate'],
        },
        startDate: {
            value: getDate(),
            validation: {
                type: 'datepicker',
                required: false,
                tests: [
                    {
                        name: 'subscription-start-date',
                        message: '', // Custom error using testContext.createError(...)
                        func: async (value, testContext) => {
                            return await validateSubscription(
                                testContext,
                                'startDate'
                            );
                        },
                    },
                ],
            },
        },
        endDate: {
            value: '',
            validation: {
                type: 'datepicker',
                required: false,
                tests: [
                    {
                        name: 'subscription-end-date',
                        message: '', // Custom error using testContext.createError(...)
                        func: async (value, testContext) => {
                            return await validateSubscription(
                                testContext,
                                'endDate'
                            );
                        },
                    },
                ],
            },
        },
        remark: {
            validation: {
                required: false,
            },
        },
        cost: {
            validation: {
                type: 'cost',
                required: false,
            },
        },
    };

    const saveData = async (form) => {
        form.clientId = props.clientId;

        if (isOneTimeCost()) {
            form.endDate = null;
        }

        if (!form.id) {
            return await postClientSubscription(form);
        } else {
            return await putClientSubscription(form);
        }
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        try {
            const response = await saveData(form);
            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: t(
                            'b48b13e73a6ac2a86dc54425dd24d9ff',
                            'Subscription'
                        ),
                        name: response?.subscription?.name,
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    useEffect(() => {
        if (!_.isNil(remarkRef?.current)) {
            remarkRef.current.updateText(subscriptionData.remark);
        }

        if (
            !_.isNil(endDateRef?.current) &&
            !isOneTimeCost() &&
            isEndDateBeforeStartDate()
        ) {
            endDateRef.current.updateDate('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData]);

    const isEndDateBeforeStartDate = () => {
        if (!isValidDate(startDate) || !isValidDate(endDate)) {
            return false;
        }

        return DateTime.fromJSDate(endDate) < DateTime.fromJSDate(startDate);
    };

    const isOneTimeCost = () => {
        return (
            _.isBoolean(subscriptionData?.isOneTimeCost) &&
            subscriptionData?.isOneTimeCost
        );
    };

    const hasId = () => {
        return !_.isNil(props?.initValues?.id);
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            name="subscriptionId"
                            label={t(
                                'b48b13e73a6ac2a86dc54425dd24d9ff',
                                'Subscription'
                            )}
                            type={EdsDropdownType.ComboBox}
                            readOnly={hasId()}
                            getDataCallback={getApplicableSubscriptions}
                            mappingCallback={subscriptionMappingCallback}
                            onChangeCallback={async (event) => {
                                const selectedItem = event?.selectedItem;
                                if (
                                    _.isNil(selectedItem?.id) ||
                                    _.isEmpty(selectedItem?.id)
                                ) {
                                    if (
                                        !_.isEmpty(
                                            subscriptionData.description
                                        ) ||
                                        !_.isUndefined(
                                            subscriptionData.isOneTimeCost
                                        ) ||
                                        !_.isEmpty(subscriptionData.remark)
                                    ) {
                                        setSubscriptionData({
                                            description: '',
                                            isOneTimeCost: undefined,
                                            remark: '',
                                        });
                                    }
                                    return;
                                }

                                let remark = selectedItem?.remark;

                                if (
                                    _.isUndefined(remark) &&
                                    isGroupSubscription &&
                                    selectedItem?.id
                                ) {
                                    const groupSubscription =
                                        await getGroupSubscription(
                                            props?.groupId,
                                            selectedItem?.id
                                        );
                                    remark = groupSubscription?.remark;
                                }

                                setSubscriptionData({
                                    description:
                                        selectedItem?.description ?? '',
                                    isOneTimeCost:
                                        selectedItem?.isOneTimeCost ?? false,
                                    remark: remark ?? '',
                                });
                            }}
                        />
                    </EdsFormColumn>
                    <EdsFormColumn>
                        <EdsTextInput
                            name="subscriptionDescription"
                            label={t(
                                '67daf92c833c41c95db874e18fcb2786',
                                'Description'
                            )}
                            uncontrolledValue={subscriptionData?.description}
                            readOnly={true}
                        />
                    </EdsFormColumn>
                </Row>
                <Row>
                    <EdsFormColumn />
                    <EdsFormColumn>
                        <CheckboxGroup legendText="">
                            <EdsCheckbox
                                name="isOneTimeCost"
                                labelText={t(
                                    'ac8e0cbd39060b0c224fc2394da64275',
                                    'One-time cost'
                                )}
                                uncontrolledValue={
                                    subscriptionData?.isOneTimeCost
                                }
                                readOnly={true}
                            />
                        </CheckboxGroup>
                    </EdsFormColumn>
                </Row>
                <Row>
                    <EdsFormColumn>
                        <EdsDatePicker
                            name={'startDate'}
                            label={t(
                                '46188e80453520b55cd82a67950f660e',
                                'Start date'
                            )}
                            maxDate={
                                !isOneTimeCost() && isValidDate(endDate)
                                    ? toJSDate(endDate)
                                    : ''
                            }
                            onChangeCallback={(date) => {
                                setStartDate(date ?? '');
                            }}
                        />
                    </EdsFormColumn>
                    {!isOneTimeCost() && (
                        <EdsFormColumn>
                            <EdsDatePicker
                                ref={endDateRef}
                                name={'endDate'}
                                label={t(
                                    'f49c17d504a2e8f8e29454a6d6ce9ecd',
                                    'End date'
                                )}
                                minDate={toJSDate(startDate)}
                                onChangeCallback={(date) => {
                                    setEndDate(date ?? '');
                                }}
                            />
                        </EdsFormColumn>
                    )}
                </Row>
                {isOneTimeCost() && (
                    <Row>
                        <EdsFormColumn>
                            <EdsNumberInput
                                name="cost"
                                label={t(
                                    '4e1566f0798fb3d6f350720cacd74446',
                                    'Cost'
                                )}
                                cost={true}
                            />
                        </EdsFormColumn>
                    </Row>
                )}
                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsTextArea
                            ref={remarkRef}
                            name="remark"
                            label={t(
                                '911c185c8f3c475cdca2ef9cf12166da',
                                'Remark'
                            )}
                            enableCounter={true}
                            maxCount={formDefinition.remark.validation.max}
                            rows={2}
                        ></EdsTextArea>
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});
UmoSubscriptionForm.displayName = 'UmoSubscriptionForm';

export default UmoSubscriptionForm;
