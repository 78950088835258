import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/professional-caregivers';

export const getProfessionalCaregiverSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const postProfessionalCaregiver = async (data) => {
    return postRequest(`${basePath}`, data);
};

export const putProfessionalCaregiver = async (data) => {
    return putRequest(`${basePath}/${data.id}`, data);
};

export const patchProfessionalCaregiver = async (id, data) => {
    return patchRequest(`${basePath}/${id}`, data);
};

export const getProfessionalCaregiver = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const getProfessionalCaregiverNotes = async (id, params) => {
    return getRequest(`${basePath}/${id}/notes`, params);
};

export const postProfessionalCaregiverNote = async (id, data) => {
    return postRequest(`${basePath}/${id}/notes`, data);
};

export const getProfessionalCaregiverTags = async (id, params) => {
    return getRequest(`${basePath}/${id}/tags`, params);
};

export const postProfessionalCaregiverTag = async (id, tag) => {
    return postRequest(`${basePath}/${id}/tags`, tag);
};

export const getProfessionalCaregiverPhoneNumbers = async (id) => {
    return getRequest(`${basePath}/${id}/phone-numbers`);
};

export const postProfessionalCaregiverPhoneNumber = async (id, data) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, data);
};

export const getProfessionalCaregiverPersonalCaregiverClients = async (id) => {
    return getRequest(`${basePath}/${id}/personal-caregiver-clients`);
};

export const getProfessionalCaregiverClients = async (id) => {
    return getRequest(`${basePath}/${id}/clients`);
};

export const getProfessionalCaregiverCareTeams = async (id) => {
    return getRequest(`${basePath}/${id}/care-teams`);
};

export const getProfessionalCaregiverResidences = async (id) => {
    return getRequest(`${basePath}/${id}/residences`);
};

export const getProfessionalCaregiverSchemes = async (id) => {
    return getRequest(`${basePath}/${id}/schemes`);
};

export const getProfessionalCaregiverHolidayLinks = async (id, params) => {
    return getRequest(`${basePath}/${id}/holiday-links`, params);
};

export const postProfessionalCaregiverHolidayLink = async (id, data) => {
    return postRequest(`${basePath}/${id}/holiday-links`, data);
};

export const getProfessionalCaregiverAvailability = async (id, params) => {
    return getRequest(`${basePath}/${id}/availabilities`, params);
};

export const postProfessionalCaregiverAvailability = async (id, data) => {
    return postRequest(`${basePath}/${id}/availabilities`, data);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'professional-caregivers'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'professional-caregivers'),
};
