import { DateTime } from 'luxon';

export const getDateTime = (value) => {
    return DateTime.fromISO(value).toJSDate().toJSON();
};

export const getDateRangeFrom = (value) => {
    return DateTime.fromISO(value)
        .setZone('utc', {
            keepLocalTime: true,
        })
        .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
        .toISO();
};

export const getDateRangeTo = (value) => {
    return DateTime.fromISO(value)
        .setZone('utc', {
            keepLocalTime: true,
        })
        .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
        })
        .toISO();
};
