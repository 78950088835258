import { Row } from '@carbon/react';
import {
    EdsFileUpload,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    FileUploadStatus,
} from '../../..';
import { forwardRef, useState } from 'react';
import { getLogger, useNotifications } from '../../../../features';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const logger = getLogger('DeviceTypeImageForm');

const UmoDocumentForm = forwardRef((props, ref) => {
    const { showSuccess, showError } = useNotifications();
    const [filenameStatus, setFilenameStatus] = useState(FileUploadStatus.Edit);
    const { t } = useTranslation();
    const formDefinition = {
        document: {
            validation: {
                type: 'files',
                required: true,
                maxCount: 1,
                maxFileSize: 5000000,
                allowedTypes: props.accept,
            },
        },
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }

        try {
            logger.log('File:', form.document);
            setFilenameStatus(FileUploadStatus.Uploading);
            const abortController = new AbortController();
            const response = await props.postDataCallback(
                props.entity.id,
                form.document[0],
                (percentCompleted, remainingTime) => {
                    logger.log(
                        `File upload progress: completed: ${percentCompleted}% remaining: ${remainingTime}`
                    );
                },
                abortController
            );
            setFilenameStatus(FileUploadStatus.Complete);

            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '484b4c163b57b73647002eaad865133e',
                    "{{entity}} of '{{name}}' is saved",
                    {
                        entity: t(
                            'fdc3bdefb79cec8eb8211d2499e04704',
                            'Document'
                        ),
                        name:
                            props.entity.person?.combinedName ||
                            props.entity.name,
                    }
                ),
            });

            props.tableRef?.current?.fetchInitData();

            return response;
        } catch (error) {
            setFilenameStatus(FileUploadStatus.Edit);

            let errors = error?.response?.data?.errors;
            if (
                !_.isEmpty(errors) &&
                _.isArray(errors) &&
                _.findIndex(errors, ['code', 'B1212']) !== -1
            ) {
                // <Entity> document with the same file name already exists
                let filename = form.document[0].name;
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        '2bae1b96103a2d821115bb11d5196986',
                        "Document with filename '{{filename}}' already exists",
                        { filename }
                    ),
                });
            } else {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });
            }

            throw error;
        }
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={{}}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsFileUpload
                            name="document"
                            description={t(
                                '7e81dd7af7a76c9a840d100c90502a33',
                                'Max file size is 5MB. Only .jpg and .png images, PDFs and speadsheets are supported.'
                            )}
                            filenameStatus={filenameStatus}
                            multiple={false}
                            accept={props.accept}
                        />
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});

UmoDocumentForm.displayName = 'UmoDocumentForm';

export default UmoDocumentForm;
