import { getGroup } from '../../../../../features';
import { redirectPageLoaderError } from '../../../../../features/utils/loader';

export const pageLoader = async ({ params }) => {
    try {
        return await getGroup(params.groupId);
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};
