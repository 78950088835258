import { redirect } from 'react-router-dom';
import { getRefererLoginUrl } from './referer-redirect';

export const redirectPageLoaderError = (error) => {
    if (error?.response?.status === 401) {
        return redirect(getRefererLoginUrl());
    } else {
        return redirect('..');
    }
};
