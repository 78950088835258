import { getRequest, postRequest } from '../api';

const basePath = '/address';

export const getAddressValidate = async (address) => {
    return getRequest(`${basePath}/validate`, address);
};

export const postReplaceDistrict = async (fromDistrict, toDistrict) => {
    return postRequest(
        `${basePath}/replace-district/from/${fromDistrict}/to/${toDistrict}`
    );
};

export const postReplaceCity = async (fromCity, toCity) => {
    return postRequest(
        `${basePath}/replace-city/from/${fromCity}/to/${toCity}`
    );
};

export const postReplaceRegion = async (fromRegion, toRegion) => {
    return postRequest(
        `${basePath}/replace-region/from/${fromRegion}/to/${toRegion}`
    );
};

export const postReplaceCountry = async (fromCountry, toCountry) => {
    return postRequest(
        `${basePath}/replace-country/from/${fromCountry}/to/${toCountry}`
    );
};
