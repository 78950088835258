import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/care-teams';

export const getCareTeamSearch = async (params) => {
    return getRequest(`${basePath}/search`, params);
};

export const getCareTeam = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const postCareTeam = async (data) => {
    return postRequest(`${basePath}`, data);
};

export const putCareTeam = async (data) => {
    return putRequest(`${basePath}/${data.id}`, data);
};

export const patchCareTeam = async (id, data) => {
    return patchRequest(`${basePath}/${id}`, data);
};

export const getCareTeamPhoneNumbers = async (id) => {
    return getRequest(`${basePath}/${id}/phone-numbers`);
};

export const postCareTeamPhoneNumber = async (id, data) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, data);
};

export const getCareTeamNotes = async (id) => {
    return getRequest(`${basePath}/${id}/notes`);
};

export const postCareTeamNote = async (id, data) => {
    return postRequest(`${basePath}/${id}/notes`, data);
};

export const getCareTeamTags = async (id) => {
    return getRequest(`${basePath}/${id}/tags`);
};

export const postCareTeamTag = async (id, data) => {
    return postRequest(`${basePath}/${id}/tags`, data);
};

export const getCareTeamHolidayLinks = async (id, params) => {
    return getRequest(`${basePath}/${id}/holiday-links`, params);
};

export const postCareTeamHolidayLink = async (id, data) => {
    return postRequest(`${basePath}/${id}/holiday-links`, data);
};

export const getCareTeamAvailability = async (id, params) => {
    return getRequest(`${basePath}/${id}/availabilities`, params);
};

export const postCareTeamAvailability = async (id, data) => {
    return postRequest(`${basePath}/${id}/availabilities`, data);
};

export const getCareTeamClients = async (id) => {
    return getRequest(`${basePath}/${id}/clients`);
};

export const getCareTeamResidences = async (id) => {
    return getRequest(`${basePath}/${id}/residences`);
};

export const getCareTeamSchemes = async (id) => {
    return getRequest(`${basePath}/${id}/schemes`);
};

export const getCareTeamMembers = async (id) => {
    return getRequest(`${basePath}/${id}/members`);
};

export const getCareTeamMember = async (id) => {
    return getRequest(`${basePath}/members/${id}`);
};

export const postCareTeamMember = async (data) => {
    return postRequest(`${basePath}/members`, data);
};

export const putCareTeamMember = async (data) => {
    return putRequest(`${basePath}/members/${data.id}`, data);
};

export const deleteCareTeamMember = async (id) => {
    return deleteRequest(`${basePath}/members/${id}`);
};

export const postCareTeamValidate = async (params) => {
    return postRequest(`${basePath}/validate`, params);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'care-teams'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'care-teams'),
};
