import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
} from '../../../../components';
import { useTranslation } from 'react-i18next';
import { Row } from '@carbon/react';
import {
    defaultDropdownMappingCallback,
    getTagCategories,
    getTags,
} from '../../../../features';
import { useEffect, useRef, useState } from 'react';
import { useEffectOnMount } from '../../../../features/react/hooks';
import _ from 'lodash';

export function EdsFormGroupTag(props) {
    const [formDefinition, setFormDefinition] = useState([]);
    const { t } = useTranslation();
    const [categoryId, setCategoryId] = useState();

    const tagRef = useRef(null);

    useEffectOnMount(async () => {
        setFormDefinition(await getFormDefinition());
    });

    const getFormDefinition = async () => {
        return {
            ...(!_.isUndefined(props.wizardStepId) && {
                wizardStepId: props.wizardStepId,
            }),

            categoryId: {
                value: props.selectedValues?.tagCategory,
                validation: {
                    type: 'dropdown',
                    required: false,
                },
                mapping: 'id',
            },
            tagId: {
                value: props.selectedValues?.tag,
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'id',
            },
        };
    };

    const updateTags = async () => {
        const tags = [];
        let tagsResponse = null;
        if (_.isNumber(categoryId)) {
            tagsResponse = await getTags(categoryId, {
                IsActive: true,
            });
        }
        if (_.isArray(tagsResponse)) {
            tagsResponse.map((data) => {
                tags.push(defaultDropdownMappingCallback(data));
            });
        }
        tagRef.current?.updateItems(tags);
    };

    useEffect(() => {
        // Update tags on category id change
        updateTags();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryId]);

    return (
        <EdsFormGroup
            label={props.label ?? t('e4d23e841d8e8804190027bce3180fa5', 'Tag')}
            prefix={props.prefix}
            formDefinition={formDefinition}
            inStep={props.wizardStepId ?? false}
        >
            <Row>
                <EdsFormColumn>
                    <EdsDropdown
                        name={'categoryId'}
                        label={t(
                            'e22cbb5177c24c788f7e5bcd54b5ed79',
                            'Tag category'
                        )}
                        type={EdsDropdownType.ComboBox}
                        getDataCallback={() => {
                            if (_.isFunction(props.getDataCallback)) {
                                return props.getDataCallback();
                            }
                            return getTagCategories({
                                IsActive: true,
                            });
                        }}
                        onChangeCallback={(event) => {
                            setCategoryId(event.selectedItem?.id);
                        }}
                    ></EdsDropdown>
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        ref={tagRef}
                        name={'tagId'}
                        label={t('e4d23e841d8e8804190027bce3180fa5', 'Tag')}
                        type={EdsDropdownType.ComboBox}
                        disabledOnEmpty={true}
                    ></EdsDropdown>
                </EdsFormColumn>
            </Row>
        </EdsFormGroup>
    );
}
