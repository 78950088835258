import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import './eds-table.scss';
import {
    DataTable,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar,
    TableToolbarContent,
    Button,
    ProgressBar,
    TableSelectAll,
} from '@carbon/react';
import { Add, Filter } from '@carbon/icons-react';
import {
    EdsTableActionbar,
    EdsActionButtonTypes,
    EdsLoading,
    EdsSearch,
    EdsTableRow,
    EdsFilterToolbar,
    useTableFilter,
    EdsTableFilterProvider,
    EdsTableFilterForm,
    EdsTableFilterType,
    EdsTooltipIcon,
    urlEncodeFilters,
    urlDecodeFilters,
    EdsLink,
    EdsOptionalLink,
    EdsTableFilterAlarmCriteriaType,
} from '../';
import { useTranslation } from 'react-i18next';
import {
    availablePageSizes,
    debounce,
    defaultPageSize,
    getLogger,
    useModal,
    useNotifications,
    usePermissions,
} from '../../../features';
import {
    useEffectOnMount,
    useEffectOnUpdate,
} from '../../../features/react/hooks';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { addFilterQuery } from '../../../features/utils/odata';
import { EdsOverFlowMenu } from '../eds-overflow-menu/eds-overflow-menu';

const logger = getLogger('EdsTable');

export const EdsSortDirection = Object.freeze({
    None: 'NONE',
    Ascending: 'ASC',
    Descending: 'DESC',
});

const EdsTableInternal = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { showError } = useNotifications();
    const { showFormModal } = useModal();
    const {
        filters,
        searchTerm,
        sorting,
        addFilter,
        addFilters,
        editFilter,
        setSavedFilters,
        setSearchTerm,
        setSorting,
    } = useTableFilter();
    const [headers] = useState(props.headers ?? []);
    const [searchableHeaders, setSearchableHeaders] = useState([]);
    const { isAllowed } = usePermissions();

    const formRef = useRef(null);

    const [initialLoaded, setInitialLoaded] = useState(false);
    const [initDataFetched, setInitDataFetched] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    let searchQuery = searchParams.get('q');
    let searchPage = parseInt(searchParams.get('p'));
    let searchPageSize = parseInt(searchParams.get('a'));
    let searchSortField = searchParams.get('s');
    let searchSortDirection = searchParams.get('d');
    let searchFilters = searchParams.get('f');

    const [previousPage, setPreviousPage] = useState();
    const [currentPage, setCurrentPage] = useState(
        !isNaN(searchPage) ? searchPage : 1
    );
    const [pageSize, setPageSize] = useState(
        !isNaN(searchPageSize) && availablePageSizes.includes(searchPageSize)
            ? searchPageSize
            : defaultPageSize
    );
    const [title] = useState('');
    const [rows, setRows] = useState();
    const [actionButtons, setActionButtons] = useState([]);

    //takes rows as input and can be modified by EdsTable
    const [filteredRows, setFilteredRows] = useState([]);

    //never set.  its taken care of by useEffect filteredRows
    const [totalItems, setTotalItems] = useState(0);

    const [loading, setLoading] = useState(true);
    const [errorState, setErrorState] = useState(false);
    const [enableSearch, setEnableSearch] = useState(false);
    const [enablePagination] = useState(props.enablePagination ?? true);
    const [tableSearchTerm, setTableSearchTerm] = useState(searchQuery ?? '');
    const [searchClientSide] = useState(props.searchClientSide ?? true);
    const [hasPaging] = useState(props.hasPaging ?? true);

    const [fetchServerSideDataOnInit] = useState(
        props.fetchServerSideDataOnInit ?? false
    );

    const defaultSortField =
        headers.find((header) => header.isDefaultSort)?.sort ?? '';
    const defaultSortDirection =
        headers.find((header) => header.isDefaultSort)?.isDefaultSort ??
        EdsSortDirection.None;

    const [sortDirection, setSortDirection] = useState(
        searchSortField &&
            _.values(EdsSortDirection).includes(searchSortDirection)
            ? searchSortDirection ?? defaultSortDirection
            : defaultSortDirection
    );

    // only allow the valid sort keys
    const validSortFields = headers
        .map((header) => header.sort)
        .filter((item) => !_.isUndefined(item));
    const [sortField, setSortField] = useState(
        searchSortDirection !== EdsSortDirection.None &&
            validSortFields.includes(searchSortField)
            ? searchSortField ?? defaultSortField
            : defaultSortField
    );
    const searchTermTimeoutId = useRef(null);

    const enableSearchParams = props.enableSearchParams ?? true;
    const [selectedRows, setSelectedRows] = useState(props.selected ?? []);

    useEffect(() => {
        if (!enableSearchParams) {
            return;
        }

        let params = {};
        if (tableSearchTerm?.length > 0) {
            params.q = tableSearchTerm;
        }
        if (currentPage != 1) {
            params.p = currentPage;
        }
        if (pageSize !== defaultPageSize) {
            params.a = pageSize;
        }
        if (
            tableSearchTerm?.length > 0 &&
            (sortDirection === EdsSortDirection.Ascending ||
                sortDirection === EdsSortDirection.Descending)
        ) {
            if (sortField?.length > 0) {
                params.s = sortField;
            }
            params.d = sortDirection;
        }
        if (!_.isEmpty(filters)) {
            params.f = urlEncodeFilters(filters);
        } else if (!initialLoaded && !_.isEmpty(searchFilters)) {
            params.f = searchFilters;
            let decodedFilters = urlDecodeFilters(
                searchFilters,
                props.availableFilters
            );

            if (!_.isEmpty(decodedFilters)) {
                addFilters(decodedFilters, onFilterButtonEditClick);
            }
        }

        setSearchParams(params, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pageSize,
        currentPage,
        tableSearchTerm,
        sortField,
        sortDirection,
        filters,
        searchFilters,
    ]);

    useEffectOnMount(async () => {
        if (_.isUndefined(props.searchFilterStore)) {
            return;
        }

        if (_.isFunction(setSavedFilters)) {
            setSavedFilters(onFilterButtonEditClick);
        }
    });

    useEffect(() => {
        if (!initialLoaded) {
            return;
        }
        // current page exceeds of what's allowed based on total items and pageSize
        if (currentPage > Math.ceil(totalItems / pageSize)) {
            setCurrentPage(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, totalItems, pageSize]);

    //no minSearchTermLength needed for searching in the already available data.
    const minSearchTermLength =
        searchClientSide || !hasPaging || fetchServerSideDataOnInit ? 0 : 2;

    const hasMappingCallback = () => {
        return _.isFunction(props.mappingCallback);
    };

    const hasGetDataCallback = () => {
        return _.isFunction(props.getDataCallback);
    };

    useEffect(() => {
        if (hasGetDataCallback()) {
            //if searchClientSide is true. Data need te be fetechd once at init
            if (searchClientSide || !hasPaging) {
                fetchInitData();
                if (searchClientSide) {
                    setSearchableHeaders(getSearchableHeaders());
                }
            }
        }

        setActionButtons(getActionButtons());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchInitData = async () => {
        setLoading(true);
        try {
            const serverData = await props.getDataCallback();
            processDataFromServer(serverData);
        } catch (err) {
            onLoadError(err);
        }
    };

    const onLoadError = (err) => {
        logger.warn('UDS Exception:', err);

        // Set error state, unset loading and clear rows
        setErrorState(true);
        setLoading(false);
        setFilteredRows([]);

        showError({
            title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
            content: t(
                'df0a9d46baf7315909e4389a04786e3d',
                'Oops something went wrong'
            ),
        });
    };

    const processDataFromServer = (serverData) => {
        if (errorState) {
            // Received data from server, unset error state
            setErrorState(false);
        }
        if (!_.isArray(serverData) && _.isArray(serverData.value)) {
            setTotalItems(serverData['@odata.count']);
            serverData = serverData.value;
        } else if (!searchClientSide) {
            setTotalItems(serverData.length);
        }

        const mappedData = mapDataToRows(serverData);
        if (_.isArray(mappedData)) {
            setRows(mappedData);
        }
        setInitDataFetched(true);
    };

    const mappingCallback = (data) => {
        if (hasMappingCallback()) {
            if (!_.isString(data['id'])) {
                data['id'] = `${data['id']}`;
            }
            return props.mappingCallback(data);
        }
        return data;
    };

    const mapDataToRows = (data) => {
        if (!hasMappingCallback()) {
            logger.log('No mappingCallback available');
            return data;
        }
        const rows = [];
        if (_.isArray(data)) {
            data.map((dataArrayRow) => {
                rows.push(mappingCallback(dataArrayRow));
            });
        } else {
            rows.push(mappingCallback(data));
        }
        //filter out all null values
        return rows.filter((item) => item);
    };

    useEffect(() => {
        if (!_.isUndefined(rows)) {
            setFilteredRows(rows);
            //do initial filtering / search from url
            if (searchClientSide) {
                getPageDataFromRows();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rows]);

    useEffect(() => {
        if (_.isUndefined(props.enableSearch)) {
            setEnableSearch(
                searchableHeaders.length > 0 ||
                    (!searchClientSide && !fetchServerSideDataOnInit)
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchableHeaders]);

    useEffect(() => {
        if (currentPage && !_.isNumber(currentPage)) {
            logger.error(
                'currentpage is not a number : ',
                currentPage,
                typeof currentPage
            );
            changeCurrentPage(1);
        } else if (currentPage < 1) {
            logger.error('currentpage cannot be below 1', currentPage);
            changeCurrentPage(1);
        } else {
            if (
                (searchClientSide && previousPage !== currentPage) ||
                (previousPage !== currentPage &&
                    (!_.isEmpty(filters) ||
                        tableSearchTerm.length >= minSearchTermLength))
            ) {
                getPageData();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        if (!searchClientSide && rows?.length > 0) {
            changeCurrentPage(1);
            getPageData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSize]);

    const changeCurrentPage = (newPageNumber) => {
        if (newPageNumber === 1) {
            setPreviousPage(undefined);
        } else {
            setPreviousPage(currentPage);
        }
        setCurrentPage(newPageNumber);
    };

    useEffect(() => {
        if (_.isUndefined(rows)) {
            return;
        }
        if (!_.isArray(filteredRows)) {
            logger.error('table filtered rows is not an Array');
            setFilteredRows([]);
            return;
        }

        if (searchClientSide) {
            setTotalItems(filteredRows.length);
            let rowsForTable = filteredRows.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
            );
            // force page back to 1 when having no data in current page
            if (rowsForTable.length === 0 && currentPage > 1) {
                changeCurrentPage(1);
            }
        }
        if (
            _.isEmpty(rows) &&
            !_.isNil(props.enableSearch) &&
            !(!_.isEmpty(tableSearchTerm) || !_.isEmpty(filters))
        ) {
            setEnableSearch(false);
        } else if (!_.isNil(props.enableSearch)) {
            setEnableSearch(props.enableSearch);
        }

        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredRows]);

    const doSearch = () => {
        if (
            !_.isEmpty(filters) ||
            (!_.isUndefined(tableSearchTerm) &&
                tableSearchTerm.length >= minSearchTermLength)
        ) {
            if (initialLoaded) {
                changeCurrentPage(1);
            }
            setLoading(true);
            getPageData();
            setInitialLoaded(true);
        } else {
            if (initialLoaded) {
                changeCurrentPage(1);
            }
            if (
                rows &&
                filteredRows &&
                rows.length !== filteredRows.length &&
                searchClientSide
            ) {
                setLoading(true);
                getPageData();
            } else {
                if (!searchClientSide && !fetchServerSideDataOnInit) {
                    setRows([]);
                    setTotalItems(0);
                }
            }
            setInitialLoaded(true);
        }
    };

    useEffect(() => {
        debounce(searchTermTimeoutId, () => doSearch(), {
            trailing: true,
            delay: 300,
        });

        if (props.availableFilters && !_.isEmpty(props.availableFilters)) {
            setSearchTerm(tableSearchTerm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableSearchTerm]);

    useEffect(() => {
        if (_.isEmpty(searchTerm)) {
            return;
        }

        if (tableSearchTerm !== searchTerm) {
            setTableSearchTerm(searchTerm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    useEffect(() => {
        if (_.isEmpty(sorting)) {
            return;
        }

        if (
            !_.isNil(sorting.direction) &&
            sortDirection !== sorting.direction
        ) {
            setSortDirection(sorting.direction);
        }

        if (!_.isNil(sorting.field) && sortField !== sorting.field) {
            setSortField(sorting.field);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sorting]);

    const getPageData = () => {
        debounce(
            searchTermTimeoutId,
            () => {
                if (searchClientSide) {
                    getPageDataFromRows();
                } else {
                    getPageDataFromServer();
                }
            },
            { trailing: true, delay: 100 }
        );
    };

    const onSearchInputChange = (event) => {
        const newSearchTerm = event?.target?.value.trim();
        if (_.isUndefined(newSearchTerm)) {
            return;
        }

        if (newSearchTerm === tableSearchTerm) {
            return;
        }

        if (newSearchTerm.length > 0) {
            setLoading(true);
        }
        setTableSearchTerm(newSearchTerm);
    };

    const getPageDataFromRows = async () => {
        if (!_.isUndefined(rows)) {
            if (enableSearch) {
                const searchResult = filterArrayByValue(rows, tableSearchTerm);
                setFilteredRows(searchResult);
            }
            setLoading(false);
        }
    };

    const getPageDataFromServer = async () => {
        let params = {
            searchTerm: tableSearchTerm,
            $top: pageSize,
            $skip: pageSize * (currentPage - 1),
            $count: true,
            $orderBy: createOrderByQuery(),
        };

        let tagFilter = filters?.find(
            (filter) => filter.type === EdsTableFilterType.Tag
        );
        if (!_.isNil(tagFilter)) {
            params.tagId = tagFilter.value;
        }

        params = addFilterQuery(params, filters, props.availableFilters);

        try {
            const serverData = await props.getDataCallback(params);
            processDataFromServer(serverData);
        } catch (err) {
            onLoadError(err);
        }
    };

    const createOrderByQuery = () => {
        if (!sortField || sortDirection === EdsSortDirection.None) {
            return null;
        }

        const suffix = ` ${sortDirection.toLowerCase()}`;
        return sortField.replaceAll(',', `${suffix},`) + `${suffix}`;
    };

    const onPaginationChange = (event) => {
        if (!enablePagination) {
            return;
        }

        if (event.pageSize !== pageSize) {
            setPageSize(event.pageSize);
        }

        if (event.page !== currentPage) {
            setLoading(true);
            changeCurrentPage(event.page);
        }

        if (_.isFunction(props.onPaginationChange)) {
            props.onPaginationChange(event);
        }
    };

    const filterArrayByValue = (array, string) => {
        if (!_.isArray(array)) {
            return [];
        }

        if (_.isEmpty(string)) {
            return array;
        }

        return array.filter((obj) => {
            return Object.keys(obj).some((key) => {
                if (!searchableHeaders.includes(key) || _.isNil(obj[key])) {
                    return false;
                }

                let value = _.isString(obj[key]) ? obj[key] : undefined;

                if (
                    _.isObject(obj[key]) &&
                    [EdsLink.name, EdsOptionalLink.name].includes(
                        obj[key]?.type?.name
                    )
                ) {
                    value = obj[key]?.props?.label;
                }

                return (
                    !_.isUndefined(value) &&
                    value.toLowerCase().includes(string.toLowerCase())
                );
            });
        });
    };

    const sortRows = (rowData) => {
        if (!searchClientSide || sortDirection === EdsSortDirection.None) {
            return rowData;
        }

        const sorter = (fields) => {
            if (!sortField || !(sortField in fields)) {
                logger.info(`Header sortField '${sortField}' not in data`);
                return null;
            }

            if (_.isString(fields[sortField])) {
                return fields[sortField].toLowerCase();
            }
            return fields[sortField];
        };
        return _.orderBy(rowData, [sorter], [sortDirection?.toLowerCase()]);
    };

    const getRowsForTable = () => {
        if (!searchClientSide) {
            return filteredRows;
        }

        const rowsForView = sortRows(filteredRows);
        return rowsForView.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
        );
    };

    const hasAvailableFilters = () => {
        let usedFilters = filters?.map((item) => item.column) ?? [];
        let availableFilters = props.availableFilters?.filter(
            (item) => !usedFilters.includes(item?.id)
        );
        return !_.isEmpty(availableFilters);
    };

    const showFilterButton = () => {
        return !_.isEmpty(props.availableFilters);
    };

    const showTooltipKeywords = () => {
        return (
            enableSearch &&
            !_.isEmpty(props.searchTooltipKeywords) &&
            _.isArray(props.searchTooltipKeywords)
        );
    };

    const onFilterButtonEditClick = (params) => {
        onFilterButtonClick({ filterToEdit: params });
    };

    const onFilterButtonClick = ({ filterToEdit = {} }) => {
        const editMode = () => {
            return !_.isNil(filterToEdit) && !_.isEmpty(filterToEdit);
        };

        logger.info('filterToEdit', filterToEdit);

        showFormModal({
            newTitle: editMode()
                ? t('657e4c952a7d9122fbdfef04f88399b7', 'Edit filter')
                : t('3567d28663c57aea65267b6ea4c0db9b', 'Add filter'),
            size: 'sm',
            primaryButtonText: t('4da463dcb391d40ec0352edd2e42b2d2', 'Apply'),
            formRef,
            children: (
                <EdsTableFilterForm
                    ref={formRef}
                    filterToEdit={filterToEdit}
                    filters={filters}
                    availableFilters={props.availableFilters}
                />
            ),
            hidePrimaryButton: !hasAvailableFilters(),
            onSubmitFormResponse: async (response, removeModal) => {
                let filter = props.availableFilters.find(
                    (filter) => filter.id === response.column.id
                );
                let value = response.filter_value?.value;
                if (
                    filter?.type === EdsTableFilterType.Range ||
                    filter?.type === EdsTableFilterType.DateRange ||
                    filter?.type === EdsTableFilterType.DateTimeRange
                ) {
                    value = {
                        from: response.filter_value?.value_from,
                        to: response.filter_value?.value_to,
                    };
                } else if (filter?.type === EdsTableFilterType.MultiSelect) {
                    value = _.isArray(value)
                        ? value.map((item) => item.id)
                        : [];
                } else if (filter?.type === EdsTableFilterType.Dropdown) {
                    value = value?.id;
                } else if (filter?.type === EdsTableFilterType.Number) {
                    value = parseInt(value);
                } else if (filter?.type === EdsTableFilterType.Location) {
                    value = {
                        country: response.filter_value?.countryId,
                        region: response.filter_value?.regionId,
                        city: response.filter_value?.cityId,
                        district: response.filter_value?.districtId,
                    };
                } else if (filter?.type === EdsTableFilterType.Group) {
                    value = {
                        group: response.filter_value?.groupId,
                        subgroup: response.filter_value?.subgroupId,
                        project: response.filter_value?.projectId,
                        subproject: response.filter_value?.subprojectId,
                    };
                } else if (filter?.type === EdsTableFilterType.Tag) {
                    value = response.filter_value?.tagId;
                } else if (filter?.type === EdsTableFilterType.AlarmCriteria) {
                    let customRangeKeys = Object.keys(response).filter((key) =>
                        key.startsWith('custom_')
                    );
                    let customRanges = [];
                    customRangeKeys.map((key) => {
                        customRanges.push({
                            from: response[key].from,
                            to: response[key].to,
                        });
                    });
                    value = {
                        alarm: !!response.filter_value?.value?.find(
                            (item) =>
                                item.id ===
                                EdsTableFilterAlarmCriteriaType.Alarm
                        ),
                        technicalAlarm: !!response.filter_value?.value?.find(
                            (item) =>
                                item.id ===
                                EdsTableFilterAlarmCriteriaType.TechnicalAlarm
                        ),
                        ...(!_.isEmpty(customRanges) && {
                            custom: customRanges,
                        }),
                        ...(!_.isEmpty(response.specific?.numbers) && {
                            specific: response.specific.numbers,
                        }),
                    };
                }

                if (editMode()) {
                    editFilter(
                        {
                            id: filterToEdit?.id,
                            column: response.column.id,
                            type: filter?.type,
                            operator: response.operator?.id,
                            value,
                        },
                        onFilterButtonEditClick
                    );
                } else {
                    addFilter(
                        {
                            column: response.column.id,
                            type: filter?.type,
                            operator: response.operator?.id,
                            value,
                        },
                        onFilterButtonEditClick
                    );
                }

                removeModal();
            },
        });
    };

    useEffect(() => {
        doSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const showMenuButton = () => {
        return !_.isEmpty(props.menu);
    };

    const showNewButton = () => {
        return (
            _.isFunction(props.onNewButtonClick) &&
            (_.isUndefined(props.permissions?.new) ||
                (!_.isUndefined(props.permissions?.new) &&
                    isAllowed(props.permissions.new)))
        );
    };

    const onNewButtonClick = (event) => {
        if (showNewButton()) {
            props.onNewButtonClick(event);
        }
    };

    const showSelection = () => {
        return _.isFunction(props.onSelectionButtonClick);
    };

    const isRowSelected = (rowId) => {
        if (!showSelection()) {
            return false;
        }
        if (!_.isArray(selectedRows)) {
            return false;
        }
        return selectedRows.includes(rowId);
    };

    const getSearchableHeaders = () => {
        let items = [];
        headers.filter((obj) => {
            return Object.keys(obj).some((key) => {
                if (key === 'search') {
                    items.push(obj.key);
                    return true;
                }
            });
        });
        return items;
    };

    const hasNoSearchResult = () => {
        return (
            !loading &&
            (tableSearchTerm.length >= minSearchTermLength ||
                !_.isEmpty(filters))
        );
    };

    const hasNoDataToSearchIn = () => {
        if (loading || !searchClientSide) {
            if (!hasPaging && _.isEmpty(filters)) {
                if (!_.isUndefined(props.enableSearch)) {
                    if (props.enableSearch === false) {
                        return true;
                    }
                }
            }
            return false;
        }

        return (
            filteredRows.length === 0 &&
            tableSearchTerm.length <= minSearchTermLength
        );
    };

    const insertTableRow = (rowData) => {
        setRows((tableData) => [...tableData, mappingCallback(rowData)]);
    };

    const updateTableRow = (responseData, remove) => {
        const newTableData = rows
            .filter((item) => {
                if (remove && item.id === '' + responseData.id) {
                    return false;
                }
                return true;
            })
            .map((item) => {
                if (item.id === '' + responseData.id) {
                    return mappingCallback(responseData);
                }
                return item;
            });
        setRows(newTableData);
    };

    const removeTableRow = (responseData) => {
        updateTableRow(responseData, true);
    };

    const getRows = () => {
        return rows ?? [];
    };

    const getRowData = (id) => {
        return rows?.find((item) => {
            return item.id === id;
        });
    };

    const getColumnValueString = (id, field) => {
        const rowData = getRowData(id);
        if (_.isNil(rowData?.[field])) {
            return undefined;
        }
        const fieldValue = rowData[field];

        if (_.isString(fieldValue)) {
            return fieldValue;
        }

        //if the value of the column is a <EdsLink to={} label={}/>
        if (_.isPlainObject(fieldValue)) {
            if (
                fieldValue?.props?.label &&
                _.isString(fieldValue?.props?.label)
            ) {
                return fieldValue?.props?.label;
            }
        }

        return undefined;
    };

    useImperativeHandle(ref, () => ({
        insertTableRow,
        updateTableRow,
        removeTableRow,
        getRows,
        fetchInitData,
        doSearch,
        getRowData,
        getColumnValueString,
        onRowClick,
    }));

    const onRowClick = (params) => {
        if (showActionEdit()) {
            props.onEditClick(params);
        }
    };

    const itemRangeText = (min, max, total) => {
        if (total === 1) {
            return t(
                'c1b84c86b3debd9a2c5875a3213d7cd6',
                '{{min}}–{{max}} of {{total}} item',
                {
                    min: min,
                    max: max,
                    total: total,
                }
            );
        } else {
            return t(
                'e2645f3bd74d3a3edf58f225c63a7e95',
                '{{min}}–{{max}} of {{total}} items',
                {
                    min: min,
                    max: max,
                    total: total,
                }
            );
        }
    };

    const pageRangeText = (current, total) => {
        if (total === 1) {
            return t('76e3d02e0873cf052df1d3ec048d668a', 'of {{total}} page', {
                current: current,
                total: total,
            });
        } else {
            return t('8bb7da160ee1bbeed3b9cfd0e0c31dfa', 'of {{total}} pages', {
                current: current,
                total: total,
            });
        }
    };

    const getActionButtons = () => {
        let buttons = [];

        if (!_.isEmpty(props?.customActionButtons)) {
            props.customActionButtons.forEach((item) => {
                buttons.push({
                    type: EdsActionButtonTypes.Custom,
                    ...item,
                });
            });
        }

        if (showActionInfo()) {
            buttons.push({
                type: EdsActionButtonTypes.Log,
                onClick: props?.onLogClick,
                label: props?.logLabel,
            });
        }

        if (showActionEdit()) {
            buttons.push({
                type: EdsActionButtonTypes.Edit,
                onClick: props.onEditClick,
                label: props?.editLabel,
            });
        }

        if (showActionDownload()) {
            buttons.push({
                type: EdsActionButtonTypes.Download,
                onClick: props.onDownloadClick,
                label: props?.downloadLabel,
            });
        }

        if (showActionImage()) {
            buttons.push({
                type: EdsActionButtonTypes.Image,
                onClick: props.onImageClick,
                label: props?.imageLabel,
            });
        }

        if (showActionReplace()) {
            buttons.push({
                type: EdsActionButtonTypes.Replace,
                onClick: props.onReplaceClick,
                label: props?.replaceLabel,
            });
        }

        if (showActionDelete()) {
            buttons.push({
                type: EdsActionButtonTypes.Delete,
                onClick: props.onDeleteClick,
                label: props?.deleteLabel,
            });
        }

        if (showActionUnlink()) {
            buttons.push({
                type: EdsActionButtonTypes.Unlink,
                onClick: props.onUnlinkClick,
                label: props?.unlinkLabel,
            });
        }

        if (buttons.length) {
            buttons[buttons.length - 1].align = 'top-right';
        }

        return buttons;
    };

    const showActionEdit = () => {
        return (
            _.isFunction(props.onEditClick) &&
            (_.isUndefined(props.permissions?.edit) ||
                (!_.isUndefined(props.permissions?.edit) &&
                    isAllowed(props.permissions.edit)))
        );
    };

    const showActionDownload = () => {
        return _.isFunction(props.onDownloadClick);
    };

    const showActionImage = () => {
        return _.isFunction(props.onImageClick);
    };

    const showActionReplace = () => {
        return _.isFunction(props.onReplaceClick);
    };

    const showActionDelete = () => {
        return (
            _.isFunction(props.onDeleteClick) &&
            (_.isUndefined(props.permissions?.delete) ||
                (!_.isUndefined(props.permissions?.delete) &&
                    isAllowed(props.permissions.delete)))
        );
    };

    const showActionUnlink = () => {
        return (
            _.isFunction(props.onUnlinkClick) &&
            (_.isUndefined(props.permissions?.unlink) ||
                (!_.isUndefined(props.permissions?.unlink) &&
                    isAllowed(props.permissions.unlink)))
        );
    };

    const showActionInfo = () => {
        return _.isFunction(props.onLogClick);
    };

    const showActionsColumn = () => {
        return getNumberOfActionButtons() > 0;
    };

    const showPagingToolbar = () => {
        return (
            enablePagination &&
            totalItems > defaultPageSize &&
            filteredRows.length !== 0
        );
    };

    const getNumberOfActionButtons = () => {
        return actionButtons.length;
    };

    const getWidthForActionsColumn = (size = props.size) => {
        let btnWidth = 48;
        let cellPadding = 2 * 16;

        if (!_.isUndefined(size) && size === 'md') {
            btnWidth = 28;
        }

        return cellPadding + btnWidth * getNumberOfActionButtons();
    };

    const getStyleWidthForColumn = (width) => {
        if (!width) {
            return;
        }

        let minWidth = '100px';
        return {
            width: width,
            minWidth: minWidth,
        };
    };

    const getHiddenActionButtons = (rowId) => {
        const rowData = getRowData(rowId);
        if (_.isArray(rowData?.hideActionButtons)) {
            return rowData.hideActionButtons;
        }
        return [];
    };

    useEffectOnUpdate(() => {
        if (!searchClientSide) {
            setLoading(true);
            getPageData();
        }
    }, [sortDirection, sortField]);

    const getTableHeaderSortProps = (header) => {
        if (!header.sort) {
            return {};
        }
        return {
            onClick: () => {
                /*
                sort click sequence:
                - NONE (default)
                - ASC
                - DESC
                */
                let direction;
                if (sortField !== header.sort) {
                    direction = EdsSortDirection.Ascending;
                } else {
                    if (sortDirection === EdsSortDirection.Descending) {
                        direction = EdsSortDirection.None;
                    } else if (sortDirection === EdsSortDirection.Ascending) {
                        direction = EdsSortDirection.Descending;
                    } else if (sortDirection === EdsSortDirection.None) {
                        direction = EdsSortDirection.Ascending;
                    }
                }
                setSortField(header.sort);
                setSortDirection(direction);

                if (direction === EdsSortDirection.None) {
                    setSorting(null);
                } else {
                    setSorting({ field: header.sort, direction: direction });
                }
            },
            isSortHeader: sortField === header.sort,
            sortDirection:
                sortField === header.sort
                    ? sortDirection
                    : EdsSortDirection.None,
        };
    };

    const getToolbarStyle = () => {
        let classes = ['eds-table-toolbar'];
        if (
            (!enableSearch && !showNewButton() && !showFilterButton()) ||
            (enableSearch && hasNoDataToSearchIn())
        ) {
            classes.push('hidden');
        } else if (!enableSearch && showFilterButton()) {
            classes.push('only-filter');
        }

        if (!initDataFetched && (searchClientSide || !hasPaging) && !rows) {
            classes.push('hidden');
        }

        return classes.join(' ');
    };

    const handleTableCellStyleCallback = (row, index) => {
        if (!_.isFunction(headers[index]?.getTableCellStyleCallback)) {
            return;
        }

        return {
            className: headers[index]?.getTableCellStyleCallback(
                getRowData(row.id)
            ),
        };
    };

    return (
        <div className="eds-table">
            <DataTable
                rows={getRowsForTable()}
                size={props.size ?? 'lg'}
                headers={headers}
                radio={props.enableRadioSelection ?? false}
            >
                {({
                    rows,
                    headers,
                    getTableProps,
                    getHeaderProps,
                    getRowProps,
                    getSelectionProps,
                    getToolbarProps,
                }) => {
                    return (
                        <TableContainer title={title}>
                            <div className={getToolbarStyle()}>
                                <TableToolbar {...getToolbarProps()}>
                                    <TableToolbarContent>
                                        {enableSearch &&
                                            !hasNoDataToSearchIn() && (
                                                <EdsSearch
                                                    inToolbar={true}
                                                    placeholder={t(
                                                        '06a943c59f33a34bb5924aaf72cd2995',
                                                        'Search'
                                                    )}
                                                    value={tableSearchTerm}
                                                    onInputChange={
                                                        onSearchInputChange
                                                    }
                                                />
                                            )}

                                        {showTooltipKeywords() && (
                                            <EdsTooltipIcon
                                                align="bottom"
                                                label={
                                                    <div className="eds-tooltip-list">
                                                        {t(
                                                            '2e90ec720fc6dd4b207289869bb3e4be',
                                                            'Searchable terms:'
                                                        )}
                                                        <ul>
                                                            {props.searchTooltipKeywords.map(
                                                                (keyword) => (
                                                                    <li
                                                                        key={
                                                                            keyword
                                                                        }
                                                                    >
                                                                        {
                                                                            keyword
                                                                        }
                                                                    </li>
                                                                )
                                                            )}
                                                        </ul>
                                                    </div>
                                                }
                                            />
                                        )}

                                        {showFilterButton() && (
                                            <Button
                                                className="eds-table-toolbar-button"
                                                onClick={onFilterButtonClick}
                                                kind="ghost"
                                                hasIconOnly={true}
                                                label={t(
                                                    '2c9885d2b0c7e26971f60a90f33cf718',
                                                    'Filters'
                                                )}
                                                renderIcon={Filter}
                                                iconDescription={t(
                                                    '2c9885d2b0c7e26971f60a90f33cf718',
                                                    'Filters'
                                                )}
                                            />
                                        )}
                                        {showMenuButton() && (
                                            <EdsOverFlowMenu
                                                className="eds-table-toolbar-button"
                                                kind="ghost"
                                                items={props.menu.items}
                                            />
                                        )}
                                        {showNewButton() && (
                                            <Button
                                                className="eds-table-toolbar-button"
                                                onClick={onNewButtonClick}
                                                kind="ghost"
                                                renderIcon={Add}
                                                iconDescription={t(
                                                    '34ec78fcc91ffb1e54cd85e4a0924332',
                                                    'Add'
                                                )}
                                            >
                                                {t(
                                                    '34ec78fcc91ffb1e54cd85e4a0924332',
                                                    'Add'
                                                )}
                                            </Button>
                                        )}
                                    </TableToolbarContent>
                                </TableToolbar>
                            </div>
                            <EdsFilterToolbar
                                filters={props.filters ?? []}
                                availableFilters={props.availableFilters ?? []}
                                showSavedFilters={
                                    tableSearchTerm.length < minSearchTermLength
                                }
                                headers={props.headers}
                            />
                            {loading && filteredRows.length === 0 && (
                                <EdsTableLoading
                                    size={props.size ?? 'lg'}
                                    inline={props.size === 'md'}
                                ></EdsTableLoading>
                            )}
                            {filteredRows.length === 0 &&
                            !props.showHeadersOnEmptyResult ? (
                                <>
                                    {hasNoSearchResult() ? (
                                        <EdsTableNoData
                                            onNewButtonClick={
                                                showNewButton() &&
                                                onNewButtonClick
                                            }
                                            afterSearch={
                                                !hasNoDataToSearchIn() &&
                                                (tableSearchTerm.length > 0 ||
                                                    filters.length > 0)
                                            }
                                            noDataLabel={props.noDataLabel}
                                            emptyWithImage={
                                                props.emptyWithImage ?? true
                                            }
                                            errorState={errorState}
                                        />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            ) : (
                                <Table
                                    {...getTableProps()}
                                    experimentalAutoAlign={true}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {showSelection() ? (
                                                props.enableRadioSelection ? (
                                                    <th scope="col" />
                                                ) : (
                                                    <TableSelectAll
                                                        {...getSelectionProps()}
                                                    />
                                                )
                                            ) : null}
                                            {headers.map((header) => (
                                                <TableHeader
                                                    style={getStyleWidthForColumn(
                                                        header.width
                                                    )}
                                                    {...getHeaderProps({
                                                        header,
                                                        isSortable: Boolean(
                                                            header.sort
                                                        ),
                                                    })}
                                                    {...getTableHeaderSortProps(
                                                        header
                                                    )}
                                                    key={header.key}
                                                >
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                            {showActionsColumn() && (
                                                <TableHeader
                                                    style={getStyleWidthForColumn(
                                                        getWidthForActionsColumn()
                                                    )}
                                                ></TableHeader>
                                            )}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        <EdsTableProgress
                                            showActionsColumn={showActionsColumn()}
                                            acitve={loading}
                                            {...props}
                                        ></EdsTableProgress>

                                        {props.showHeadersOnEmptyResult &&
                                            rows.length === 0 && (
                                                <EdsTableRow>
                                                    <TableCell
                                                        colSpan={
                                                            props.headers.length
                                                        }
                                                    >
                                                        {t(
                                                            '1360810a585271ede5fbe874817a2f4e',
                                                            'No data in this section'
                                                        )}
                                                    </TableCell>
                                                </EdsTableRow>
                                            )}
                                        {rows.map((row) => (
                                            <EdsTableRow
                                                {...(!showSelection() &&
                                                    getRowProps({ row }))}
                                                selectionProps={
                                                    showSelection() &&
                                                    getSelectionProps({ row })
                                                }
                                                key={row.id}
                                                row={row}
                                                isSelected={isRowSelected(
                                                    row.id
                                                )}
                                                getTableRowStyleCallback={
                                                    _.isFunction(
                                                        props?.getTableRowStyleCallback
                                                    )
                                                        ? () => {
                                                              return props?.getTableRowStyleCallback(
                                                                  getRowData(
                                                                      row.id
                                                                  )
                                                              );
                                                          }
                                                        : undefined
                                                }
                                                onRowClick={
                                                    !_.isFunction(
                                                        props.onRowClick
                                                    ) && !showSelection()
                                                        ? null
                                                        : (row) => {
                                                              if (
                                                                  showSelection()
                                                              ) {
                                                                  setSelectedRows(
                                                                      [row.id]
                                                                  );
                                                              }
                                                              if (
                                                                  _.isFunction(
                                                                      props.onRowClick
                                                                  )
                                                              ) {
                                                                  props.onRowClick(
                                                                      row
                                                                  );
                                                              }
                                                          }
                                                }
                                            >
                                                {row.cells.map(
                                                    (cell, index) => (
                                                        <TableCell
                                                            key={cell.id}
                                                            {...handleTableCellStyleCallback(
                                                                row,
                                                                index
                                                            )}
                                                        >
                                                            {cell.value}
                                                        </TableCell>
                                                    )
                                                )}

                                                {showActionsColumn() && (
                                                    <TableCell
                                                        className={
                                                            'eds-actions-col'
                                                        }
                                                        onClick={(e) =>
                                                            e.stopPropagation()
                                                        }
                                                    >
                                                        <EdsTableActionbar
                                                            row={row}
                                                            size={props.size}
                                                            actionButtons={
                                                                actionButtons
                                                            }
                                                            hideActionButtons={getHiddenActionButtons(
                                                                row.id
                                                            )}
                                                        ></EdsTableActionbar>
                                                    </TableCell>
                                                )}
                                            </EdsTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        </TableContainer>
                    );
                }}
            </DataTable>

            {/* also based on odata count */}
            {showPagingToolbar() && (
                <Pagination
                    backwardText={t(
                        '4b8d849da50c8e72f839c5c7e1abfe95',
                        'Previous page'
                    )}
                    forwardText={t(
                        '362d09ef71837c9e9806341e062753e9',
                        'Next page'
                    )}
                    itemsPerPageText={t(
                        '65fbd8fe4ac0a214466e94536c22a7c7',
                        'Items per page:'
                    )}
                    itemRangeText={itemRangeText}
                    pageRangeText={pageRangeText}
                    page={currentPage}
                    pageNumberText={t(
                        'd5c38f52f86bb9370fd3aa0c6159da4c',
                        'Page number'
                    )}
                    pageSize={pageSize}
                    pageSizes={availablePageSizes}
                    size="md"
                    totalItems={totalItems}
                    onChange={onPaginationChange}
                />
            )}
        </div>
    );
});
EdsTableInternal.displayName = 'EdsTableInternal';

export const EdsTable = forwardRef((props, ref) => {
    if (props?.availableFilters?.length > 0) {
        return (
            <EdsTableFilterProvider
                searchFilterStore={props.searchFilterStore}
                availableFilters={props.availableFilters}
                headers={props.headers}
            >
                <EdsTableInternal ref={ref} {...props} />
            </EdsTableFilterProvider>
        );
    }

    return <EdsTableInternal ref={ref} {...props} />;
});
EdsTable.displayName = 'EdsTable';

export function EdsTableNoData(props) {
    const { t } = useTranslation();
    const [label, setLabel] = useState(
        props?.noDataLabel ??
            t('1360810a585271ede5fbe874817a2f4e', 'No data in this section')
    );
    useEffect(() => {
        if (props.errorState) {
            setLabel(
                t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                )
            );
        } else if (props.afterSearch) {
            setLabel(
                t('1c6b3f5dda60057236fc1e9da9d3296e', 'No search results')
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getEmptyImageType = () => {
        if (props.errorState) {
            return 'error';
        } else if (props.afterSearch) {
            return 'search';
        }
        return 'default';
    };

    const getEmptyStyle = () => {
        let classes = ['eds-table-empty'];
        if (!props.emptyWithImage) {
            classes.push('small');
        }
        if (props.afterSearch) {
            classes.push('afterSearch');
        }
        return classes.join(' ');
    };

    const showNewButton = () => {
        return _.isFunction(props.onNewButtonClick);
    };

    return (
        <div className={getEmptyStyle()}>
            {props.emptyWithImage && (
                <div
                    className={'eds-table-empty-img ' + getEmptyImageType()}
                ></div>
            )}

            <div className="eds-table-empty-label">{label}</div>

            {showNewButton() && (
                <div className="eds-table-empty-button">
                    <Button
                        label={t('34ec78fcc91ffb1e54cd85e4a0924332', 'Add')}
                        renderIcon={Add}
                        onClick={props.onNewButtonClick}
                        iconDescription={t(
                            '34ec78fcc91ffb1e54cd85e4a0924332',
                            'Add'
                        )}
                    >
                        {t('34ec78fcc91ffb1e54cd85e4a0924332', 'Add')}
                    </Button>
                </div>
            )}
        </div>
    );
}

export function EdsTableLoading(props) {
    const [small] = useState(props.size === 'md' || props.size === 'sm');
    const [delay] = useState(props.delay ?? true);

    const getStyle = () => {
        let classes = ['eds-table-loading'];
        if (props.inline) {
            classes.push('inline');
        }
        return classes.join(' ');
    };

    if (props.hide) {
        return null;
    }

    return (
        <div className={getStyle()}>
            <EdsLoading
                hideLabel={!small}
                small={small}
                delay={delay}
                inline={props.inline}
            />
        </div>
    );
}

export function EdsTableProgress(props) {
    const getStyle = () => {
        let classes = ['eds-table-progress', 'delay'];

        return classes.join(' ');
    };

    const columnsToSpan = () => {
        let columns = props.headers.length;
        if (props.showActionsColumn) {
            columns++;
        }
        return columns;
    };

    if (!props.acitve) {
        return null;
    }

    return (
        <TableRow className={getStyle()}>
            <TableCell colSpan={columnsToSpan()}>
                <ProgressBar
                    hideLabel={true}
                    label=""
                    type="inline"
                    size="small"
                ></ProgressBar>
            </TableCell>
        </TableRow>
    );
}
