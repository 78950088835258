import { forwardRef, useState } from 'react';
import {
    EdsContainerTable,
    EdsOptionalLink,
    EdsTableCheckmark,
    UmoLinkedClientTableInModal,
} from '../../..';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    getResidence,
    getResidenceClients,
    setEmptyFieldToNull,
    useModal,
    useNotifications,
} from '../../../../features';
import { UmoLinkResidence } from './link-residence';
import { useNavigate } from '../../../../features';
import { UserMultiple } from '@carbon/icons-react';

export const UmoLinkedResidencesTable = forwardRef(
    (
        {
            getDataCallback,
            postDataCallback,
            onUnlinkClick,
            hideWhenNoData = true,
            canLinkToMultipleResidence = true,
            linkBaseFilter,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const navigate = useNavigate();
        const { showSuccess, showError, showInfo } = useNotifications();
        const { showLinkModal, showConfirmationModal } = useModal();

        const [title] = useState(
            props.title ??
                t('c2f4d0a190c0cd334dc02d48960df8b0', 'Linked residences')
        );

        const [hasData, setHasData] = useState();

        const getVisibleHeaders = () => {
            if (!props.visibleHeaderKeys) {
                return headers;
            }

            return props.visibleHeaderKeys.map((key) =>
                headers.find((h) => h.key === key)
            );
        };

        const isVisible = (headerKey) => {
            if (!props.visibleHeaderKeys) {
                return true;
            }
            return props.visibleHeaderKeys.includes(headerKey);
        };

        const headers = [
            {
                key: 'address',
                header: t('884d9804999fc47a3c2694e49ad2536a', 'Address'),
            },

            {
                key: 'city',
                header: t('4ed5d2eaed1a1fadcc41ad1d58ed603e', 'City'),
            },
            {
                key: 'scheme',
                header: t('41323917ef8089432959a3c33269debf', 'Scheme'),
            },
            {
                key: 'type',
                header: t('2d02d77d09fdf4c4466181594b8d8739', 'Residence type'),
            },
            {
                key: 'firstPhoneNumber',
                header: t(
                    'fb3fcd28585746348373a082399383b7',
                    '1st phone number'
                ),
            },
            {
                key: 'isSchemeApartment',
                header: t(
                    'cb4ca34e1faa1f62d0acaed117297aae',
                    'Scheme apartment'
                ),
            },
            {
                key: 'defaultDeviceCode',
                header: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
            },
            {
                key: 'useSchemeAddress',
                header: t(
                    '9775ffbdb26b8e4dd4008dc09e8b2bac',
                    'Uses scheme address'
                ),
            },
        ];

        const getData = async () => {
            let data = await getDataCallback();
            setHasData(!_.isEmpty(data));
            return data;
        };

        if (
            hideWhenNoData &&
            !_.isUndefined(hasData) &&
            _.isBoolean(hasData) &&
            !hasData
        ) {
            return null;
        }

        const linkNewResidence = async (form) => {
            const postData = setEmptyFieldToNull(form);
            try {
                const response = await postDataCallback({
                    residenceId: postData.selected,
                });

                showSuccess({
                    title: t('d48e37bc19b9fe2c72923c30fd7a4152', 'Linked'),
                    content: t(
                        '5be667d4a923c2575e183041beafed1e',
                        '{{entity}} is linked',
                        {
                            entity: response.combinedAddressField,
                        }
                    ),
                });

                ref.current?.fetchInitData();

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        return (
            <EdsContainerTable
                hidden={_.isUndefined(hasData)}
                title={title}
                ref={ref}
                unlinkLabel={t(
                    'f4d6f065c5fbc300d7da48142db14a66',
                    'Unlink residence'
                )}
                linkLabel={
                    !hasData
                        ? t('2a304a1348456ccd2234cd71a81bd338', 'Link')
                        : t('e69f80c0efc55c80069fc61889517a0e', 'Change link')
                }
                headers={getVisibleHeaders()}
                getDataCallback={getData}
                onRowClick={(row) => {
                    navigate(`/crm/residence/${row.id}`);
                }}
                onHeaderLinkClick={
                    !_.isFunction(postDataCallback)
                        ? undefined
                        : () => {
                              showLinkModal({
                                  title: t(
                                      'a81628576e4f50605e8683358f4f0cac',
                                      'Link residence'
                                  ),
                                  tableRef: ref,
                                  children: (
                                      <UmoLinkResidence
                                          {...props}
                                          linkBaseFilter={linkBaseFilter}
                                          getDataCallback={getData}
                                          onSubmit={async (form) => {
                                              if (
                                                  form.selected ===
                                                  form.initSelected
                                              ) {
                                                  showInfo({
                                                      title: t(
                                                          '9cd8af940d870c0db8bf0e5efb2ef3c1',
                                                          'No changes were made'
                                                      ),
                                                      content: t(
                                                          '7cd027baccfb092c5f246532308f4c70',
                                                          'Please note that you did not select another residence to link'
                                                      ),
                                                  });
                                                  return;
                                              }

                                              if (
                                                  !hasData ||
                                                  canLinkToMultipleResidence
                                              ) {
                                                  return linkNewResidence(form);
                                              }

                                              showConfirmationModal({
                                                  question: t(
                                                      'd8e8b70c5c165a36aea26ae8150d931f',
                                                      'Are you sure you want to link to another residence?'
                                                  ),
                                                  yesHandler: async () => {
                                                      return linkNewResidence(
                                                          form
                                                      );
                                                  },
                                              });
                                          }}
                                      />
                                  ),
                              });
                          }
                }
                onUnlinkClick={
                    !_.isFunction(onUnlinkClick)
                        ? undefined
                        : (row) => {
                              let data = ref.current?.getRowData(row.id);
                              return onUnlinkClick({ row: row, data: data });
                          }
                }
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        ...(isVisible('address') && {
                            address: (
                                <EdsOptionalLink
                                    id={responseData.id}
                                    to={`/crm/residence/${responseData.id}`}
                                    label={responseData.combinedAddressField}
                                />
                            ),
                        }),
                        ...(isVisible('city') && {
                            city: responseData.address?.city?.name,
                        }),
                        ...(isVisible('scheme') && {
                            scheme: (
                                <EdsOptionalLink
                                    id={responseData?.scheme?.id}
                                    to={`/crm/scheme/${responseData?.scheme?.id}`}
                                    label={responseData?.scheme?.name}
                                />
                            ),
                        }),
                        ...(isVisible('type') && {
                            type: responseData?.residenceType?.name,
                        }),
                        ...(isVisible('firstPhoneNumber') && {
                            firstPhoneNumber:
                                responseData?.firstPhoneNumber?.number,
                        }),
                        ...(isVisible('isSchemeApartment') && {
                            isSchemeApartment: (
                                <EdsTableCheckmark
                                    value={responseData.isSchemeApartment}
                                />
                            ),
                        }),
                        ...(isVisible('useSchemeAddress') && {
                            useSchemeAddress: (
                                <EdsTableCheckmark
                                    value={responseData.useSchemeAddress}
                                />
                            ),
                        }),
                        ...(isVisible('defaultDeviceCode') && {
                            defaultDeviceCode: (
                                <EdsOptionalLink
                                    id={responseData?.defaultDisplayedDeviceId}
                                    to={`/crm/device/${responseData?.defaultDisplayedDeviceId}`}
                                    label={
                                        responseData.defaultDisplayedDeviceCode
                                    }
                                />
                            ),
                        }),
                    };
                }}
                {...props}
            />
        );
    }
);
UmoLinkedResidencesTable.displayName = 'UmoLinkedResidencesTable';

export const UmoLinkedResidencesTableForAlarm = forwardRef((props, ref) => {
    return (
        <UmoLinkedResidencesTable
            {...props}
            ref={ref}
            visibleHeaderKeys={['address', 'city', 'type', 'defaultDeviceCode']}
            onHeaderLinkClick={null}
        />
    );
});
UmoLinkedResidencesTableForAlarm.displayName =
    'UmoLinkedResidencesTableForAlarm';

export const UmoLinkedResidencesTableForDevice = forwardRef((props, ref) => {
    return (
        <UmoLinkedResidencesTable
            {...props}
            ref={ref}
            visibleHeaderKeys={['address', 'city', 'defaultDeviceCode']}
            onHeaderLinkClick={null}
        />
    );
});
UmoLinkedResidencesTableForDevice.displayName =
    'UmoLinkedResidencesTableForDevice';

export const UmoLinkedResidencesTableForProfessionalCaregiver = forwardRef(
    (props, ref) => {
        return (
            <UmoLinkedResidencesTable
                {...props}
                ref={ref}
                visibleHeaderKeys={['address', 'type', 'isSchemeApartment']}
                onHeaderLinkClick={null}
            />
        );
    }
);
UmoLinkedResidencesTableForProfessionalCaregiver.displayName =
    'UmoLinkedResidencesTableForProfessionalCaregiver';

export const UmoLinkedResidencesTableForCareTeams = forwardRef((props, ref) => {
    return (
        <UmoLinkedResidencesTable
            {...props}
            ref={ref}
            visibleHeaderKeys={['address', 'type', 'isSchemeApartment']}
            onHeaderLinkClick={null}
        />
    );
});
UmoLinkedResidencesTableForCareTeams.displayName =
    'UmoLinkedResidencesTableForCareTeams';

export const UmoLinkedResidencesTableForClient = forwardRef((props, ref) => {
    return (
        <UmoLinkedResidencesTable
            {...props}
            ref={ref}
            canLinkToMultipleResidence={false}
            visibleHeaderKeys={[
                'address',
                'city',
                'scheme',
                'type',
                'firstPhoneNumber',
                'defaultDeviceCode',
            ]}
        />
    );
});
UmoLinkedResidencesTableForClient.displayName =
    'UmoLinkedResidencesTableForClient';

export const UmoLinkedResidencesTableForScheme = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const { showModal } = useModal();

    return (
        <UmoLinkedResidencesTable
            {...props}
            ref={ref}
            hideWhenNoData={false}
            linkBaseFilter={`schemeId eq null`}
            visibleHeaderKeys={[
                'address',
                'city',
                'type',
                'defaultDeviceCode',
                'isSchemeApartment',
                'useSchemeAddress',
            ]}
            customActionButtons={[
                {
                    label: t(
                        '4c0cef16cbb39b8ba7eef755e33662bb',
                        'Show clients'
                    ),
                    icon: <UserMultiple />,
                    onClick: async (row) => {
                        const residence = await getResidence(row.id);

                        showModal({
                            title: t(
                                '9e3c4a100a5bea098f6eef66fdf615c3',
                                "Clients for '{{residence}}'",
                                { residence: residence?.combinedAddressField }
                            ),

                            children: (
                                <UmoLinkedClientTableInModal
                                    hideWhenNoData={false}
                                    showRequiresDevice={true}
                                    title={t(
                                        'fac04ca68a48af91f0290001604a2463',
                                        'Clients'
                                    )}
                                    visibleHeaderKeys={[
                                        'state',
                                        'name',
                                        'gender',
                                        'requires_device',
                                    ]}
                                    getDataCallback={async () =>
                                        await getResidenceClients(row.id)
                                    }
                                />
                            ),
                            hidePrimaryButton: true,
                            secondaryButtonText: t(
                                '716f6b30598ba30945d84485e61c1027',
                                'Close'
                            ),
                            size: 'lg',
                            onRequestSubmit: (event, removeModal) => {
                                removeModal();
                            },
                        });
                    },
                },
            ]}
        />
    );
});
UmoLinkedResidencesTableForScheme.displayName =
    'UmoLinkedResidencesTableForScheme';
