import React from 'react';
import { RadioButtonGroup } from '@carbon/react';
import { useForm, useFormGroup } from '../eds-form';

export function EdsRadioButtonGroup(props) {
    const { name, legendText, forceRequired, orientation, children } = props;
    const { isInvalid, getInvalidText, handleOnBlur, formatLabel } = useForm();
    const { prefix, usePrefix } = useFormGroup();
    let prefixedName = usePrefix(name) ?? name;

    function getEdsRadioButtons() {
        const mappedChildren = React.Children.map(children, (child) => {
            if (child) {
                return React.cloneElement(child, {
                    name: name,
                });
            }
        });
        return mappedChildren;
    }

    return (
        <RadioButtonGroup
            className="eds-radio-button-group"
            name={prefixedName}
            legendText={formatLabel(legendText, name, prefix, forceRequired)}
            orientation={orientation}
            onBlur={handleOnBlur}
            invalid={isInvalid(prefixedName)}
            invalidText={getInvalidText(prefixedName)}
        >
            {getEdsRadioButtons()}
        </RadioButtonGroup>
    );
}
