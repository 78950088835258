import {
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/personal-caregivers';

export const getPersonalCaregiver = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const putPersonalCaregiver = async (data) => {
    return putRequest(`${basePath}/${data.id}`, data);
};

export const deletePersonalCaregiver = async (id) => {
    return deleteRequest(`${basePath}/${id}`);
};

export const patchPersonalCaregiver = async (id, data) => {
    return patchRequest(`${basePath}/${id}`, data);
};

export const getPersonalCaregiverLink = async (id) => {
    return getRequest(`${basePath}/${id}/link`);
};

export const putPersonalCaregiverLink = async (data) => {
    return putRequest(`${basePath}/${data.id}/link`, data);
};

export const patchPersonalCaregiverLink = async (id, data) => {
    return patchRequest(`${basePath}/${id}/link`, data);
};

export const getPersonalCaregiverNotes = async (id, params) => {
    return getRequest(`${basePath}/${id}/notes`, params);
};

export const postPersonalCaregiverNote = async (id, data) => {
    return postRequest(`${basePath}/${id}/notes`, data);
};

export const getPersonalCaregiverTags = async (id, params) => {
    return getRequest(`${basePath}/${id}/tags`, params);
};

export const postPersonalCaregiverTag = async (id, data) => {
    return postRequest(`${basePath}/${id}/tags`, data);
};
export const getPersonalCaregiverPhoneNumbers = async (id, onlyE164Format) => {
    return getRequest(`${basePath}/${id}/phone-numbers`, {
        onlyE164Format: onlyE164Format,
    });
};

export const postPersonalCaregiverPhoneNumber = async (id, data) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, data);
};

export const getPersonalCaregiverHolidayLinks = async (id, params) => {
    return getRequest(`${basePath}/${id}/holiday-links`, params);
};

export const postPersonalCaregiverHolidayLink = async (id, data) => {
    return postRequest(`${basePath}/${id}/holiday-links`, data);
};

export const getPersonalCaregiverAvailability = async (id, params) => {
    return getRequest(`${basePath}/${id}/availabilities`, params);
};

export const postPersonalCaregiverAvailability = async (id, data) => {
    return postRequest(`${basePath}/${id}/availabilities`, data);
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'personal-caregivers'),
};
