import { useTranslation } from 'react-i18next';
import {
    EdsCheckbox,
    EdsDropdown,
    EdsDropdownType,
    EdsFormColumn,
    EdsFormGroup,
    EdsNumberInput,
} from '../../../eds';
import { CheckboxGroup, Row } from '@carbon/react';
import _ from 'lodash';
import { getRelationTypes } from '../../../../features';

function AdditionalInformationFormGroup(props) {
    const { t } = useTranslation();

    const formDefinition = {
        order: {
            validation: {
                type: 'number',
                min: 0,
                required: false,
            },
        },
        relationTypeId: {
            validation: {
                type: 'dropdown',
                required: false,
            },
            mapping: 'id',
        },
        hasKey: false,
        isNextOfKin: false,
        ...(!_.isUndefined(props.wizardStepId) && {
            wizardStepId: props.wizardStepId,
        }),
    };

    return (
        <EdsFormGroup
            label={props.label}
            prefix="linkInfo"
            formDefinition={formDefinition}
            inStep={true}
        >
            <Row>
                <EdsFormColumn>
                    <EdsNumberInput
                        name="order"
                        label={t('70a17ffa722a3985b86d30b034ad06d7', 'Order')}
                    />
                </EdsFormColumn>
                <EdsFormColumn>
                    <EdsDropdown
                        name="relationTypeId"
                        label={t(
                            '3a7434581e964a77c33fa7ae8931bc17',
                            'Relation type'
                        )}
                        type={EdsDropdownType.Dropdown}
                        getDataCallback={getRelationTypes}
                    />
                </EdsFormColumn>
            </Row>
            <Row>
                <EdsFormColumn>
                    <CheckboxGroup legendText="">
                        <EdsCheckbox
                            name="hasKey"
                            labelText={t(
                                'ce380372c6231ea71ee4d0a798b99959',
                                'Has key'
                            )}
                        />
                        <EdsCheckbox
                            name="isNextOfKin"
                            labelText={t(
                                'cb09f7b3eaebedc6aecf621e3014e282',
                                'Next of kin'
                            )}
                        />
                    </CheckboxGroup>
                </EdsFormColumn>
            </Row>
        </EdsFormGroup>
    );
}

export default AdditionalInformationFormGroup;
