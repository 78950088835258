import { CircleDash, InProgress } from '@carbon/icons-react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { toJSDate } from '../../../features';
import _ from 'lodash';
import './eds-alarm-status.scss';

export function EdsAlarmStatus({ assignee, startDate }) {
    const { t } = useTranslation();
    const [dtStart, setDtStart] = useState();
    const [duration, setDuration] = useState();

    const calculateAlarmAge = () => {
        const now = DateTime.now();
        setDuration(Math.floor(now.diff(dtStart, 'seconds').seconds));
    };

    useEffect(() => {
        if (!_.isUndefined(dtStart)) {
            const timer = setTimeout(() => {
                calculateAlarmAge();
            }, 1000);

            return () => clearTimeout(timer);
        }
    });

    useEffect(() => {
        const jsdate = toJSDate(startDate, true);
        if (_.isDate(jsdate)) {
            setDtStart(DateTime.fromJSDate(jsdate));
        }
    }, [startDate]);

    useEffect(() => {
        if (!_.isNil(dtStart)) {
            calculateAlarmAge();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dtStart]);

    const renderStatusIcon = () => {
        const props = {
            className: 'eds-alarm-status-icon',
            size: '16',
        };

        if (_.isNil(assignee)) {
            return <CircleDash {...props} />;
        } else {
            return <InProgress {...props} />;
        }
    };

    const renderDuration = () => {
        if (_.isUndefined(duration)) {
            return;
        }

        return (
            <div className="eds-alarm-status-duration">{`(${duration} ${t(
                '74459ca3cf85a81df90da95ff6e7a207',
                'sec'
            )})`}</div>
        );
    };

    return (
        <div className="eds-alarm-status">
            {renderStatusIcon()}
            <div className="eds-alarm-status-assignee">
                {assignee ??
                    t('28791904d516051cca7bd49343e18b5a', 'Open alarm')}
            </div>
            {renderDuration()}
        </div>
    );
}
