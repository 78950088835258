import { useRef } from 'react';
import { TableRow, TableSelectRow } from '@carbon/react';
import './eds-table-row.scss';
import { debounce } from '../../../../features';
import _ from 'lodash';

export function EdsTableRow(props) {
    const { row, selectionProps, isSelected, children } = props;
    const rowClickTimeoutId = useRef(null);

    const hasOnRowClick = () => {
        return _.isFunction(props.onRowClick);
    };

    const onRowClick = (row) => {
        if (hasOnRowClick()) {
            if (_.isFunction(props.onRowClick)) {
                debounce(
                    rowClickTimeoutId,
                    () => {
                        props.onRowClick(row);
                    },
                    { delay: 500 }
                );
            }
        }
    };

    const getStyle = (row) => {
        let classes = ['eds-table-row'];

        if (hasOnRowClick()) {
            classes.push('eds-clickable-row');
        }

        if (_.isFunction(props?.getTableRowStyleCallback)) {
            classes.push(props?.getTableRowStyleCallback(row));
        }

        return classes.join(' ');
    };

    return (
        <TableRow
            isSelected={isSelected}
            className={getStyle(row)}
            onClick={() => onRowClick(row)}
            aria-label=""
        >
            {selectionProps && (
                <TableSelectRow {...selectionProps} checked={isSelected} />
            )}
            {children}
        </TableRow>
    );
}
