import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { toJSDate } from '../../../../features';
import _ from 'lodash';

export const EdsTableDatetimeTypes = Object.freeze({
    DayHourSecond: 'DAY_HOUR_SECOND',
    DayHour: 'DAY_HOUR',
    Day: 'DAY',
});

function FormatDate({ type, value = '' }) {
    const { t } = useTranslation();

    const getFormat = () => {
        switch (type) {
            case EdsTableDatetimeTypes.Day: {
                return t('418c23834fc746227d6ba6bbf181e8b5', 'MM/dd/yyyy');
            }
            case EdsTableDatetimeTypes.DayHour: {
                return t(
                    '6eb1403d4ccd7c4731ffa90b6c01beef',
                    'MM/dd/yyyy h:mm a'
                );
            }
            case EdsTableDatetimeTypes.DayHourSecond: {
                return t(
                    'c063848f55ed9fb9f201e31ab3fb3ad6',
                    'MM/dd/yyyy h:mm:ss a'
                );
            }

            default: {
                return t(
                    '6eb1403d4ccd7c4731ffa90b6c01beef',
                    'MM/dd/yyyy h:mm a'
                );
            }
        }
    };

    const jsdate = toJSDate(value, true);
    if (jsdate instanceof Date) {
        return DateTime.fromJSDate(jsdate).toFormat(getFormat());
    }

    return _.isEmpty(value) ? '-' : value;
}

export function EdsTableDate(props) {
    return <FormatDate type={EdsTableDatetimeTypes.Day} {...props} />;
}

export function EdsTableDatetime(props) {
    return <FormatDate type={EdsTableDatetimeTypes.DayHour} {...props} />;
}

export function EdsTableDatetimeSeconds(props) {
    return <FormatDate type={EdsTableDatetimeTypes.DayHourSecond} {...props} />;
}
