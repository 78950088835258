import { useRef } from 'react';
import {
    getAlarmLocationByNumber,
    getLogger,
    useModal,
} from '../../../../features';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { EdsContainerTable } from '../../../eds';
import LocationRenumberingForm from './location-renumbering-form';
import { UmoAuditLogGrid } from '../../umo-container-grid';

let logger = getLogger('UmoLocationReallocationsTable');

export const UmoLocationReallocations = ({
    title,
    parentId,
    getDataCallback,
    getItemCallback,
    postDataCallback,
    putDataCallback,
    deleteDataCallback,
    enableContainer = true,
    ...props
}) => {
    const { t } = useTranslation();
    const tableRef = useRef(null);
    const formRef = useRef(null);
    const { showAuditLogModal, showDeleteModal, showFormModal } = useModal();

    const showDataModal = (locationRenumbering = {}) => {
        const unavailableNumbers = tableRef.current
            .getRows()
            .map((row) => _.toNumber(row.deviceLocationNumber))
            .filter(
                (number) =>
                    number !== locationRenumbering?.deviceLocationNumber?.number
            );

        showFormModal({
            newTitle: t(
                'c6cc9fec722ea9c47de30274c90be7ff',
                'New alarm location renumbering'
            ),
            editTitle: t(
                '4ef027f5fd26030d38e1cfd4f3a5c72d',
                'Edit alarm location renumbering'
            ),
            formData: locationRenumbering,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.fetchInitData();
                }
                removeModal();
            },
            children: (
                <LocationRenumberingForm
                    postDataCallback={postDataCallback}
                    putDataCallback={putDataCallback}
                    ref={formRef}
                    initValues={locationRenumbering}
                    hideDefaultSubmit={true}
                    parentId={parentId}
                    unavailableNumbers={unavailableNumbers}
                />
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let reallocation = await getItemCallback(row.id);

            if (_.isUndefined(reallocation.deviceLocationDescription)) {
                let requests = [];
                requests.push(
                    getAlarmLocationByNumber(reallocation.deviceLocationNumber)
                );
                requests.push(
                    getAlarmLocationByNumber(reallocation.umoLocationNumber)
                );

                const response = await Promise.all(requests);

                reallocation.deviceLocationDescription =
                    response[0]?.description;
                reallocation.umoLocationDescription = response[1]?.description;
            }

            const formData = {
                id: {
                    value: reallocation.id,
                },
                deviceLocationNumber: {
                    number: reallocation.deviceLocationNumber,
                    text: `${reallocation?.deviceLocationDescription} (${reallocation?.deviceLocationNumber})`,
                },
                umoLocationNumber: {
                    number: reallocation.umoLocationNumber,
                    text: `${reallocation?.umoLocationDescription} (${reallocation?.umoLocationNumber})`,
                },
                remarks: {
                    value: reallocation.remarks,
                },
            };

            showDataModal(formData);
        } catch (ex) {
            logger.warn(
                'getDeviceAlarmLocationReallocation UDS Exception:',
                ex
            );
        }
    };

    return (
        <EdsContainerTable
            enableContainer={enableContainer}
            title={
                title ??
                t(
                    'a919b89cb9800f8c3e5a614f487e1b29',
                    'Alarm location renumbering'
                )
            }
            ref={tableRef}
            headers={[
                {
                    key: 'deviceLocationNumber',
                    header: t(
                        '9f8d225bc2126dc3c5ab5e40d3d3dc8d',
                        'From location'
                    ),
                    sort: 'deviceLocationNumber',
                    ...(!enableContainer && {
                        search: 'searchByDeviceLocationNumber',
                    }),
                },
                {
                    key: 'umoLocationNumber',
                    header: t(
                        'bd7812e272ba6f993037a1ee259460be',
                        'To location'
                    ),
                    sort: 'umoLocationNumber',
                    ...(!enableContainer && {
                        search: 'searchByUmoLocationNumber',
                    }),
                },
                {
                    key: 'remark',
                    header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
                    ...(!enableContainer && {
                        search: 'searchByRemark',
                    }),
                },
            ]}
            getDataCallback={getDataCallback}
            mappingCallback={(responseData) => {
                return {
                    id: responseData.id,
                    ...(_.isUndefined(
                        responseData?.deviceLocationDescription
                    ) && {
                        deviceLocationNumber: `${responseData?.deviceLocationNumber}`,
                        umoLocationNumber: `${responseData?.umoLocationNumber}`,
                    }),
                    ...(!_.isUndefined(
                        responseData?.deviceLocationDescription
                    ) && {
                        deviceLocationNumber: `${responseData?.deviceLocationDescription} (${responseData?.deviceLocationNumber})`,
                        umoLocationNumber: `${responseData?.umoLocationDescription} (${responseData?.umoLocationNumber})`,
                    }),
                    remark: responseData.remarks,
                };
            }}
            onHeaderAddClick={
                enableContainer ? () => showDataModal() : undefined
            }
            onNewButtonClick={
                !enableContainer ? () => showDataModal() : undefined
            }
            onEditClick={(row) => {
                getDataRow(row);
            }}
            onDeleteClick={(row) => {
                let data = tableRef.current?.getRowData(row.id);
                showDeleteModal({
                    name: `${data.deviceLocationNumber} - ${data.umoLocationNumber}`,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteDataCallback(row.id);
                    },
                });
            }}
            onLogClick={
                !enableContainer
                    ? async (row) => {
                          let entity = await getItemCallback(row.id);
                          showAuditLogModal({
                              name: entity.name,
                              children: <UmoAuditLogGrid entity={entity} />,
                          });
                      }
                    : undefined
            }
            permissions={props.permissions}
            {...props}
        />
    );
};
