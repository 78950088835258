import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EdsFormContext from './form-context';
import {
    areObjectValuesEqual,
    debounce,
    extractFormSchemaFromDefinition,
    getFormFromDefinition,
    getLogger,
    setEmptyFieldToUndefined,
} from '../../../features';
import * as Yup from 'yup';
import { DateTime } from 'luxon';
import { useWizard } from '../eds-wizard';
import _ from 'lodash';

const logger = getLogger('EdsFormProvider');

const EdsFormProvider = (props) => {
    const { children, onFormChange } = props;
    const [formDefinitions, setFormDefinitions] = useState({});
    const [initFormValues, setInitFormValues] = useState({});
    const [form, setForm] = useState({});
    const [validation, setValidation] = useState({});
    const [formSchema, setFormSchema] = useState(Yup.object({}));
    const { t } = useTranslation();
    const formOnChangeTimeoutId = useRef(null);
    const { addFormDefinitionForStep, removeWizardFormDefinitions } =
        useWizard();

    Yup.setLocale({
        mixed: {
            required: t(
                '3f5f7f01887d47e3f71bf06e5f475e41',
                'Field is required'
            ),
            notType: (path) => {
                switch (path?.type) {
                    case 'number':
                        return t(
                            'd1d26283539c047d5c72c2b0079c5156',
                            'Please enter a number'
                        );
                    default:
                        return t(
                            '17f93df6d93b1747d04f5cfb0b7a0fe4',
                            'Value is not of type {{type}}',
                            {
                                type: path?.type,
                            }
                        );
                }
            },
            // Custom error messages that are referenced by hash only are placed here
            custom: {
                file: {
                    required: t(
                        'b87a9a67ba05171eeffb3ede04f5d457',
                        'File is required'
                    ),
                    maxCount: t(
                        'e9ccf1f36775b6dce3955c448ae29e6a',
                        'Maximum file count exceeded'
                    ),
                    maxFileSize: t(
                        'a0884fd29d9c60f14f22b31ff7189cfc',
                        'File exceeds maximum file size'
                    ),
                    maxTotalSize: t(
                        '64352602e1ad7b9c201249eaa57600a9',
                        'Maximum total file size exceeded'
                    ),
                    allowedTypes: t(
                        'ede551916187bbec49bf1a46e7c1601d',
                        'File type is not allowed'
                    ),
                },
                cost: {
                    invalid: t(
                        '41bd6a7f9a27479c2821df1d147ee633',
                        "Please enter a cost, for example '12' or '7.50'"
                    ),
                },
            },
        },
        string: {
            length: t(
                '42cd3702484a96050cb20e2f5c03870d',
                'Must be exactly ${length} characters'
            ),
            min: t(
                '78904572b365d51bf2e1b2220851935a',
                'Must be ${min} characters or more'
            ),
            max: t(
                '6296ea3fb1e0ccb6b545d3a7c0eab69f',
                'Must be ${max} characters or less'
            ),
            email: t(
                '636e4a8a5f41d718beb2199623cb7ab7',
                'Please enter a valid email address'
            ),
            matches: t(
                '3f3e0e56753660dfb468fd904ead6190',
                'Value does not meet the requirements'
            ),
            phoneNumber: t(
                '72e4cbc0883679025771510359937a07',
                'Please enter a valid phone number'
            ),
            phoneNumberService: t(
                '4cd8d22c5d1a67c0851c601e840d36a7',
                'Please enter a valid service number (only digits)'
            ),
        },
        number: {
            min: t(
                '4bc6b91742c3abfda2860b4d97188ccd',
                'Must be ${min} or higher'
            ),
            max: t(
                '1dfa3020e25cf43cc1ed6494ce3cf713',
                'Must be ${max} or lower'
            ),
        },
    });

    const addFormDefinition = (formDefinition, prefix) => {
        if (
            !_.isEmpty(prefix) &&
            formDefinitions[prefix]?.resetOnDefinitionChange
        ) {
            removeFormValuesByPrefix(prefix);
        }

        if (prefix) {
            setFormDefinitions((prevState) => ({
                ...prevState,
                [prefix]: formDefinition,
            }));
        } else {
            setFormDefinitions((prevState) => ({
                ...prevState,
                ...formDefinition,
            }));
        }

        if (formDefinition?.wizardStepId) {
            addFormDefinitionForStep(
                formDefinition.wizardStepId,
                formDefinition,
                prefix
            );
        }
    };

    const removeFormDefinitionByPrefix = (prefix) => {
        if (_.isEmpty(prefix) || _.isNil(formDefinitions[prefix])) {
            return;
        }
        removeFormDefinitionsByFilter((key) => key !== prefix);
    };

    const removeFormDefinitionsByFilter = (filter) => {
        if (!_.isFunction(filter)) {
            return;
        }
        let definitionPrefixes = Object.keys(formDefinitions).filter(filter);
        let newFormDefinitions = {};
        definitionPrefixes.map((key) => {
            newFormDefinitions[key] = formDefinitions[key];
        });

        const removedPrefixes = _.difference(
            _.keys(formDefinitions),
            _.keys(newFormDefinitions)
        );

        const wizardPrefixesToRemove = removedPrefixes.reduce((obj, key) => {
            const k = formDefinitions[key].wizardStepId;
            if (obj[k]) {
                obj[k] = [...obj[k], key];
            } else {
                obj[k] = [key];
            }
            return obj;
        }, {});

        setFormDefinitions(newFormDefinitions);
        removeFormValuesByFilter(filter);

        if (
            !_.isEmpty(wizardPrefixesToRemove) &&
            _.isFunction(removeWizardFormDefinitions)
        ) {
            removeWizardFormDefinitions(wizardPrefixesToRemove);
        }
    };

    const removeFormValuesByPrefix = (prefix) => {
        if (_.isEmpty(prefix) || _.isNil(form[prefix])) {
            return;
        }
        removeFormValuesByFilter((key) => key !== prefix);
    };

    const removeFormValuesByFilter = (filter) => {
        if (!_.isFunction(filter)) {
            return;
        }
        let formPrefixes = Object.keys(form).filter(filter);
        let newForm = {};
        formPrefixes.map((key) => {
            newForm[key] = form[key];
        });
        setForm(newForm);
    };

    const getFormKeyValueFromEvent = (event) => {
        let formKey, formValue;

        if (event.target) {
            const { name, value, type, checked } = event.target;
            formKey = name;
            formValue = value;

            if (type === 'checkbox') {
                formValue = checked;
            } else if (type === 'radio') {
                formValue = checked ? value : undefined;
            }
        } else if ('selectedItem' in event) {
            formKey = event.name;
            formValue = event.selectedItem;
            //combobox
            if (
                _.isUndefined(event.selectedItem) &&
                !_.isUndefined(event.inputValue)
            ) {
                formValue = event.inputValue;
            }

            if (_.isNull(formValue)) {
                formValue = {};
            }
        } else if (
            event?.type === 'datepicker' ||
            event?.type === 'datetimepicker'
        ) {
            formKey = event.name;
            formValue = event.event ?? '';
        } else if ('selectedItems' in event) {
            //multiselect dropdown
            formKey = event.name;
            formValue = event.selectedItems;

            if (_.isNull(formValue)) {
                formValue = [];
            }
        }

        logger.log(
            '[getFormKeyValueFromEvent] key:',
            formKey,
            'value:',
            formValue
        );
        return { key: formKey, value: formValue };
    };

    const checkIsFormChanged = () => {
        const initialFormValues = getFormFromDefinition(
            formDefinitions,
            initFormValues
        );

        //compare the current form with the initialFormValues
        //if they are not equal the form is changed
        const equal = areObjectValuesEqual(form, initialFormValues);
        return !equal;
    };

    const updateFormValues = (formChanges, skipCheckInputKeys = false) => {
        let keys = [];
        let values = [];

        for (const key in formChanges) {
            keys.push(key);
            values.push(formChanges[key]);
        }

        handleFormUpdates(keys, values, skipCheckInputKeys);
    };

    const handleFormUpdates = (keys, values, skipCheckInputKeys = false) => {
        logger.log(
            '[handleFormUpdates] keys:',
            keys,
            'values:',
            values,
            'skipCheckInputKeys:',
            skipCheckInputKeys
        );

        const updatedForm = Object.assign({}, form);
        for (let index in keys) {
            const key = keys[index];
            const prefix = getPrefix(key);
            const name = getName(key);

            if (!_.isNil(prefix) && !_.isEmpty(prefix)) {
                if (_.isUndefined(updatedForm[prefix])) {
                    updatedForm[prefix] = {};
                }
                updatedForm[prefix][name] = values[index];
            } else {
                updatedForm[name] = values[index];
            }
        }

        setForm(updatedForm);

        if (_.isFunction(onFormChange)) {
            if (Object.keys(updatedForm).length > 0) {
                onFormChange();
            }
        }

        if (!skipCheckInputKeys) {
            debounce(
                formOnChangeTimeoutId,
                () => {
                    checkInputKeys(keys, updatedForm);
                },
                {
                    trailing: true,
                    delay: 100,
                }
            );
        }
    };

    const handleFormChange = (event) => {
        let keys = [];
        let values = [];

        const { key, value } = getFormKeyValueFromEvent(event);
        keys.push(key);
        values.push(value);

        handleFormUpdates(keys, values);
    };

    const handleOnBlur = (event) => {
        const { key } = getFormKeyValueFromEvent(event);
        checkInputKey(key);
    };

    const checkInputKey = async (key, useForm = form) => {
        checkInputKeys([key], useForm);
    };

    const checkInputKeys = async (keys, useForm = form) => {
        logger.log('[checkInputKeys] keys:', keys, 'useForm:', useForm);

        for (let index in keys) {
            let key = keys[index];
            const formDefinition = getFormDefinition(key);
            if (
                !_.isNil(formDefinition) &&
                _.isArray(formDefinition.dependents)
            ) {
                const prefix = getPrefix(key);
                for (const index in formDefinition.dependents) {
                    let dependentKey = formDefinition.dependents[index];
                    let dependentName = getName(dependentKey);
                    let dependentPrefix = getPrefix(dependentKey);

                    // Use prefix of dependent key if given, or else use prefix of dependency
                    let usePrefix = !_.isEmpty(dependentPrefix)
                        ? dependentPrefix
                        : prefix;
                    let useDependentKey = getFormKey(dependentName, usePrefix);

                    const dependentValue = getFormValue(
                        dependentName,
                        usePrefix
                    );
                    if (
                        _.isNil(dependentValue) &&
                        !isInvalid(useDependentKey)
                    ) {
                        // Dependent value is not set and valid, skip validation
                        continue;
                    }

                    if (keys.indexOf(useDependentKey) === -1) {
                        logger.log(
                            '[checkInputKeys] adding dependent key:',
                            useDependentKey
                        );
                        keys.push(useDependentKey);
                    }
                }
            }
        }

        let newValidation = {};

        for (let index in keys) {
            let key = keys[index];
            let errorMessage = null;
            try {
                await formSchema
                    .validateAt(key, useForm, { context: { form: useForm } })
                    .catch((reason) => {
                        errorMessage = reason.message;
                        logger.warn(
                            '[checkInputKeys] key:' +
                                key +
                                ' error:' +
                                errorMessage
                        );
                    });
            } catch (error) {
                logger.warn('[checkInputKeys] error:', error.message);
            }
            if (!errorMessage) {
                logger.log('[checkInputKeys] validated key:', key);
            }
            newValidation[key] = {
                invalid: errorMessage != null,
                invalidText: errorMessage,
            };
        }

        setValidation((prevValidation) => {
            return { ...prevValidation, ...newValidation };
        });
    };

    const checkInputs = async (schema = formSchema) => {
        let newValidation = {};
        let schemaKeys = getFieldKeys(schema.fields);
        logger.log('[checkInputs] keys:', schemaKeys);

        const options = {
            abortEarly: false,
            context: { form: form },
        };

        const isValid = await schema.isValid(form, options);
        if (!isValid) {
            let errorKeys = [];
            schema
                .validate(form, options)
                .catch((error) => {
                    for (let index in error.inner) {
                        const innerError = error.inner[index];
                        if (_.isUndefined(innerError)) {
                            return;
                        }

                        let key = innerError.path.replaceAll(/\["|"]/g, '');
                        let errorMessage = innerError.message.replaceAll(
                            /\["|"]/g,
                            ''
                        );
                        logger.warn(
                            '[checkInputs] key:',
                            key,
                            'error:',
                            errorMessage
                        );

                        errorKeys.push(key);
                        newValidation[key] = {
                            invalid: true,
                            invalidText: errorMessage,
                        };
                    }
                })
                .finally(() => {
                    for (let index in schemaKeys) {
                        let schemaKey = schemaKeys[index];
                        if (
                            errorKeys.indexOf(schemaKey) === -1 &&
                            validation[schemaKey]
                        ) {
                            logger.log(
                                '[checkInputs] validated key:',
                                schemaKey
                            );
                            newValidation[schemaKey] = { invalid: false };
                        }
                    }

                    setValidation((prevValidation) => {
                        return { ...prevValidation, ...newValidation };
                    });
                });
        } else {
            for (let index in schemaKeys) {
                let schemaKey = schemaKeys[index];
                if (validation[schemaKey]) {
                    logger.log('[checkInputs] key:', schemaKey, 'validated');
                    newValidation[schemaKey] = { invalid: false };
                }
            }
            setValidation((prevValidation) => {
                return { ...prevValidation, ...newValidation };
            });
        }

        return isValid;
    };

    const checkInputsSection = async (customDefinition) => {
        const schema = extractFormSchemaFromDefinition(customDefinition);
        return checkInputs(schema);
    };

    const getPrefix = (key) => {
        let keyParts = key.split('.');
        if (keyParts.length > 1) {
            return keyParts[0];
        }
        return '';
    };

    const getName = (key) => {
        let keyParts = key.split('.');
        if (keyParts.length > 1) {
            return keyParts[1];
        }
        return key;
    };

    const getFormKey = (name, prefix) => {
        if (!_.isNil(prefix) && !_.isEmpty(prefix)) {
            return prefix + '.' + name;
        }
        return name;
    };

    const getFormDefinition = (key) => {
        let keyParts = key.split('.');
        let formDefinition = null;
        for (let index in keyParts) {
            let keyPart = keyParts[index];
            if (formDefinition) {
                formDefinition = formDefinition[keyPart];
            } else {
                formDefinition = formDefinitions[keyPart];
            }
            if (!formDefinition) {
                return null;
            }
        }
        return formDefinition;
    };

    const getFieldKeys = (fields, prefix = '') => {
        let keys = [];
        if (!_.isObject(fields) || _.isEmpty(fields)) {
            return keys;
        }

        for (let key in fields) {
            let field = fields[key];
            if (_.isObject(field.fields) && !_.isEmpty(field.fields)) {
                keys = keys.concat(getFieldKeys(field.fields, key + '.'));
            } else {
                keys.push(prefix + key);
            }
        }
        return keys;
    };

    const getFormValue = (key, prefix) => {
        let formValue;

        if (!form) {
            return formValue;
        }

        if (prefix && prefix in form) {
            if (!_.isUndefined(form[prefix])) {
                if (_.isPlainObject(form[prefix])) {
                    if (key in form[prefix]) {
                        formValue = form[prefix][key];
                    }
                } else {
                    logger.info(`Prefix$ ${prefix} is no object in form`);
                }
            } else {
                logger.info(`Prefix$ ${prefix} is undefined in form`);
            }
        } else if (key in form) {
            formValue = form[key];
        }
        return formValue;
    };

    const isFieldRequired = (key, prefix) => {
        let formDefinition = {};

        if (prefix && prefix in formDefinitions) {
            if (!_.isUndefined(formDefinitions[prefix])) {
                if (key in formDefinitions[prefix]) {
                    formDefinition = formDefinitions[prefix][key];
                }
            } else {
                logger.info(
                    `Prefix$ ${prefix} is undefined in formDefinitions`
                );
            }
        } else if (key in formDefinitions) {
            formDefinition = formDefinitions[key];
        }

        return formDefinition?.validation?.required ?? false;
    };

    const formatLabel = (label, key, prefix, forceRequired) => {
        return (
            label +
            ' ' +
            (forceRequired || isFieldRequired(key, prefix) ? '*' : '')
        );
    };

    const isInvalid = (prefixedName) => {
        if (validation && prefixedName in validation) {
            return validation[prefixedName]?.invalid;
        }
        return false;
    };

    const getInvalidText = (prefixedName) => {
        if (isInvalid(prefixedName)) {
            if (validation[prefixedName].invalidText) {
                let invalidText = validation[prefixedName].invalidText;
                // Check if the invalidText has the format of an i18n translation key to prevent error logging
                let regex = /^[a-z0-9]{32}$/g;
                if (regex.test(invalidText)) {
                    // Use invalidText as i18n translation key with invalidText as fallback
                    return t(invalidText, {
                        defaultValue: invalidText,
                    });
                } else {
                    // Use invalidText directly
                    return invalidText;
                }
            }
        }
        return '';
    };

    const mapData = async (key, value, mapKey) => {
        let newKey = key;
        let newValue = value;

        let formDef = formDefinitions;
        if (mapKey in formDef) {
            formDef = formDef[mapKey];
        }

        if (key in formDef) {
            if (_.isPlainObject(formDef[key]) && 'mapping' in formDef[key]) {
                const mapping = formDef[key]['mapping'];
                if (_.isPlainObject(value)) {
                    newValue = value[mapping];
                } else if (_.isArray(value)) {
                    newValue = value.map((item) => item[mapping]);
                } else if (_.isString(mapping)) {
                    newKey = mapping;
                }
            } else if (_.isObject(newValue) && !_.isArray(newValue)) {
                if (newValue instanceof Date) {
                    //the fields with type datepicker do not need a time.  like a birthdate
                    if (formDef[key]?.validation?.type === 'datepicker') {
                        newValue =
                            DateTime.fromJSDate(newValue).toFormat(
                                'yyyy-MM-dd'
                            );
                    } else if (
                        formDef[key]?.validation?.type === 'datetimepicker'
                    ) {
                        newValue = newValue.toJSON();
                    } else {
                        newValue = newValue.toJSON();
                    }
                } else {
                    newValue = await getMappedForm(newValue, key);
                }
            }
        }

        return {
            newKey: newKey,
            newValue: newValue,
        };
    };

    const getMappedForm = async (toMap = form, mapKey) => {
        let returnData = {};

        if (_.isUndefined(toMap) || _.isNull(toMap)) {
            return returnData;
        }

        for (const [key, value] of Object.entries(toMap)) {
            const { newKey, newValue } = await mapData(
                key,
                value ?? '',
                mapKey
            );
            returnData[newKey] = newValue;
        }
        return returnData;
    };

    const replaceFormValues = (values) => {
        logger.log('[replaceFormValues]', values);
        const getForm = getFormFromDefinition(formDefinitions, values);
        setForm(getForm);
    };

    const providerValue = {
        addFormDefinition,
        removeFormDefinitionByPrefix,
        removeFormDefinitionsByFilter,
        getFormValue,
        removeFormValuesByPrefix,
        removeFormValuesByFilter,
        form,
        checkIsFormChanged,
        handleFormChange,
        updateFormValues,
        handleOnBlur,
        validation,
        checkInputs,
        checkInputsSection,
        setInitFormValues,
        replaceFormValues,
        getMappedForm,
        isFieldRequired,
        formatLabel,
        isInvalid,
        getInvalidText,
    };

    useEffect(() => {
        if (
            !_.isEmpty(
                _.difference(_.keys(formDefinitions), _.keys(form)).filter(
                    (key) => key !== 'id'
                )
            )
        ) {
            const getForm = setEmptyFieldToUndefined(
                getFormFromDefinition(formDefinitions, initFormValues, form)
            );

            setForm(getForm);
            setFormSchema(extractFormSchemaFromDefinition(formDefinitions));
        }
    }, [form, formDefinitions, initFormValues, t]);

    useEffect(() => {
        logger.log('[formDefinitions]', formDefinitions);
        if (_.isEmpty(formDefinitions)) {
            return;
        }
        setFormSchema(extractFormSchemaFromDefinition(formDefinitions));
        if (_.isUndefined(initFormValues) || _.isEmpty(initFormValues)) {
            let newForm = setDefaultValues(form, formDefinitions);
            setForm(newForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formDefinitions]);

    const setDefaultValues = (currentForm, definition) => {
        for (let key in definition) {
            let definitionRule = definition[key];
            if (!_.isPlainObject(definitionRule)) {
                if (_.isBoolean(definitionRule)) {
                    currentForm[key] = definitionRule;
                }
                continue;
            }

            if (Object.keys(definitionRule).includes('validation')) {
                if (
                    !Object.keys(currentForm).includes(key) ||
                    _.isUndefined(currentForm[key])
                ) {
                    currentForm[key] = definitionRule.value ?? undefined;
                }
            } else {
                currentForm[key] = setDefaultValues(
                    currentForm[key] ?? {},
                    definitionRule
                );
            }
        }
        return currentForm;
    };

    useEffect(() => {
        logger.log('[formSchema]', formSchema);
    }, [formSchema]);

    useEffect(() => {
        logger.log('[form]', form);
    }, [form]);

    useEffect(() => {
        logger.log('[validation]', validation);
    }, [validation]);

    useEffect(() => {
        logger.log('[initFormValues]', initFormValues);
    }, [initFormValues]);

    return (
        <EdsFormContext.Provider value={providerValue}>
            {children}
        </EdsFormContext.Provider>
    );
};

export default EdsFormProvider;
