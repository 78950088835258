import { forwardRef } from 'react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsTextArea,
} from '../../..';
import { useTranslation } from 'react-i18next';
import { getAlarmCriteria, useNotifications } from '../../../../features';
import { Row } from '@carbon/react';

const CriteriaRenumberingForm = forwardRef(
    (
        {
            parentId,
            unavailableNumbers = [],
            postDataCallback,
            putDataCallback,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const { showSuccess, showError } = useNotifications();

        const alarmCriterionMappingCallback = (data) => {
            return {
                id: data.id,
                number: data.number,
                text: `${data.name} (${data.number})`,
            };
        };

        const getAlarmCriteriaForDeviceCriterion = async () => {
            const alarmCriteria = await getAlarmCriteria();
            return alarmCriteria.filter(
                (item) => !unavailableNumbers.includes(item.number)
            );
        };

        const formDefinition = {
            deviceCriterion: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'number',
            },
            umoCriterion: {
                validation: {
                    type: 'dropdown',
                    required: true,
                },
                mapping: 'number',
            },
            remarks: {
                validation: {
                    required: false,
                    max: 80,
                },
            },
        };

        const saveData = async (form) => {
            form.parentId = parentId;
            if (!form.id) {
                return await postDataCallback(form);
            } else {
                return await putDataCallback(form);
            }
        };

        const onSubmit = async (_event, form, isValid) => {
            if (!isValid) {
                throw false;
            }

            try {
                const response = await saveData(form);

                showSuccess({
                    title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                    content: t(
                        '18a093083fc263a2e179b2571f604d93',
                        '{{entity}} is saved',
                        {
                            entity: t(
                                '327b0f7e1b948f544f8bddc6716e9ae1',
                                'Alarm criteria renumbering'
                            ),
                        }
                    ),
                });

                return response;
            } catch (error) {
                showError({
                    title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                    content: t(
                        'df0a9d46baf7315909e4389a04786e3d',
                        'Oops something went wrong'
                    ),
                });

                throw error;
            }
        };

        return (
            <EdsForm
                hideDefaultSubmit={props.hideDefaultSubmit}
                initValues={props.initValues}
                formDefinition={formDefinition}
                onSubmit={onSubmit}
                ref={ref}
            >
                <EdsFormGroup>
                    <Row>
                        <EdsFormColumn>
                            <EdsDropdown
                                name="deviceCriterion"
                                label={t(
                                    'df9cf0c8d13f0960d82aa9a111475de8',
                                    'From criteria'
                                )}
                                type={EdsDropdownType.ComboBox}
                                getDataCallback={
                                    getAlarmCriteriaForDeviceCriterion
                                }
                                mappingCallback={alarmCriterionMappingCallback}
                            />
                        </EdsFormColumn>
                        <EdsFormColumn>
                            <EdsDropdown
                                name="umoCriterion"
                                label={t(
                                    '4c836076e2338b7bd1a7d2e3f11478f2',
                                    'To criteria'
                                )}
                                type={EdsDropdownType.ComboBox}
                                getDataCallback={getAlarmCriteria}
                                mappingCallback={alarmCriterionMappingCallback}
                            />
                        </EdsFormColumn>
                    </Row>
                    <Row>
                        <EdsFormColumn fullWidth>
                            <EdsTextArea
                                name="remarks"
                                label={t(
                                    '911c185c8f3c475cdca2ef9cf12166da',
                                    'Remark'
                                )}
                                enableCounter={true}
                                maxCount={
                                    formDefinition?.remarks?.validation?.max
                                }
                                rows={2}
                            ></EdsTextArea>
                        </EdsFormColumn>
                    </Row>
                </EdsFormGroup>
            </EdsForm>
        );
    }
);

CriteriaRenumberingForm.displayName = 'CriteriaRenumberingForm';

export default CriteriaRenumberingForm;
