import { createContext, useContext } from 'react';
import { useAuth } from '../auth';
import _ from 'lodash';

const PermissionsContext = createContext({
    isAllowed: () => false,
    isAllowedAnyAccount: () => false,
});

export const PermissionsProvider = ({ children }) => {
    const { allPermissions, currentPermissions } = useAuth();

    const isAllowed = (scope, requiresAll = true) => {
        if (_.isArray(scope)) {
            if (requiresAll) {
                return _.difference(scope, currentPermissions).length === 0;
            }
            return (
                _.difference(scope, currentPermissions).length < scope.length
            );
        }
        return currentPermissions?.includes(scope);
    };

    const isAllowedAnyAccount = (scope, requiresAll = true) => {
        if (_.isArray(scope)) {
            if (requiresAll) {
                return _.difference(scope, allPermissions).length === 0;
            }
            return _.difference(scope, allPermissions).length < scope.length;
        }
        return allPermissions?.includes(scope);
    };

    return (
        <PermissionsContext.Provider value={{ isAllowed, isAllowedAnyAccount }}>
            {children}
        </PermissionsContext.Provider>
    );
};

export const usePermissions = () => useContext(PermissionsContext);
