import _ from 'lodash';
import { getLogger } from './logger';

const logger = getLogger('RefererRedirect');
const refererKey = 'referer';

const invalidPathsPatterns = [
    /^[^/]/, // paths without start slash
    /\/auth\/.*/, // authorisation paths
    /\/account[/]?$/, // /account or /account/
];

export const setReferer = (referer) => {
    logger.log('Set referer', referer);

    if (isInvalidReferer(referer)) {
        logger.log('Skip unsupported referer path');
        return;
    }
    const refererInfo = getRefererInfo();
    if (!_.isEmpty(refererInfo.referer)) {
        logger.log('Skip as referer is already set', refererInfo.referer);
        return;
    }

    localStorage.setItem(refererKey, encodeURI(referer));
};

export const getRefererInfo = (removeAfterGet = false) => {
    let referer = localStorage.getItem(refererKey);
    referer = !_.isNil(referer) ? decodeURI(referer) : undefined;

    if (removeAfterGet && !_.isNil(referer)) {
        removeReferer();
    }

    let accountId;
    if (!_.isEmpty(referer)) {
        const accountRegex = /^\/account\/(\d+)/;
        const found = referer.match(accountRegex);
        if (!_.isNull(found) && found.length == 2) {
            accountId = found[1];
        }
    }

    logger.log('Get referer', referer);

    return {
        referer: referer,
        accountId: accountId,
    };
};

export const removeReferer = () => {
    logger.log('Remove referer');
    localStorage.removeItem(refererKey);
};

export const getRefererLoginUrl = () => {
    const referer = window.location.pathname + window.location.search;
    const loginUrl = '/auth/login';

    if (isInvalidReferer(referer)) {
        logger.log('Skip unsupported referer path as search param', referer);
        return loginUrl;
    }

    logger.log('getRefererLoginUrl search param', referer);
    return `${loginUrl}?r=${encodeURI(referer)}`;
};

export const isInvalidReferer = (referer) => {
    return invalidPathsPatterns.reduce((acc, pathRegEx) => {
        return acc || pathRegEx.test(referer);
    }, false);
};
