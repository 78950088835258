import {
    Download,
    Edit,
    FlowLogsVpc,
    TrashCan,
    Unlink,
    Image,
    CharacterPatterns,
} from '@carbon/icons-react';
import { IconButton } from '@carbon/react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import './eds-table-actionbar-button.scss';
import { useEffect, useState } from 'react';

export function EdsTableActionbarButton({
    kind,
    label,
    size,
    align,
    icon,
    ...props
}) {
    const [iconLabel] = useState(_.isFunction(label) ? label() : label);
    const [iconAlign, setIconAlign] = useState(align);

    useEffect(() => {
        if (iconLabel.length > 15) {
            setIconAlign('top-right');
        }
    }, [iconLabel]);

    const handleOnClick = (event) => {
        if (_.isFunction(props.onClick)) {
            props.onClick(event);
        }
        event.stopPropagation();
    };

    const getStyle = () => {
        let classes = ['eds-table-actionbar-button'];
        if (props?.className) {
            classes.push(props?.className);
        }
        return classes.join(' ');
    };

    return (
        <IconButton
            className={getStyle()}
            label={iconLabel}
            kind={kind ?? 'ghost'}
            size={size ?? 'lg'}
            align={iconAlign}
            onClick={handleOnClick}
        >
            {icon}
        </IconButton>
    );
}

export function EdsTableReplaceButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            className={'eds-table-replace-button'}
            label={label ?? t('9dde360102c103867bd2f45872f1129c', 'Replace')}
            icon={<CharacterPatterns />}
            {...props}
        />
    );
}

export function EdsTableDeleteButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            className={'eds-table-delete-button'}
            label={label ?? t('9530d977022e1fce705fc0f93af0a9b7', 'Delete row')}
            icon={<TrashCan />}
            {...props}
        />
    );
}

export function EdsTableUnlinkButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            className={'eds-table-unlink-button'}
            label={label ?? t('695630cfc5eb92580fb3e76a0c790e63', 'Unlink')}
            icon={<Unlink />}
            {...props}
        />
    );
}

export function EdsTableImageButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('78805a221a988e79ef3f42d7c5bfd418', 'Image')}
            icon={<Image />}
            {...props}
        />
    );
}

export function EdsTableEditButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('176ed580b73b60b8c497dd28cdf2afc0', 'Edit row')}
            icon={<Edit />}
            {...props}
        />
    );
}

export function EdsTableDownloadButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('fd456406745d816a45cae554c788e754', 'Download')}
            icon={<Download />}
            {...props}
        />
    );
}

export function EdsTableLogButton({ label, ...props }) {
    const { t } = useTranslation();

    return (
        <EdsTableActionbarButton
            label={label ?? t('7d71019726dccc04ceb52b94f892b7f6', 'Audit log')}
            icon={<FlowLogsVpc />}
            {...props}
        />
    );
}
