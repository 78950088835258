import { useTranslation } from 'react-i18next';
import { useForm, useFormGroup } from '../eds-form';
import './eds-date-picker.scss';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { Calendar } from '@carbon/icons-react';
import { Row } from '@carbon/react';
import { EdsFormColumn } from '../eds-form/eds-form-column/eds-form-column';
import _ from 'lodash';
import {
    getDate,
    getTimeFromJSDate,
    isValidDate,
    toJSDate,
} from '../../../features';
import i18n from 'i18next';
import { parse } from 'date-fns';
import { nl, enUS, enGB, nn, nb, sv, de, da, fi } from 'date-fns/locale';
import { forwardRef, useImperativeHandle } from 'react';
import { DateTime } from 'luxon';

const locales = {
    'nl-NL': nl,
    'en-devel': enUS,
    'en-US': enUS,
    'en-GB': enGB,
    nn: nn,
    'nb-NO': nb,
    sv: sv,
    de: de,
    da: da,
    fi: fi,
};

for (const [i18nLocale, dateLocale] of Object.entries(locales)) {
    registerLocale(i18nLocale, dateLocale);
}
setDefaultLocale('en-US');

export const EdsDatePickerType = Object.freeze({
    Date: 'DATE',
    DateTime: 'DATE_TIME',
    DateRange: 'DATE_RANGE',
    DateTimeRange: 'DATE_TIME_RANGE',
    TimeRange: 'TIME_RANGE',
});

const isSameDay = (minDate, maxDate) => {
    return (
        minDate instanceof Date &&
        maxDate instanceof Date &&
        minDate.getDate() === maxDate.getDate() &&
        minDate.getMonth() === maxDate.getMonth() &&
        minDate.getFullYear() === maxDate.getFullYear()
    );
};

const getMinTime = (minDate, maxDate) => {
    if (isSameDay(minDate, maxDate)) {
        return DateTime.now()
            .set({
                hour: minDate.getHours(),
                minute: minDate.getMinutes(),
                second: minDate.getSeconds(),
                millisecond: minDate.getMilliseconds(),
            })
            .toJSDate();
    }
    return DateTime.now()
        .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
        })
        .toJSDate();
};

const getMaxTime = (minDate, maxDate) => {
    if (isSameDay(minDate, maxDate)) {
        return DateTime.now()
            .set({
                hour: maxDate.getHours(),
                minute: maxDate.getMinutes(),
                second: maxDate.getSeconds(),
                millisecond: maxDate.getMilliseconds(),
            })
            .toJSDate();
    }
    return DateTime.now()
        .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 999,
        })
        .toJSDate();
};

export const EdsDatePicker = forwardRef(
    (
        {
            name,
            label,
            fromName,
            toName,
            fromLabel,
            toLabel,
            forceRequired,
            disabled = false,
            type = EdsDatePickerType.Date,
            onChangeCallback,
            ...props
        },
        ref
    ) => {
        const { t } = useTranslation();
        const {
            formatLabel,
            getFormValue,
            updateFormValues,
            isInvalid,
            getInvalidText,
        } = useForm();
        const { prefix, usePrefix } = useFormGroup();
        let prefixedName = usePrefix(name) ?? name;
        let prefixedFromName = usePrefix(fromName) ?? fromName;
        let prefixedToName = usePrefix(toName) ?? toName;

        const updateDate = (date, name = prefixedName) => {
            handleOnChange(date, name);
        };

        useImperativeHandle(ref, () => ({
            updateDate,
        }));

        const getValue = (name) => {
            const val = getFormValue(name, prefix);
            return toJSDate(val, true);
        };

        const handleOnChange = (date, name) => {
            if (
                !(date instanceof Date) &&
                !_.isNull(date) &&
                !_.isEmpty(date)
            ) {
                date = parse(
                    date,
                    type === EdsDatePickerType.DateTime ||
                        type === EdsDatePickerType.DateTimeRange
                        ? t(
                              '6eb1403d4ccd7c4731ffa90b6c01beef',
                              'MM/dd/yyyy h:mm a'
                          )
                        : t('418c23834fc746227d6ba6bbf181e8b5', 'MM/dd/yyyy'),
                    new Date()
                );
            }

            if (!isValidDate(date)) {
                date = null;
            }

            updateFormValues({
                [name]: date,
            });

            if (_.isFunction(onChangeCallback)) {
                onChangeCallback(date);
            }
        };

        const getWrapperClasses = (prefixedName) => {
            let classes = ['cds--text-input__label-wrapper'];
            if (isInvalid(prefixedName)) {
                classes.push('text-input--invalid');
            }
            return classes.join(' ');
        };

        const DatePickerWrapper = ({
            label,
            name,
            prefixedName,
            fullWidth = false,
            ...props
        }) => {
            return (
                <EdsFormColumn fullWidth={fullWidth}>
                    <div className={getWrapperClasses(prefixedName)}>
                        <label
                            htmlFor={prefixedName}
                            className="cds--label"
                            dir="auto"
                        >
                            {formatLabel(label, name, prefix, forceRequired)}
                        </label>
                    </div>
                    {props.children}
                    {isInvalid(prefixedName) && (
                        <div
                            className="cds--form-requirement"
                            id="description-error-msg"
                            dir="auto"
                        >
                            {getInvalidText(prefixedName)}
                        </div>
                    )}
                </EdsFormColumn>
            );
        };

        const getPickerClasses = (prefixedName) => {
            let classes = ['eds-date-picker-input', 'cds--text-input'];
            if (isInvalid(prefixedName)) {
                classes.push('cds--text-input--invalid');
            }
            return classes.join(' ');
        };

        const getDatePicker = (type) => {
            const defaultProps = {
                maxDate: props.maxDate,
                minDate: props.minDate,
                closeOnScroll: true,
                isClearable: true,
                dateFormat: t('418c23834fc746227d6ba6bbf181e8b5', 'MM/dd/yyyy'),
                showIcon: true,
                showPopperArrow: false,
                toggleCalendarOnIconClick: true,
                showWeekNumbers: true,
                icon: <Calendar />,
                placeholderText: '',
                disabled: disabled,
                locale: i18n.language,
            };

            switch (type) {
                case EdsDatePickerType.DateRange: {
                    return (
                        <>
                            <DatePickerWrapper
                                label={fromLabel}
                                name={fromName}
                                prefixedName={prefixedFromName}
                            >
                                <DatePicker
                                    {...defaultProps}
                                    id={prefixedFromName}
                                    name={prefixedFromName}
                                    className={getPickerClasses(
                                        prefixedFromName
                                    )}
                                    selectsStart
                                    selected={getValue(fromName)}
                                    startDate={getValue(fromName)}
                                    endDate={getValue(toName)}
                                    maxDate={getValue(toName)}
                                    invalid={isInvalid(prefixedFromName)}
                                    invalidText={getInvalidText(
                                        prefixedFromName
                                    )}
                                    onSelect={(date) => {
                                        handleOnChange(date, prefixedFromName);
                                    }}
                                    onBlur={({ target: { value } }) => {
                                        handleOnChange(value, prefixedFromName);
                                    }}
                                    onChange={(date) => {
                                        if (_.isNull(date)) {
                                            handleOnChange(
                                                null,
                                                prefixedFromName
                                            );
                                        }
                                    }}
                                />
                            </DatePickerWrapper>
                            <DatePickerWrapper
                                label={toLabel}
                                name={toName}
                                prefixedName={prefixedToName}
                            >
                                <DatePicker
                                    {...defaultProps}
                                    id={prefixedToName}
                                    name={prefixedToName}
                                    className={getPickerClasses(prefixedToName)}
                                    selectsEnd
                                    selected={getValue(toName)}
                                    minDate={getValue(fromName)}
                                    startDate={getValue(fromName)}
                                    endDate={getValue(toName)}
                                    invalid={isInvalid(prefixedToName)}
                                    invalidText={getInvalidText(prefixedToName)}
                                    onSelect={(date) => {
                                        handleOnChange(date, prefixedToName);
                                    }}
                                    onBlur={({ target: { value } }) => {
                                        handleOnChange(value, prefixedToName);
                                    }}
                                    onChange={(date) => {
                                        if (_.isNull(date)) {
                                            handleOnChange(
                                                null,
                                                prefixedToName
                                            );
                                        }
                                    }}
                                />
                            </DatePickerWrapper>
                        </>
                    );
                }
                case EdsDatePickerType.DateTimeRange: {
                    return (
                        <>
                            <DatePickerWrapper
                                label={fromLabel}
                                name={fromName}
                                prefixedName={prefixedFromName}
                            >
                                <DatePicker
                                    {...defaultProps}
                                    id={prefixedFromName}
                                    name={prefixedFromName}
                                    className={getPickerClasses(
                                        prefixedFromName
                                    )}
                                    selectsStart
                                    selected={getValue(fromName)}
                                    startDate={getValue(fromName)}
                                    endDate={getValue(toName)}
                                    maxDate={getValue(toName)}
                                    invalid={isInvalid(prefixedFromName)}
                                    invalidText={getInvalidText(
                                        prefixedFromName
                                    )}
                                    onSelect={(date) => {
                                        handleOnChange(date, prefixedFromName);
                                    }}
                                    onBlur={({ target: { value } }) => {
                                        handleOnChange(value, prefixedFromName);
                                    }}
                                    onChange={(date) => {
                                        handleOnChange(date, prefixedFromName);
                                    }}
                                    showTimeSelect
                                    minTime={DateTime.now()
                                        .set({
                                            hour: 0,
                                            minute: 0,
                                            second: 0,
                                            millisecond: 0,
                                        })
                                        .toJSDate()}
                                    maxTime={getMaxTime(
                                        getValue(fromName),
                                        getValue(toName)
                                    )}
                                    timeFormat={t(
                                        '72afaa6e494ed2dfc89864418115d41f',
                                        'h:mm a'
                                    )}
                                    timeIntervals={15}
                                    timeCaption={t(
                                        '07cc694b9b3fc636710fa08b6922c42b',
                                        'Time'
                                    )}
                                    dateFormat={t(
                                        '6eb1403d4ccd7c4731ffa90b6c01beef',
                                        'MM/dd/yyyy h:mm a'
                                    )}
                                />
                            </DatePickerWrapper>
                            <DatePickerWrapper
                                label={toLabel}
                                name={toName}
                                prefixedName={prefixedToName}
                            >
                                <DatePicker
                                    {...defaultProps}
                                    id={prefixedToName}
                                    name={prefixedToName}
                                    className={getPickerClasses(prefixedToName)}
                                    selectsEnd
                                    selected={getValue(toName)}
                                    minDate={getValue(fromName)}
                                    startDate={getValue(fromName)}
                                    endDate={getValue(toName)}
                                    invalid={isInvalid(prefixedToName)}
                                    invalidText={getInvalidText(prefixedToName)}
                                    onSelect={(date) => {
                                        handleOnChange(date, prefixedToName);
                                    }}
                                    onBlur={({ target: { value } }) => {
                                        handleOnChange(value, prefixedToName);
                                    }}
                                    onChange={(date) => {
                                        handleOnChange(date, prefixedToName);
                                    }}
                                    showTimeSelect
                                    minTime={getMinTime(
                                        getValue(fromName),
                                        getValue(toName)
                                    )}
                                    maxTime={DateTime.now()
                                        .set({
                                            hour: 23,
                                            minute: 59,
                                            second: 59,
                                            millisecond: 999,
                                        })
                                        .toJSDate()}
                                    timeFormat={t(
                                        '72afaa6e494ed2dfc89864418115d41f',
                                        'h:mm a'
                                    )}
                                    timeIntervals={15}
                                    timeCaption={t(
                                        '07cc694b9b3fc636710fa08b6922c42b',
                                        'Time'
                                    )}
                                    dateFormat={t(
                                        '6eb1403d4ccd7c4731ffa90b6c01beef',
                                        'MM/dd/yyyy h:mm a'
                                    )}
                                />
                            </DatePickerWrapper>
                        </>
                    );
                }
                case EdsDatePickerType.DateTime: {
                    return (
                        <DatePickerWrapper
                            label={label}
                            name={name}
                            prefixedName={prefixedName}
                            fullWidth
                        >
                            <DatePicker
                                {...defaultProps}
                                id={prefixedName}
                                name={prefixedName}
                                className={getPickerClasses(prefixedName)}
                                selected={getValue(name)}
                                invalid={isInvalid(prefixedName)}
                                invalidText={getInvalidText(prefixedName)}
                                onSelect={(date) => {
                                    handleOnChange(date, prefixedName);
                                }}
                                onBlur={({ target: { value } }) => {
                                    handleOnChange(value, prefixedName);
                                }}
                                onChange={(date) => {
                                    handleOnChange(date, prefixedName);
                                }}
                                showTimeSelect
                                timeFormat={t(
                                    '72afaa6e494ed2dfc89864418115d41f',
                                    'h:mm a'
                                )}
                                timeIntervals={15}
                                timeCaption={t(
                                    '07cc694b9b3fc636710fa08b6922c42b',
                                    'Time'
                                )}
                                dateFormat={t(
                                    '6eb1403d4ccd7c4731ffa90b6c01beef',
                                    'MM/dd/yyyy h:mm a'
                                )}
                            />
                        </DatePickerWrapper>
                    );
                }
                case EdsDatePickerType.TimeRange: {
                    const getTimeFromValue = (
                        field,
                        defaultEndOfDay = false
                    ) => {
                        const value = getValue(field);
                        let timeObject = {};

                        const { minutes, hours } = getTimeFromJSDate(value);

                        if (!_.isUndefined(minutes) && !_.isUndefined(hours)) {
                            timeObject = {
                                plusMinutes: minutes,
                                plusHours: hours,
                            };
                        } else {
                            if (defaultEndOfDay) {
                                timeObject = {
                                    plusMinutes: 59,
                                    plusHours: 23,
                                };
                            }
                        }
                        return getDate(timeObject);
                    };

                    const dateFormat = t(
                        '72afaa6e494ed2dfc89864418115d41f',
                        'h:mm a'
                    );

                    return (
                        <>
                            <DatePickerWrapper
                                label={fromLabel}
                                name={fromName}
                                prefixedName={prefixedFromName}
                            >
                                <DatePicker
                                    {...defaultProps}
                                    dateFormat={dateFormat}
                                    id={prefixedFromName}
                                    name={prefixedFromName}
                                    className={getPickerClasses(
                                        prefixedFromName
                                    )}
                                    selectsStart
                                    selected={getValue(fromName)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeFormat={dateFormat}
                                    timeIntervals={15}
                                    timeCaption={t(
                                        '07cc694b9b3fc636710fa08b6922c42b',
                                        'Time'
                                    )}
                                    minTime={getDate()}
                                    maxTime={getTimeFromValue(toName, true)}
                                    invalid={isInvalid(prefixedFromName)}
                                    invalidText={getInvalidText(
                                        prefixedFromName
                                    )}
                                    onChange={(date) => {
                                        handleOnChange(date, prefixedFromName);
                                    }}
                                />
                            </DatePickerWrapper>
                            <DatePickerWrapper
                                label={toLabel}
                                name={toName}
                                prefixedName={prefixedToName}
                            >
                                <DatePicker
                                    {...defaultProps}
                                    dateFormat={dateFormat}
                                    id={prefixedToName}
                                    name={prefixedToName}
                                    className={getPickerClasses(prefixedToName)}
                                    selectsEnd
                                    selected={getValue(toName)}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeFormat={dateFormat}
                                    timeIntervals={15}
                                    timeCaption={t(
                                        '07cc694b9b3fc636710fa08b6922c42b',
                                        'Time'
                                    )}
                                    minTime={getTimeFromValue(fromName)}
                                    maxTime={getDate({
                                        plusHours: 23,
                                        plusMinutes: 59,
                                    })}
                                    invalid={isInvalid(prefixedToName)}
                                    invalidText={getInvalidText(prefixedToName)}
                                    onChange={(date) => {
                                        handleOnChange(date, prefixedToName);
                                    }}
                                />
                            </DatePickerWrapper>
                        </>
                    );
                }
                case EdsDatePickerType.Date:
                default: {
                    return (
                        <DatePickerWrapper
                            label={label}
                            name={name}
                            prefixedName={prefixedName}
                            fullWidth
                        >
                            <DatePicker
                                {...defaultProps}
                                id={prefixedName}
                                name={prefixedName}
                                className={getPickerClasses(prefixedName)}
                                selected={getValue(name)}
                                invalid={isInvalid(prefixedName)}
                                invalidText={getInvalidText(prefixedName)}
                                onSelect={(date) => {
                                    handleOnChange(date, prefixedName);
                                }}
                                onBlur={({ target: { value } }) => {
                                    handleOnChange(value, prefixedName);
                                }}
                                onChange={(date) => {
                                    if (_.isNull(date)) {
                                        handleOnChange(null, prefixedName);
                                    }
                                }}
                            />
                        </DatePickerWrapper>
                    );
                }
            }
        };
        return (
            <div className="eds-date-picker">
                <Row>{getDatePicker(type)}</Row>
            </div>
        );
    }
);
EdsDatePicker.displayName = 'EdsDatePicker';
