import { forwardRef, useState } from 'react';
import {
    EdsDropdown,
    EdsDropdownType,
    EdsFormGroup,
    EdsFormRepeat,
    EdsTableCriteriaRangeForm,
    EdsTableFilterAlarmCriteriaType,
} from '../../';

import { useTranslation } from 'react-i18next';
import { useEffectOnMount } from '../../../../features/react/hooks';
import { getAlarmCriteria } from '../../../../features';
import _ from 'lodash';

export const EdsFormGroupAlarmCriteria = forwardRef(
    ({ selectedFilter, ...props }, ref) => {
        const [formDefinition, setFormDefinition] = useState([]);
        const [prefix] = useState(props.prefix ?? null);
        const { t } = useTranslation();

        const [selectedCriteriaTypes, setSelectedCriteriaTypes] = useState([]);
        const [specificCriteria, setSpecificCriteria] = useState([]);
        const [selectedSpecificCriteria, setSelectedSpecificCriteria] =
            useState([]);

        useEffectOnMount(async () => {
            const alarmCriteria = await getAlarmCriteria();
            setSpecificCriteria(alarmCriteria);

            setFormDefinition(await getFormDefinition());

            if (!_.isNil(props?.selectedValues?.selectedItems)) {
                setSelectedCriteriaTypes(
                    props.selectedValues.selectedItems.map((item) => item.id)
                );

                if (!_.isNil(props?.selectedValues?.value?.specific)) {
                    let criteria = [];
                    for (let index in props.selectedValues.value.specific) {
                        let id = props.selectedValues.value.specific[index];

                        let data = alarmCriteria.find(
                            (data) => data.number === id
                        );
                        if (!_.isNil(data?.name)) {
                            criteria.push({
                                id: data.id,
                                number: data.number,
                                text: `${data.name} (${data.number})`,
                            });
                        }
                    }
                    setSelectedSpecificCriteria(criteria);
                }
            }
        });

        const getFormDefinition = async () => {
            return {
                resetOnDefinitionChange: true,
                value: {
                    value: props?.selectedValues?.selectedItems,
                    validation: {
                        type: 'multiselect',
                        required: true,
                    },
                },
            };
        };

        return (
            <>
                <EdsFormGroup prefix={prefix} formDefinition={formDefinition}>
                    <EdsDropdown
                        type={EdsDropdownType.MultiSelect}
                        name={'value'}
                        label={t('2063c1608d6e0baf80249c42e2be5804', 'Value')}
                        getDataCallback={selectedFilter.getDataCallback}
                        compareItems={() => {
                            // Keep ordering of list
                            return 0;
                        }}
                        onChangeCallback={(event) => {
                            // Remove custom range form definitions when deselected
                            if (
                                selectedCriteriaTypes.includes(
                                    EdsTableFilterAlarmCriteriaType.CustomRanges
                                ) &&
                                !event.selectedItems.find(
                                    (item) =>
                                        item.id ===
                                        EdsTableFilterAlarmCriteriaType.CustomRanges
                                )
                            ) {
                                ref.current?.removeRepeatAll();
                            }

                            setSelectedCriteriaTypes(
                                event.selectedItems.map((item) => item.id)
                            );
                        }}
                    />
                </EdsFormGroup>
                {selectedCriteriaTypes.includes(
                    EdsTableFilterAlarmCriteriaType.CustomRanges
                ) && (
                    <EdsFormGroup>
                        <EdsFormRepeat
                            ref={ref}
                            prefix={'custom'}
                            selectedValues={
                                props?.selectedValues?.value?.custom
                            }
                            addButtonLabel={t(
                                'f013897f4babe46a8fe371a8d6dd5ca8',
                                'Add custom range'
                            )}
                            min={1}
                            max={20}
                        >
                            <EdsTableCriteriaRangeForm repeatRef={ref} />
                        </EdsFormRepeat>
                    </EdsFormGroup>
                )}
                {selectedCriteriaTypes.includes(
                    EdsTableFilterAlarmCriteriaType.SpecificCriteria
                ) && (
                    <EdsFormGroup
                        formDefinition={{
                            numbers: {
                                value: selectedSpecificCriteria,
                                validation: {
                                    type: 'multiselect',
                                    required: false,
                                },
                                mapping: 'number',
                            },
                        }}
                        prefix={'specific'}
                    >
                        <EdsDropdown
                            type={EdsDropdownType.FilterableMultiSelect}
                            name={'numbers'}
                            label={t(
                                '74fb39e1813c7bd59d050bdec07a67e3',
                                'Specific criteria'
                            )}
                            getDataCallback={() => {
                                return specificCriteria;
                            }}
                            mappingCallback={(data) => {
                                return {
                                    id: data.id,
                                    number: data.number,
                                    text: `${data.name} (${data.number})`,
                                };
                            }}
                        />
                    </EdsFormGroup>
                )}
            </>
        );
    }
);
EdsFormGroupAlarmCriteria.displayName = 'EdsFormGroupAlarmCriteria';
