import { NumberInput, TextInput } from '@carbon/react';
import { useTranslation } from 'react-i18next';
import './eds-number-input.scss';
import { useState } from 'react';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';

export function EdsNumberInput(props) {
    const { prefix, usePrefix } = useFormGroup();
    const {
        getFormValue,
        handleFormChange,
        handleOnBlur,
        isFieldRequired,
        forceRequired,
        formatLabel,
        isInvalid,
        getInvalidText,
    } = useForm();
    const { i18n } = useTranslation();

    const [name] = useState(props.name ?? '');
    const [placeholder] = useState(props.placeholder ?? null);
    const [min] = useState(props.min ?? 0);
    const [readOnly] = useState(props.readOnly ?? false);
    const [cost] = useState(props.cost ?? false);
    const [locale] = useState(
        i18n.language !== 'en-devel' ? i18n.language : 'en-US'
    );
    const [decimalSeparator] = useState(
        Intl.NumberFormat(locale)
            .format(1.1)
            .replace(/\p{Number}/gu, '')
    );

    let prefixedName = usePrefix(name) ?? name;
    const [labelText] = useState(props.label);

    const getValue = () => {
        const value = getFormValue(name, prefix);

        if (!_.isUndefined(value) && !_.isNull(value)) {
            if (!_.isPlainObject(value)) {
                if (cost && !_.isEmpty(value)) {
                    return new String(value).replace(/\./g, decimalSeparator);
                }
                return value;
            }

            if ('value' in value) {
                return value[value];
            }
        }
        return isFieldRequired(name, prefix) ? min : '';
    };

    const onNumberChange = (event) => {
        if (cost && !_.isEmpty(event?.target?.value)) {
            let value = new String(event.target.value);
            value = value.replace(/[^\d.,]/g, '');
            value = value.replace(/,/g, '.');
            event.target.value = value;
        }
        handleFormChange(event);
    };

    const numberInputProps = {
        className: 'eds-number-input',
        id: prefixedName,
        name: prefixedName,
        placeholder: placeholder,
        value: getValue(),
        readOnly: readOnly,
        onChange: onNumberChange,
        onBlur: handleOnBlur,
        invalid: isInvalid(prefixedName),
        invalidText: getInvalidText(prefixedName),
    };

    if (isFieldRequired(name, prefix) && !cost) {
        return (
            <NumberInput
                {...numberInputProps}
                label={formatLabel(labelText, name, prefix, forceRequired)}
                hideSteppers={true} //value komt niet goed mee het het stepper event.
                min={min}
            />
        );
    } else {
        return (
            <TextInput
                {...numberInputProps}
                labelText={formatLabel(labelText, name, prefix, forceRequired)}
            ></TextInput>
        );
    }
}
