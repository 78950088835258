import './eds-table-criterium.scss';

export const EdsAlarmType = Object.freeze({
    Normal: 'normal',
    FireSmoke: 'fire-smoke',
    Silent: 'silent',
    Technical: 'technical',
});

export function EdsTableCriterium({
    label,
    priority,
    alarmType = EdsAlarmType.Normal,
}) {
    return (
        <div className="eds-table-criterium" data-priority={priority}>
            <div className={`eds-table-criterium-icon ${alarmType}`} />
            <div>{label}</div>
        </div>
    );
}
