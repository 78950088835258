import { TextArea } from '@carbon/react';
import { forwardRef, useImperativeHandle } from 'react';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';

export const EdsTextArea = forwardRef((props, ref) => {
    const {
        name,
        label,
        forceRequired,
        placeholder,
        enableCounter,
        counterMode,
        maxCount,
        rows,
    } = props;
    const {
        getFormValue,
        handleFormChange,
        handleOnBlur,
        formatLabel,
        isInvalid,
        getInvalidText,
    } = useForm();
    const { prefix, usePrefix } = useFormGroup();

    let prefixedName = usePrefix(name) ?? name;
    const { updateFormValues } = useForm();

    const updateText = (text) => {
        let formChanges = {};
        formChanges[prefixedName] = text;
        updateFormValues(formChanges);
    };

    useImperativeHandle(ref, () => ({
        updateText,
    }));

    const getValue = () => {
        const value = getFormValue(name, prefix);

        if (!_.isUndefined(value) && !_.isNull(value)) {
            if (!_.isPlainObject(value)) {
                return value;
            }

            if ('value' in value) {
                return value[value];
            }
        }
        return '';
    };

    return (
        <TextArea
            className="eds-text-area"
            id={prefixedName}
            name={prefixedName}
            labelText={
                _.isNil(label)
                    ? ''
                    : formatLabel(label, name, prefix, forceRequired)
            }
            placeholder={placeholder}
            value={getValue()}
            rows={rows}
            enableCounter={enableCounter}
            counterMode={counterMode}
            maxCount={maxCount}
            onChange={handleFormChange}
            onBlur={handleOnBlur}
            invalid={isInvalid(prefixedName)}
            invalidText={getInvalidText(prefixedName)}
        ></TextArea>
    );
});
EdsTextArea.displayName = 'EdsTextArea';
