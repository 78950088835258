import _ from 'lodash';
import {
    createSearchFilterStoreFunctions,
    createStateManagementFunctions,
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from '../api';

const basePath = '/residences';

export const getResidenceSearch = async (search) => {
    return getRequest(`${basePath}/search`, search);
};

export const getResidence = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const getResidenceByUID = async (uid) => {
    return getRequest(`${basePath}/uid/${uid}`);
};

export const putResidence = async (residence) => {
    return putRequest(`${basePath}/${residence.id}`, residence);
};

export const patchResidence = async (id, residence) => {
    return patchRequest(`${basePath}/${id}`, residence);
};

export const getClientsByResidence = async (residenceId) => {
    return getRequest(`${basePath}/${residenceId}/clients`);
};

export const saveResidence = async (residence) => {
    return postRequest(`${basePath}`, residence);
};

export const saveResidenceRange = async (residenceRange) => {
    return postRequest(`${basePath}/range`, residenceRange);
};

export const saveResidenceDeviceRange = async (residenceDeviceRange) => {
    return postRequest(`${basePath}/device/range`, residenceDeviceRange);
};

export const getResidencePhoneNumbers = async (id) => {
    return getRequest(`${basePath}/${id}/phone-numbers`);
};

export const postResidencePhoneNumber = async (id, number) => {
    return postRequest(`${basePath}/${id}/phone-numbers`, number);
};

export const getResidenceClients = async (residenceId) => {
    return getRequest(`${basePath}/${residenceId}/clients`);
};

export const getResidenceDevices = async (residenceId) => {
    return getRequest(`${basePath}/${residenceId}/devices`);
};

export const postResidenceDefaultDevice = async (residenceId, deviceId) => {
    return postRequest(`${basePath}/${residenceId}/default-device/${deviceId}`);
};

export const getResidenceCaregivers = async (residenceId) => {
    return getRequest(`${basePath}/${residenceId}/caregivers`);
};

export const getResidenceNotes = async (residenceId, params) => {
    return getRequest(`${basePath}/${residenceId}/notes`, params);
};

export const postResidenceNote = async (residenceId, residenceNote) => {
    return postRequest(`${basePath}/${residenceId}/notes`, residenceNote);
};

export const getResidenceTags = async (residenceId, params) => {
    return getRequest(`${basePath}/${residenceId}/tags`, params);
};

export const postResidenceTag = async (residenceId, residenceTag) => {
    return postRequest(`${basePath}/${residenceId}/tags`, residenceTag);
};

export const postLinkResidenceToDevice = (residenceId, deviceId, params) => {
    return postRequest(
        `${basePath}/${residenceId}/devices/${deviceId}/link`,
        null,
        params
    );
};

export const deleteUnlinkResidenceToDevice = (
    residenceId,
    deviceId,
    params
) => {
    return deleteRequest(
        `${basePath}/${residenceId}/devices/${deviceId}/unlink`,
        params
    );
};

export const stateManagement = {
    ...createStateManagementFunctions(basePath, 'residences'),
};

export const searchFilterStore = {
    ...createSearchFilterStoreFunctions(basePath, 'residences'),
};

export const addressMappingCallback = (address, residenceType) => {
    if (_.isNil(address)) {
        return null;
    }

    return {
        addressName: address.addressName,
        streetName: address.streetName,
        houseNumber: address.houseNumber,
        postalCode: address.postalCode,
        addressField2: address.addressField2,
        apartmentNumber: address.apartmentNumber,
        countryId: {
            id: address?.country?.id,
            text: address?.country?.name,
            isUKAddressFormat: address?.country?.isUKAddressFormat,
            postalCodeFormat: address?.country?.postalCodeFormat,
        },
        regionId: {
            id: address?.region?.id,
            text: address?.region?.name,
        },
        cityId: {
            id: address?.city?.id,
            text: address?.city?.name,
            regionId: address?.city?.regionId,
        },
        districtId: {
            id: address?.district?.id,
            text: address?.district?.name,
        },
        ...(residenceType && {
            residenceTypeId: {
                id: residenceType?.id,
                text: residenceType?.name,
            },
        }),
    };
};

export const schemeMappingCallback = (scheme) => {
    if (_.isNil(scheme)) {
        return {};
    }

    return {
        id: scheme?.id,
        text: scheme?.name,
        isDefaultUseSchemeAddress: scheme?.isDefaultUseSchemeAddress,
        isEnableUseSchemeAddress: scheme?.isEnableUseSchemeAddress,
        schemeIdentifier: scheme?.schemeIdentifier,
        roomUnitDigits: scheme?.roomUnitDigits,
        address: addressMappingCallback(scheme?.address),
    };
};

export const getResidenceLinkToProfessionalCaregiver = async (
    residenceId,
    professionalCaregiverId
) => {
    return getRequest(
        `${basePath}/${residenceId}/professional-caregivers/${professionalCaregiverId}/link`
    );
};

export const postLinkResidenceToProfessionalCaregiver = async (
    residenceId,
    professionalCaregiverId,
    data
) => {
    return postRequest(
        `${basePath}/${residenceId}/professional-caregivers/${professionalCaregiverId}/link`,
        data
    );
};

export const putResidenceLinkToProfessionalCaregiver = async (
    residenceId,
    professionalCaregiverId,
    data
) => {
    return putRequest(
        `${basePath}/${residenceId}/professional-caregivers/${professionalCaregiverId}/link`,
        data
    );
};

export const getResidenceLinkToCareteam = async (residenceId, careTeamId) => {
    return getRequest(
        `${basePath}/${residenceId}/care-teams/${careTeamId}/link`
    );
};

export const postLinkResidenceToCareTeam = async (
    residenceId,
    careTeamId,
    data
) => {
    return postRequest(
        `${basePath}/${residenceId}/care-teams/${careTeamId}/link`,
        data
    );
};

export const putResidenceLinkToCareTeam = async (
    residenceId,
    careTeamId,
    data
) => {
    return putRequest(
        `${basePath}/${residenceId}/care-teams/${careTeamId}/link`,
        data
    );
};

export const deleteUnlinkResidenceToProfessionalCaregiver = async (
    residenceId,
    professionalCaregiverId
) => {
    return deleteRequest(
        `${basePath}/${residenceId}/professional-caregivers/${professionalCaregiverId}/unlink`
    );
};

export const deleteUnlinkResidenceToCareTeam = async (
    residenceId,
    careTeamId
) => {
    return deleteRequest(
        `${basePath}/${residenceId}/care-teams/${careTeamId}/unlink`
    );
};
