import { useTranslation } from 'react-i18next';
import {
    EdsContainer,
    EdsContainerGrid,
    EdsDataGrid,
    EdsTableDatetime,
} from '../../../eds';

export const UmoAuditLogContainer = ({ entity }) => {
    const { t } = useTranslation();

    return (
        <EdsContainerGrid>
            <EdsContainer
                title={t('7d71019726dccc04ceb52b94f892b7f6', 'Audit log')}
            >
                <UmoAuditLogGrid entity={entity} />
            </EdsContainer>
        </EdsContainerGrid>
    );
};

export const UmoAuditLogGrid = ({ entity }) => {
    const { t } = useTranslation();

    return (
        <EdsDataGrid
            gridData={{
                styles: {
                    left: {
                        key: {
                            width: '200px',
                        },
                        value: {
                            width: '250px',
                        },
                    },
                },
                rows: {
                    left: [
                        {
                            key: t(
                                'e2fa538867c3830a859a5b17ab24644b',
                                'Created'
                            ),
                            value: (
                                <EdsTableDatetime value={entity.insertDate} />
                            ),
                            details: entity.insertBy,
                        },

                        {
                            key: t(
                                '0f81d52e06caaa4860887488d18271c7',
                                'Updated'
                            ),
                            value: (
                                <EdsTableDatetime value={entity.updateDate} />
                            ),
                            details: entity.updateBy,
                        },
                    ],
                },
            }}
        />
    );
};
