import {
    getRequest,
    multipartPostRequest,
    postRequest,
    putRequest,
    deleteRequest,
} from '../api';

export const getClientStatuses = async () => {
    return getRequest('/maintenance/client/statuses');
};

export const getClientStatus = async (id) => {
    return getRequest('/maintenance/client/statuses/' + id);
};

export const postClientStatus = async (clientStatus) => {
    return postRequest('/maintenance/client/statuses', clientStatus);
};

export const putClientStatus = async (clientStatus) => {
    return putRequest(
        '/maintenance/client/statuses/' + clientStatus.id,
        clientStatus
    );
};

export const deleteClientStatus = async (id) => {
    return deleteRequest('/maintenance/client/statuses/' + id);
};

export const getPrefixes = async () => {
    return getRequest('/maintenance/client/prefixes');
};

export const getPrefix = async (id) => {
    return getRequest('/maintenance/client/prefixes/' + id);
};

export const postPrefix = async (prefix) => {
    return postRequest('/maintenance/client/prefixes', prefix);
};

export const putPrefix = async (prefix) => {
    return putRequest('/maintenance/client/prefixes/' + prefix.id, prefix);
};

export const deletePrefix = async (id) => {
    return deleteRequest('/maintenance/client/prefixes/' + id);
};

export const getMaritalStates = async () => {
    return getRequest('/maintenance/client/marital-states');
};

export const getMaritalState = async (id) => {
    return getRequest('/maintenance/client/marital-states/' + id);
};

export const postMaritalState = async (maritalState) => {
    return postRequest('/maintenance/client/marital-states', maritalState);
};

export const putMaritalState = async (maritalState) => {
    return putRequest(
        '/maintenance/client/marital-states/' + maritalState.id,
        maritalState
    );
};

export const deleteMaritalState = async (id) => {
    return deleteRequest('/maintenance/client/marital-states/' + id);
};

export const getRelationTypes = async () => {
    return getRequest('/maintenance/client/relation-types');
};

export const getRelationType = async (id) => {
    return getRequest('/maintenance/client/relation-types/' + id);
};

export const postRelationType = async (relationType) => {
    return postRequest('/maintenance/client/relation-types', relationType);
};

export const putRelationType = async (relationType) => {
    return putRequest(
        '/maintenance/client/relation-types/' + relationType.id,
        relationType
    );
};

export const deleteRelationType = async (id) => {
    return deleteRequest('/maintenance/client/relation-types/' + id);
};

export const getLanguages = async () => {
    return getRequest('/maintenance/client/languages');
};

export const getLanguage = async (id) => {
    return getRequest('/maintenance/client/languages/' + id);
};

export const postLanguage = async (language) => {
    return postRequest('/maintenance/client/languages', language);
};

export const putLanguage = async (language) => {
    return putRequest('/maintenance/client/languages/' + language.id, language);
};

export const deleteLanguage = async (id) => {
    return deleteRequest('/maintenance/client/languages/' + id);
};

export const getInsurers = async () => {
    return getRequest('/maintenance/client/insurers');
};

export const getInsurer = async (id) => {
    return getRequest('/maintenance/client/insurers/' + id);
};

export const postInsurer = async (insurer) => {
    return postRequest('/maintenance/client/insurers', insurer);
};

export const putInsurer = async (insurer) => {
    return putRequest('/maintenance/client/insurers/' + insurer.id, insurer);
};

export const deleteInsurer = async (id) => {
    return deleteRequest('/maintenance/client/insurers/' + id);
};

export const getInsuranceClasses = async () => {
    return getRequest('/maintenance/client/insurance-classes');
};

export const getInsuranceClass = async (id) => {
    return getRequest('/maintenance/client/insurance-classes/' + id);
};

export const postInsuranceClass = async (insuranceClass) => {
    return postRequest('/maintenance/client/insurance-classes', insuranceClass);
};

export const putInsuranceClass = async (insuranceClass) => {
    return putRequest(
        '/maintenance/client/insurance-classes/' + insuranceClass.id,
        insuranceClass
    );
};

export const deleteInsuranceClass = async (id) => {
    return deleteRequest('/maintenance/client/insurance-classes/' + id);
};

export const getContractEndingReasons = async () => {
    return getRequest('/maintenance/client/contract-ending-reasons');
};

export const getContractEndingReason = async (id) => {
    return getRequest('/maintenance/client/contract-ending-reasons/' + id);
};

export const postContractEndingReason = async (contractEndingReason) => {
    return postRequest(
        '/maintenance/client/contract-ending-reasons',
        contractEndingReason
    );
};

export const putContractEndingReason = async (contractEndingReason) => {
    return putRequest(
        '/maintenance/client/contract-ending-reasons/' +
            contractEndingReason.id,
        contractEndingReason
    );
};

export const deleteContractEndingReason = async (id) => {
    return deleteRequest('/maintenance/client/contract-ending-reasons/' + id);
};

export const getAuthorizationStatuses = async () => {
    return getRequest('/maintenance/client/authorization-statuses');
};

export const getAuthorizationStatus = async (id) => {
    return getRequest('/maintenance/client/authorization-statuses/' + id);
};

export const postAuthorizationStatus = async (authorizationStatus) => {
    return postRequest(
        '/maintenance/client/authorization-statuses',
        authorizationStatus
    );
};

export const putAuthorizationStatus = async (authorizationStatus) => {
    return putRequest(
        '/maintenance/client/authorization-statuses/' + authorizationStatus.id,
        authorizationStatus
    );
};

export const deleteAuthorizationStatus = async (id) => {
    return deleteRequest('/maintenance/client/authorization-statuses/' + id);
};

export const getGroups = async () => {
    return getRequest('/maintenance/client/groups');
};

export const getGroup = async (id) => {
    return getRequest('/maintenance/client/groups/' + id);
};

export const postGroup = async (group) => {
    return postRequest('/maintenance/client/groups', group);
};

export const putGroup = async (group) => {
    return putRequest('/maintenance/client/groups/' + group.id, group);
};

export const deleteGroup = async (id) => {
    return deleteRequest('/maintenance/client/groups/' + id);
};

export const getSubgroups = async (groupId) => {
    return getRequest(`/maintenance/client/groups/${groupId}/subgroups`);
};

export const getSubgroup = async (id) => {
    return getRequest('/maintenance/client/subgroups/' + id);
};

export const postSubgroup = async (subgroup) => {
    return postRequest('/maintenance/client/subgroups', subgroup);
};

export const putSubgroup = async (subgroup) => {
    return putRequest('/maintenance/client/subgroups/' + subgroup.id, subgroup);
};

export const deleteSubgroup = async (id) => {
    return deleteRequest('/maintenance/client/subgroups/' + id);
};

export const getGroupSubscriptions = async (groupId, params) => {
    return getRequest(
        `/maintenance/client/groups/${groupId}/subscriptions`,
        params
    );
};

export const getGroupSubscription = async (groupId, subscriptionId) => {
    return getRequest(
        `/maintenance/client/groups/${groupId}/subscriptions/${subscriptionId}`
    );
};

export const postGroupSubscription = async (groupSubscription) => {
    return postRequest(
        `/maintenance/client/groups/${groupSubscription.parentId}/subscriptions/${groupSubscription.subscriptionId}`,
        groupSubscription
    );
};

export const putGroupSubscription = async (groupSubscription) => {
    return putRequest(
        `/maintenance/client/groups/${groupSubscription.parentId}/subscriptions/${groupSubscription.subscriptionId}`,
        groupSubscription
    );
};

export const deleteGroupSubscription = async (groupId, subscriptionId) => {
    return deleteRequest(
        `/maintenance/client/groups/${groupId}/subscriptions/${subscriptionId}`
    );
};

export const getGroupDocuments = async (groupId) => {
    return getRequest(`/maintenance/client/groups/${groupId}/documents`);
};

export const postGroupDocument = async (
    groupId,
    file,
    cbProgress,
    abortController
) => {
    let form = new FormData();
    form.append('file', file);
    let customHeaders = {};
    return multipartPostRequest(
        `/maintenance/client/groups/${groupId}/document`,
        form,
        customHeaders,
        cbProgress,
        abortController
    );
};

export const getGroupDocumentUrl = (groupId, groupDocumentId, accountId) => {
    return `/api/maintenance/client/groups/${groupId}/document/${groupDocumentId}?account_id=${accountId}`;
};

export const deleteGroupDocument = async (groupId, documentId) => {
    return deleteRequest(
        `/maintenance/client/groups/${groupId}/document/${documentId}`
    );
};

export const getProjects = async (subgroupId) => {
    return getRequest(`/maintenance/client/subgroups/${subgroupId}/projects`);
};

export const getProject = async (id) => {
    return getRequest('/maintenance/client/projects/' + id);
};

export const postProject = async (project) => {
    return postRequest('/maintenance/client/projects', project);
};

export const putProject = async (project) => {
    return putRequest('/maintenance/client/projects/' + project.id, project);
};

export const deleteProject = async (id) => {
    return deleteRequest('/maintenance/client/projects/' + id);
};

export const getSubprojects = async (projectId) => {
    return getRequest(`/maintenance/client/projects/${projectId}/subprojects`);
};

export const getSubproject = async (subprojectId) => {
    return getRequest(`/maintenance/client/subprojects/${subprojectId}`);
};

export const postSubproject = async (subproject) => {
    return postRequest('/maintenance/client/subprojects', subproject);
};

export const putSubproject = async (subproject) => {
    return putRequest(
        '/maintenance/client/subprojects/' + subproject.id,
        subproject
    );
};

export const deleteSubproject = async (id) => {
    return deleteRequest('/maintenance/client/subprojects/' + id);
};
