import { getRequest, postRequest, putRequest, deleteRequest } from '../api';

export const getCountries = async () => {
    return getRequest('/maintenance/residence/countries');
};

export const getDefaultCountry = async () => {
    return getRequest('/maintenance/residence/countries/default');
};

export const getCountry = async (id) => {
    return getRequest('/maintenance/residence/countries/' + id);
};

export const postCountry = async (country) => {
    return postRequest('/maintenance/residence/countries', country);
};

export const putCountry = async (country) => {
    return putRequest(
        '/maintenance/residence/countries/' + country.id,
        country
    );
};

export const deleteCountry = async (id) => {
    return deleteRequest('/maintenance/residence/countries/' + id);
};

export const getRegions = async (countryId) => {
    return getRequest(`/maintenance/residence/countries/${countryId}/regions`);
};

export const getRegion = async (regionId) => {
    return getRequest('/maintenance/residence/regions/' + regionId);
};

export const postRegion = async (region) => {
    return postRequest('/maintenance/residence/regions', region);
};

export const putRegion = async (region) => {
    return putRequest('/maintenance/residence/regions/' + region.id, region);
};

export const deleteRegion = async (id) => {
    return deleteRequest('/maintenance/residence/regions/' + id);
};

export const getCitiesByCountry = async (countryId) => {
    return getRequest('/maintenance/residence/cities_by_country/' + countryId);
};

export const getCitiesByRegion = async (regionId) => {
    return getRequest('/maintenance/residence/cities_by_region/' + regionId);
};

export const getCity = async (cityId) => {
    return getRequest('/maintenance/residence/cities/' + cityId);
};

export const postCity = async (city) => {
    return postRequest('/maintenance/residence/cities', city);
};

export const putCity = async (city) => {
    return putRequest('/maintenance/residence/cities/' + city.id, city);
};

export const deleteCity = async (cityId) => {
    return deleteRequest('/maintenance/residence/cities/' + cityId);
};

export const getDistrictsByCity = async (countryId) => {
    return getRequest('/maintenance/residence/districts_by_city/' + countryId);
};

export const getDistrict = async (districtId) => {
    return getRequest('/maintenance/residence/districts/' + districtId);
};

export const postDistrict = async (district) => {
    return postRequest('/maintenance/residence/districts', district);
};

export const putDistrict = async (district) => {
    return putRequest(
        '/maintenance/residence/districts/' + district.id,
        district
    );
};

export const deleteDistrict = async (districtId) => {
    return deleteRequest('/maintenance/residence/districts/' + districtId);
};

export const getResidenceTypes = async () => {
    return getRequest('/maintenance/residence/types');
};

export const getResidenceType = async (id) => {
    return getRequest('/maintenance/residence/types/' + id);
};

export const postResidenceType = async (type) => {
    return postRequest('/maintenance/residence/types', type);
};

export const putResidenceType = async (type) => {
    return putRequest('/maintenance/residence/types/' + type.id, type);
};

export const deleteResidenceType = async (id) => {
    return deleteRequest('/maintenance/residence/types/' + id);
};
export const getPointsOfEntry = async () => {
    return getRequest('/maintenance/residence/points-of-entry');
};

export const getPointOfEntry = async (id) => {
    return getRequest('/maintenance/residence/points-of-entry/' + id);
};

export const postPointOfEntry = async (pointOfEntry) => {
    return postRequest('/maintenance/residence/points-of-entry', pointOfEntry);
};

export const putPointOfEntry = async (pointOfEntry) => {
    return putRequest(
        '/maintenance/residence/points-of-entry/' + pointOfEntry.id,
        pointOfEntry
    );
};

export const deletePointOfEntry = async (id) => {
    return deleteRequest('/maintenance/residence/points-of-entry/' + id);
};

export const getKeyLocations = async () => {
    return getRequest('/maintenance/residence/key-locations');
};

export const getKeyLocation = async (id) => {
    return getRequest('/maintenance/residence/key-locations/' + id);
};

export const postKeyLocation = async (keyLocation) => {
    return postRequest('/maintenance/residence/key-locations', keyLocation);
};

export const putKeyLocation = async (keyLocation) => {
    return putRequest(
        '/maintenance/residence/key-locations/' + keyLocation.id,
        keyLocation
    );
};

export const deleteKeyLocation = async (id) => {
    return deleteRequest('/maintenance/residence/key-locations/' + id);
};
