import { useWizard } from '../wizard-context';
import { Children, cloneElement, useEffect } from 'react';
import { EdsContainer } from '../../eds-container/eds-container';
import _ from 'lodash';

export function EdsWizardStep({
    id,
    label,
    secondaryLabel,
    withPadding,
    children,
    summary = false,
    transparent = summary ? false : true,
}) {
    const { isSelected } = useWizard();
    const { addStep } = useWizard();

    useEffect(() => {
        addStep({
            id: id,
            label: label ?? `No label: ${id}`,
            secondaryLabel: secondaryLabel ?? '',
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <EdsContainer
            withPadding={withPadding ?? true}
            show={isSelected(id)}
            step={true}
            transparent={transparent}
            title={!transparent ? secondaryLabel : ''}
            summary={summary}
        >
            {Children.map(children, (child) => {
                return _.isNull(child)
                    ? null
                    : cloneElement(child, {
                          wizardStepId: id,
                      });
            })}
        </EdsContainer>
    );
}
