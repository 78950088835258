import {
    AccountIdApiSetter,
    AuthProvider,
    ChannelsProvider,
    ModalProvider,
    NotificationsProvider,
    PermissionsProvider,
} from './features';

export const ProviderTree = ({ children }) => {
    return (
        <AuthProvider>
            <AccountIdApiSetter>
                <PermissionsProvider>
                    <NotificationsProvider>
                        <ModalProvider>
                            <ChannelsProvider>{children}</ChannelsProvider>
                        </ModalProvider>
                    </NotificationsProvider>
                </PermissionsProvider>
            </AccountIdApiSetter>
        </AuthProvider>
    );
};
