import _ from 'lodash';
import {
    AdvancedMarker,
    APIProvider,
    InfoWindow,
    Map,
    Pin,
    useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import { EdsTableDatetime } from '../eds-table/eds-table-datetime/eds-table-datetime';
import { useTranslation } from 'react-i18next';
import './eds-maps.scss';
import { getLogger } from '../../../features';

const logger = getLogger('EdsMaps');

export function EdsMaps({ markers = [], ...props }) {
    const { t } = useTranslation();
    const defaultCenter = markers[0]?.location;

    if (_.isNil(props.apiKey)) {
        return (
            <div className="eds-maps error">
                {t(
                    'cd3b0ca500f0da66a243ab001586e7ef',
                    'Please fill in a google Maps API key in the maintenance settings'
                )}
            </div>
        );
    }

    if (_.isEmpty(markers)) {
        return (
            <div className="eds-maps error">
                {t(
                    '05fa299e443e61ebbf0a933f19649476',
                    'No location available to show on a map.'
                )}
            </div>
        );
    }

    return (
        <div className="eds-maps">
            <APIProvider
                apiKey={props.apiKey}
                onLoad={() => logger.log('Maps API has loaded.')}
            >
                <Map
                    mapId={uuid()}
                    defaultZoom={16}
                    defaultCenter={defaultCenter}
                >
                    {markers.map((marker) => (
                        <DeviceMarker
                            key={marker.key}
                            details={marker.details}
                            marker={marker}
                            openInfoPanel={markers.length === 1} //only open infopanel if there is one marker on screen
                        >
                            <Pin
                                background={'#38BAF0'}
                                glyphColor={'#1A5084'}
                                borderColor={'#1A5084'}
                            />
                        </DeviceMarker>
                    ))}
                </Map>
            </APIProvider>
        </div>
    );
}

function DeviceMarker({ marker, details, openInfoPanel = true, ...props }) {
    const { t } = useTranslation();
    const [markerRef, anchorMarker] = useAdvancedMarkerRef();
    const [showInfoWindow, setShowInfoWindow] = useState(false);

    useState(function () {
        setTimeout(function () {
            setShowInfoWindow(openInfoPanel);
        }, 1250);
    }, []);

    const infowWindowDetails = [
        {
            key: t('57d50ee4873d42049cc021a5df38b750', 'Device code'),
            value: details.deviceCode,
        },
        {
            key: t('07cc694b9b3fc636710fa08b6922c42b', 'Time'),
            value: <EdsTableDatetime value={details.timeStamp} />,
        },
        {
            key: t('ba569b80f7bb7762f073f1be57cc36aa', 'Longitude'),
            value: details.longitude,
        },
        {
            key: t('28c1e37e317b935a387dbe232bc9f803', 'Latitude'),
            value: details.latitude,
        },
        {
            key: t('ce4ef3ec98443ed10da505114b58f153', 'Speed'),
            value: `${details.speedInKilometersPerHour} ${t(
                '1ea675ddb12f67671f20cb24c9ef4ddd',
                'km/h'
            )}`,
        },
        {
            key: t('1bd38806382db1a26e0bb1716f52a182', 'Number of satellites'),
            value: details.numberOfSatellites,
        },
        {
            key: t('80df2a0e4b1c0867ab655a3e851bdfee', 'Battery percentage'),
            value: details.batteryLevelPercentage,
        },
        {
            key: t('c6899490c74b35c376fcbcd5d53a5984', 'In charger'),
            value: details.isInCharger,
        },
    ];

    return (
        <AdvancedMarker
            className="drop"
            ref={markerRef}
            position={marker.location}
            clickable={true}
            title={details.deviceCode}
            onClick={() => {
                setShowInfoWindow(true);
            }}
        >
            {showInfoWindow ? (
                <InfoWindow
                    className="info-window"
                    visible={true}
                    anchor={anchorMarker}
                    maxWidth={500}
                    onCloseClick={() => {
                        setShowInfoWindow(false);
                    }}
                    headerContent={
                        <div className="info-window-header">
                            {t(
                                '49fd8335a887158b77c640f026d87484',
                                'Device information'
                            )}
                        </div>
                    }
                >
                    <table className="info-window-table">
                        <tbody>
                            {infowWindowDetails.map((detail, index) => (
                                <tr key={index}>
                                    <td>{detail.key}</td>
                                    <td>{detail.value ?? '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </InfoWindow>
            ) : null}

            {props.children}
        </AdvancedMarker>
    );
}
