import { useRef, useState } from 'react';
import {
    EdsContainerTable,
    EdsTableCheckmark,
    EdsTableDate,
    EdsTableFilterType,
} from '../../..';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    deleteClientSubscription,
    getClientSubscription,
    getClientSubscriptions,
    getLogger,
    postClientGroupSubscriptionsApply,
    useModal,
    useNotifications,
} from '../../../../features';
import UmoSubscriptionForm from './umo-subscription-form.jsx';
import { Add } from '@carbon/icons-react';
import { DateTime } from 'luxon';

let logger = getLogger('UmoLinkedSubscriptionsTable');

export const UmoLinkedSubscriptionsTable = ({
    clientId,
    groupId,
    ...props
}) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const { showSuccess, showError } = useNotifications();
    const { showDeleteModal, showFormModal } = useModal();
    const [title] = useState(
        props.title ?? t('2d5d14f95af035cbd8437948de61f94c', 'Subscriptions')
    );

    const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
    const [hasData, setHasData] = useState();

    const todayDate = DateTime.fromJSDate(new Date()).toFormat('MM-dd-yyyy');

    const getData = async (params = {}) => {
        const data = await getClientSubscriptions(clientId, {
            isValid: params.showInvalid ? null : true,
        });
        setHasData(!_.isEmpty(data));
        return data;
    };

    if (
        hideWhenNoData &&
        !_.isUndefined(hasData) &&
        _.isBoolean(hasData) &&
        !hasData
    ) {
        return null;
    }

    const mappingCallback = (responseData) => {
        return {
            id: responseData.id,
            subscription: responseData?.subscription?.name,
            description: responseData?.subscription?.description,
            startDate: <EdsTableDate value={responseData.startDate} />,
            endDate: <EdsTableDate value={responseData.endDate} />,
            remark: responseData?.remark,
            isOneTimeCost: (
                <EdsTableCheckmark
                    value={responseData?.subscription?.isOneTimeCost}
                />
            ),
            cost: responseData?.cost,
        };
    };

    const showDataModal = (clientSubscription = {}) => {
        showFormModal({
            newTitle: t('c32ef9a27e41e16834e6cdc11c37f38a', 'New subscription'),
            editTitle: t(
                '4f288caef72a849b333906ca7425dd2e',
                'Edit subscription'
            ),
            formData: clientSubscription,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.doSearch();
                }
                removeModal();
            },
            children: (
                <UmoSubscriptionForm
                    ref={formRef}
                    initValues={clientSubscription}
                    hideDefaultSubmit={true}
                    clientId={clientId}
                    groupId={groupId}
                />
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let clientSubscription = await getClientSubscription(
                clientId,
                row.id
            );
            showDataModal({
                id: {
                    value: clientSubscription.id,
                },

                subscriptionId: {
                    id: clientSubscription.subscription.id,
                    text: clientSubscription.subscription.name,
                    description: clientSubscription.subscription.description,
                    remark: clientSubscription.remark,
                    isOneTimeCost:
                        clientSubscription.subscription.isOneTimeCost,
                },
                startDate: {
                    value: clientSubscription.startDate,
                },
                endDate: {
                    value: clientSubscription.endDate,
                },
                remark: {
                    value: clientSubscription.remark,
                },
                cost: {
                    value: clientSubscription.cost,
                },
            });
        } catch (ex) {
            logger.warn('getExternalIdentifier UDS Exception:', ex);
        }
    };

    return (
        <EdsContainerTable
            hidden={_.isUndefined(hasData)}
            title={title}
            ref={tableRef}
            headers={[
                {
                    key: 'subscription',
                    header: t(
                        'b48b13e73a6ac2a86dc54425dd24d9ff',
                        'Subscription'
                    ),
                },
                {
                    key: 'description',
                    header: t(
                        '67daf92c833c41c95db874e18fcb2786',
                        'Description'
                    ),
                },
                {
                    key: 'startDate',
                    header: t('46188e80453520b55cd82a67950f660e', 'Start date'),
                },
                {
                    key: 'endDate',
                    header: t('f49c17d504a2e8f8e29454a6d6ce9ecd', 'End date'),
                },
                {
                    key: 'remark',
                    header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
                },
                {
                    key: 'isOneTimeCost',
                    header: t(
                        'ac8e0cbd39060b0c224fc2394da64275',
                        'One-time cost'
                    ),
                },
                {
                    key: 'cost',
                    header: t('4e1566f0798fb3d6f350720cacd74446', 'Cost'),
                },
            ]}
            getDataCallback={getData}
            mappingCallback={mappingCallback}
            onEditClick={(row) => {
                getDataRow(row);
            }}
            onDeleteClick={(row) => {
                const subscription = tableRef.current?.getColumnValueString(
                    row.id,
                    'subscription'
                );
                showDeleteModal({
                    name: subscription,
                    tableRef,
                    row,
                    deleteHandler: async () => {
                        await deleteClientSubscription(clientId, row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            enableSearchParams={false}
            searchClientSide={false}
            enableSearch={false}
            fetchServerSideDataOnInit={true}
            availableFilters={[
                {
                    id: 'showInvalid',
                    name: t(
                        'f4b50fb47dcc58beef451ad3caead371',
                        'Also show invalid items'
                    ),
                    type: EdsTableFilterType.YesNo,
                    showOperator: false,
                    customQueryParamsFormatter: (params, values) => {
                        if (!_.isUndefined(values) && _.isBoolean(values)) {
                            return { ...params, showInvalid: values };
                        }
                        return params;
                    },
                },
            ]}
            customContainerButtons={[
                {
                    label: t(
                        '0c62c7f9b1a5d864aaf92826ed0d8a88',
                        'Add mandatory subscriptions'
                    ),
                    showLabel: true,
                    icon: Add,
                    onClick: async () => {
                        try {
                            await postClientGroupSubscriptionsApply({
                                clientId: clientId,
                                mandatoryOnly: true,
                                activeOnly: true,
                                startDate: todayDate,
                            });

                            showSuccess({
                                title: t(
                                    'b60ed88355ac3f6898fd8a7ab1734d06',
                                    'Added'
                                ),
                                content: t(
                                    '452fe720292457d9d514ae66674c19b0',
                                    'The mandatory subscriptions are added'
                                ),
                            });

                            tableRef.current?.doSearch();
                        } catch (error) {
                            let errorMessage = t(
                                'df0a9d46baf7315909e4389a04786e3d',
                                'Oops something went wrong'
                            );

                            if (error?.code === 'ERR_BAD_REQUEST') {
                                const detail = error?.response?.data?.detail;
                                if (
                                    detail ===
                                    'Some of the subscriptions from the group were already added to this client'
                                ) {
                                    errorMessage = t(
                                        '3c668cea82247b9ae40f297ab4aa5e96',
                                        'Some of the subscriptions from the group were already added to this client'
                                    );
                                } else if (
                                    detail ===
                                    'No matching subscription was found on the group!'
                                ) {
                                    errorMessage = t(
                                        '72b5dd4d360faad80c5220ba597a70b3',
                                        'The group of this client has no mandatory subscriptions'
                                    );
                                }
                            }

                            showError({
                                title: t(
                                    'cb5e100e5a9a3e7f6d1fd97512215282',
                                    'Error'
                                ),
                                content: errorMessage,
                            });
                        }
                    },
                },
            ]}
            {...props}
        />
    );
};
