import _ from 'lodash';
import { getLogger } from '../../../../features';

const logger = getLogger('EdsTableFilterReducer');

export const EdsTableFilterReducerActions = {
    AddFilter: 'ADD_FILTER',
    AddFilters: 'ADD_FILTERS',
    EditFilter: 'EDIT_FILTER',
    RemoveFilter: 'REMOVE_FILTER',
    ClearSavedFilter: 'CLEAR_SAVED_FILTER',
    SetSavedFilter: 'SET_SAVED_FILTER',
    ApplySavedFilter: 'APPLY_SAVED_FILTER',
    SetSearchTerm: 'SET_SEARCH_TERM',
    SetSorting: 'SET_SORTING',
};

export const EdsTableFilterReducer = (
    searchFilterStore = { save: () => null, clear: () => null }
) => {
    const saveFilterSet = (id, filters, search_term, sorting) => {
        if (_.isEmpty(filters) && _.isEmpty(search_term)) {
            searchFilterStore.clear(id);
            return;
        }

        let saveFilters = _.isArray(filters)
            ? filters.map((filter) => {
                  return {
                      column: filter.column,
                      type: filter.type,
                      operator: filter.operator,
                      value: filter.value,
                  };
              })
            : [];

        searchFilterStore.save({
            id,
            filter_set: { filters: saveFilters, search_term, sorting },
        });
    };

    return (state, action) => {
        switch (action.type) {
            case EdsTableFilterReducerActions.AddFilter: {
                let filters = [...state.filters, action.payload];
                logger.log('Filters to save (on add filter)', filters);
                saveFilterSet(
                    state.id,
                    filters,
                    state.searchTerm,
                    state.sorting
                );

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.AddFilters: {
                let filters = [...state.filters, ...action.payload];
                logger.log('Filters to save (on add filters)', filters);
                saveFilterSet(
                    state.id,
                    filters,
                    state.searchTerm,
                    state.sorting
                );

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.EditFilter: {
                logger.log('Filters edit action', action);

                let filters = [
                    ...state.filters.filter(
                        (filter) => filter.id !== action.payload.id
                    ),
                    { ...action.payload },
                ];

                logger.log('Filters edit filters', filters);

                saveFilterSet(
                    state.id,
                    filters,
                    state.searchTerm,
                    state.sorting
                );

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.RemoveFilter: {
                let filters = state.filters.filter(
                    (filter) => filter.id !== action.id
                );
                logger.log('Filters to save (on remove)', filters);
                saveFilterSet(
                    state.id,
                    filters,
                    state.searchTerm,
                    state.sorting
                );

                return {
                    ...state,
                    filters: filters,
                };
            }
            case EdsTableFilterReducerActions.ClearSavedFilter: {
                searchFilterStore.clear(action.id);
                return {
                    ...state,
                    savedFilters: state.savedFilters.filter(
                        (filter) => filter.id !== action.id
                    ),
                };
            }
            case EdsTableFilterReducerActions.SetSavedFilter: {
                return {
                    ...state,
                    savedFilters: action.payload,
                };
            }
            case EdsTableFilterReducerActions.ApplySavedFilter: {
                return {
                    ...state,
                    savedFilters: state.savedFilters.filter(
                        (set) => set.id !== action.payload.id
                    ),
                    id: action.payload.id,
                    filters: action.payload.filters,
                    searchTerm: action.payload.searchTerm,
                    sorting: action.payload.sorting,
                };
            }
            case EdsTableFilterReducerActions.SetSearchTerm: {
                saveFilterSet(
                    state.id,
                    state.filters,
                    action.payload,
                    state.sorting
                );

                return {
                    ...state,
                    searchTerm: action.payload,
                };
            }
            case EdsTableFilterReducerActions.SetSorting: {
                saveFilterSet(
                    state.id,
                    state.filters,
                    state.searchTerm,
                    action.payload
                );

                return {
                    ...state,
                    sorting: action.payload,
                };
            }
            default: {
                return state;
            }
        }
    };
};
