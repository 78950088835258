import { getAlarm, getClientByUID, getResidenceByUID } from '../../../features';
import axios from '../../../features/utils/axios';
import { redirectPageLoaderError } from '../../../features/utils/loader';

export const pageLoader = async ({ params }) => {
    axios.defaults.headers.common['x-umo-account-id'] = 1;
    try {
        const alarm = await getAlarm(params.alarmId);
        const client = await getClientByUID(alarm.clientUID);
        const residence = await getResidenceByUID(alarm.residenceUID);

        return { alarm, client, residence };
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};
