import { Button, ButtonSet } from '@carbon/react';
import './eds-button.scss';

export function EdsButton(props) {
    return <Button size="sm" className="eds-button" {...props}></Button>;
}

export function EdsButtonSet(props) {
    const getStyle = () => {
        let classes = ['eds-button-set'];
        if (props?.align === 'right') {
            classes.push('right');
        }
        if (props?.inTable) {
            classes.push('in-table');
        }
        return classes.join(' ');
    };

    return <ButtonSet className={getStyle()}>{props.children}</ButtonSet>;
}
