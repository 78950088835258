import { forwardRef, useRef, useState } from 'react';
import { EdsContainerTable } from '../../..';
import { useTranslation } from 'react-i18next';
import {
    DaysOfTheWeek,
    deleteAvailability,
    getAvailability,
    getLogger,
    useModal,
} from '../../../../features';
import UmoAvailabilitiesForm from './umo-availabilities-form';
import { DateTime } from 'luxon';
import _ from 'lodash';

let logger = getLogger('UmoAvailabilitiesTable');

export const UmoAvailabilitiesTable = forwardRef(
    (
        {
            getDataCallback,
            noDataLabel,
            permissions,
            postDataCallback,
            ...props
        },
        { tableRef, gridRef }
    ) => {
        const { t } = useTranslation();
        const formRef = useRef(null);

        const [title] = useState(
            props.title ?? t('f24431ce9f1b8885678b1ed611c4c214', 'Availability')
        );

        const { showDeleteModal, showFormModal } = useModal();

        const parseTime = (date) => {
            return DateTime.fromFormat(date, 'HH:mm').toFormat(
                t('72afaa6e494ed2dfc89864418115d41f', 'h:mm a')
            );
        };

        const showDataModal = (data = {}) => {
            showFormModal({
                newTitle: t(
                    'd24c861153ea59080b130ed07de02ad4',
                    'New availability'
                ),
                editTitle: t(
                    'b61ee90ebd9aafac47e3bc559fa1b391',
                    'Edit availability'
                ),
                formData: data,
                formRef,
                tableRef,
                onSubmitFormResponse: async (response, removeModal) => {
                    if (response) {
                        tableRef.current?.fetchInitData();
                        gridRef.current?.fetchInitData();
                    }
                    removeModal();
                },
                children: (
                    <UmoAvailabilitiesForm
                        postDataCallback={postDataCallback}
                        ref={formRef}
                        initValues={data}
                        hideDefaultSubmit={true}
                    />
                ),
            });
        };

        const getDataRow = async (row) => {
            try {
                if (_.isNil(row.id)) {
                    showDataModal();
                    return;
                }

                let availability = await getAvailability(row.id);
                showDataModal({
                    id: {
                        value: availability.id,
                    },
                    dayOfWeek: {
                        id: availability.dayOfWeek,
                        text: availability.dayOfWeek,
                    },
                    startTime: {
                        value: availability.startTime,
                    },
                    endTime: {
                        value: availability.endTime,
                    },
                });
            } catch (ex) {
                logger.warn('getAvailability UDS Exception:', ex);
            }
        };

        return (
            <EdsContainerTable
                title={title}
                noDataLabel={noDataLabel}
                ref={tableRef}
                headers={[
                    {
                        key: 'dayOfWeek',
                        header: t(
                            '74380f7643bffb75f5099f467fc5e691',
                            'Day of the week'
                        ),
                    },
                    {
                        key: 'startTime',
                        header: t(
                            '0088eafe1887ad21557010cac3b718f3',
                            'Start time'
                        ),
                    },
                    {
                        key: 'endTime',
                        header: t(
                            'ccedb8eada66ee89f600c1e969452072',
                            'End time'
                        ),
                    },
                ]}
                onEditClick={(row) => {
                    getDataRow(row);
                }}
                getDataCallback={getDataCallback}
                mappingCallback={(responseData) => {
                    return {
                        id: responseData.id,
                        dayOfWeek: DaysOfTheWeek[responseData.dayOfWeek]
                            ? t(DaysOfTheWeek[responseData.dayOfWeek])
                            : responseData.dayOfWeek,
                        startTime: parseTime(responseData.startTime),
                        endTime: parseTime(responseData.endTime),
                    };
                }}
                onDeleteClick={(row) => {
                    let data = tableRef.current?.getRowData(row.id);
                    showDeleteModal({
                        name: `${data.dayOfWeek} - (${data.startTime} - ${data.endTime})`,
                        tableRef,
                        row,
                        deleteHandler: async () => {
                            const repsonse = await deleteAvailability(row.id);
                            gridRef.current?.fetchInitData();
                            return repsonse;
                        },
                    });
                }}
                onHeaderAddClick={() => showDataModal()}
                permissions={permissions}
            />
        );
    }
);
UmoAvailabilitiesTable.displayName = 'UmoAvailabilitiesTable';
