import { Navigate } from 'react-router-dom';
import {
    AlarmReadPermissionSet,
    getLogger,
    usePermissions,
} from '../../features';
import _ from 'lodash';
import QueuePageRoutes from './queue-page';
import AlarmPageRoutes from './alarm-detail-page';

const logger = getLogger('AlarmRoutes');

const AlarmIndexPage = () => {
    const { isAllowedAnyAccount } = usePermissions();
    const mapping = [
        { path: QueuePageRoutes.path, permission: AlarmReadPermissionSet },
    ];
    let route = mapping.find((item) => isAllowedAnyAccount(item.permission));
    if (!_.isUndefined(route)) {
        logger.log('Navigate to first available route', route.path);
        return <Navigate to={route.path} relative={true} replace={true} />;
    }
    return <Navigate to={'/account'} />;
};

const AlarmRoutes = {
    path: '',
    children: [
        {
            index: true,
            element: <AlarmIndexPage />,
        },
        QueuePageRoutes,
        AlarmPageRoutes,
    ],
};

export default AlarmRoutes;
