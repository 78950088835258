import { forwardRef } from 'react';
import {
    EdsDatePicker,
    EdsDatePickerType,
    EdsDropdown,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
} from '../../../eds';
import { useTranslation } from 'react-i18next';
import {
    useNotifications,
    putAvailability,
    setEmptyFieldToNull,
    getTimeStringFromJSDate,
    DaysOfTheWeek,
} from '../../../../features';
import { Row } from '@carbon/react';

const UmoAvailabilitiesForm = forwardRef((props, ref) => {
    const { showSuccess, showError } = useNotifications();
    const { t } = useTranslation();

    const formDefinition = {
        dayOfWeek: {
            validation: {
                type: 'dropdown',
                required: true,
            },
            mapping: 'id',
        },
        startTime: {
            validation: {
                required: true,
            },
        },
        endTime: {
            validation: {
                required: true,
            },
        },
    };

    const saveData = async (form) => {
        const postData = setEmptyFieldToNull(form);
        postData.startTime = getTimeStringFromJSDate(postData.startTime);
        postData.endTime = getTimeStringFromJSDate(postData.endTime);

        if (!form.id) {
            postData.daysOfWeek = postData.dayOfWeek;
            return await props.postDataCallback(postData);
        } else {
            return await putAvailability(postData);
        }
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        try {
            const response = await saveData(form);
            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '2979fd13338245b2f08adcf5f9a3f440',
                    'Availability is saved',
                    {
                        entity: t(
                            'f24431ce9f1b8885678b1ed611c4c214',
                            'Availability'
                        ),
                        name: response?.absenceReason?.name,
                    }
                ),
            });

            return response;
        } catch (error) {
            if (error?.code === 'ERR_BAD_REQUEST') {
                if (
                    error?.response?.data?.detail ===
                    'The availability overlaps existing values!'
                ) {
                    showError({
                        title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                        content: t(
                            '9b262c2cd021e764b8faff9883b12d83',
                            'The availability overlaps existing values!'
                        ),
                    });

                    throw error;
                }
            }

            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            name={'dayOfWeek'}
                            label={t(
                                '74380f7643bffb75f5099f467fc5e691',
                                'Day of the week'
                            )}
                            getDataCallback={() => {
                                return Object.entries(DaysOfTheWeek).map(
                                    (day) => {
                                        return {
                                            id: day[0],
                                            name: t(day[1]),
                                        };
                                    }
                                );
                            }}
                        />
                    </EdsFormColumn>
                </Row>

                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsDatePicker
                            type={EdsDatePickerType.TimeRange}
                            fromName="startTime"
                            fromLabel={t(
                                'd98a07f84921b24ee30f86fd8cd85c3c',
                                'From'
                            )}
                            toName="endTime"
                            toLabel={t(
                                '01b6e20344b68835c5ed1ddedf20d531',
                                'To'
                            )}
                        />
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});
UmoAvailabilitiesForm.displayName = 'AvailabilitiesForm';

export default UmoAvailabilitiesForm;
