import _ from 'lodash';
import * as Sentry from '@sentry/react';
import { getLogger } from './logger';
import { EdsButton, EdsErrorPage } from '../../components';
import { useTranslation } from 'react-i18next';

const logger = getLogger('ErrorBoundary');

export const ErrorBoundary = ({ children }) => {
    const { t } = useTranslation();
    return (
        <Sentry.ErrorBoundary
            fallback={({ error, eventId }) => {
                logger.log('Error', error);
                if (
                    error instanceof TypeError &&
                    _.startsWith(
                        error?.message,
                        'Failed to fetch dynamically imported module'
                    )
                ) {
                    return (
                        <EdsErrorPage
                            title={t(
                                '1b8d235c428de8a388cdbd73f9c62d28',
                                'Unable to load page'
                            )}
                            text={t(
                                'afa9e0d59b4b2ef530b9daf037a1a7a8',
                                'The page could not be loaded, please refresh the page to try again.'
                            )}
                            errorId={eventId}
                        >
                            <EdsButton
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                {t(
                                    '03b62516184fb6ef591f45bd4974b753',
                                    'Refresh'
                                )}
                            </EdsButton>
                        </EdsErrorPage>
                    );
                }
                return (
                    <EdsErrorPage title={error.toString()} errorId={eventId} />
                );
            }}
        >
            {children}
        </Sentry.ErrorBoundary>
    );
};
