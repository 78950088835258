import { validate as uuidValidate } from 'uuid';
import { getClient, getClientByUID } from '../../../../features';
import { redirectPageLoaderError } from '../../../../features/utils/loader';

export const pageLoader = async ({ params }) => {
    try {
        if (uuidValidate(params.clientId)) {
            return await getClientByUID(params.clientId);
        } else {
            return await getClient(params.clientId);
        }
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};
