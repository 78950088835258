import { getCountry, getRegion } from '../../../../../features';
import { redirectPageLoaderError } from '../../../../../features/utils/loader';

export const pageLoader = async ({ params }) => {
    try {
        return await getCountry(params.countryId);
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};

export const cityPageLoader = async ({ params }) => {
    try {
        return await getRegion(params.regionId);
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};
