import { useRef, useState } from 'react';
import { EdsContainerTable } from '../../..';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
    deleteExternalIdentifier,
    getExternalIdentifier,
    getLogger,
    useModal,
} from '../../../../features';
import UmoExternalIdentifiersForm from './umo-external-identifiers-form';

let logger = getLogger('UmoLinkedExternalIdentifiersTable');

export const UmoLinkedExternalIdentifiersTable = ({
    getDataCallback,
    ...props
}) => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const tableRef = useRef(null);
    const { showDeleteModal, showFormModal } = useModal();
    const [title] = useState(
        props.title ??
            t('49bfd335f8060428d2fe6815635bde6a', 'External identifiers')
    );

    const [hideWhenNoData] = useState(props.hideWhenNoData ?? true);
    const [hasData, setHasData] = useState();

    const getData = async () => {
        let data = await getDataCallback();
        setHasData(!_.isEmpty(data));
        return data;
    };

    if (
        hideWhenNoData &&
        !_.isUndefined(hasData) &&
        _.isBoolean(hasData) &&
        !hasData
    ) {
        return null;
    }

    const mappingCallback = (responseData) => {
        return {
            id: responseData.id,
            sortIndex: responseData.sortIndex,
            type: responseData?.externalIdentifierType?.description,
            value: responseData.value,
            validationMethod:
                responseData?.externalIdentifierType?.validationMethod
                    ?.description,
        };
    };

    const showDataModal = (externalIdentifier = {}) => {
        showFormModal({
            newTitle: t(
                'ddee6d2ac25a992dab9ddb42032ed3d5',
                'New external identifier'
            ),
            editTitle: t(
                '8c36197ef3d974790300e3d1a892f7f6',
                'Edit external identifier'
            ),
            formData: externalIdentifier,
            formRef,
            tableRef,
            onSubmitFormResponse: async (response, removeModal) => {
                if (response) {
                    tableRef.current?.fetchInitData();
                }
                removeModal();
            },
            children: (
                <UmoExternalIdentifiersForm
                    postDataCallback={props.postDataCallback}
                    ref={formRef}
                    initValues={externalIdentifier}
                    hideDefaultSubmit={true}
                ></UmoExternalIdentifiersForm>
            ),
        });
    };

    const getDataRow = async (row) => {
        try {
            let externalIdentifier = await getExternalIdentifier(row.id);
            showDataModal({
                id: {
                    value: externalIdentifier.id,
                },
                sortIndex: {
                    value: externalIdentifier.sortIndex,
                },
                externalIdentifierTypeId: {
                    id: externalIdentifier.externalIdentifierType.id,
                    text: externalIdentifier.externalIdentifierType.description,
                    validationMethodDescription:
                        externalIdentifier?.externalIdentifierType
                            ?.validationMethod?.description ?? '',
                },
                value: {
                    value: externalIdentifier.value,
                },
            });
        } catch (ex) {
            logger.warn('getExternalIdentifier UDS Exception:', ex);
        }
    };
    return (
        <EdsContainerTable
            hidden={_.isUndefined(hasData)}
            title={title}
            ref={tableRef}
            headers={[
                {
                    key: 'sortIndex',
                    header: '#',
                    width: '100px',
                },
                {
                    key: 'type',
                    header: t('599dcce2998a6b40b1e38e8c6006cb0a', 'Type'),
                },
                {
                    key: 'value',
                    header: t('2063c1608d6e0baf80249c42e2be5804', 'Value'),
                },
                {
                    key: 'validationMethod',
                    header: t(
                        '859ada64eceea53401403388f935a67e',
                        'Validation method'
                    ),
                },
            ]}
            getDataCallback={getData}
            mappingCallback={mappingCallback}
            onEditClick={(row) => {
                getDataRow(row);
            }}
            onDeleteClick={(row) => {
                const value = tableRef.current?.getColumnValueString(
                    row.id,
                    'value'
                );
                showDeleteModal({
                    name: value,
                    row,
                    deleteHandler: async () => {
                        await deleteExternalIdentifier(row.id);
                    },
                });
            }}
            onHeaderAddClick={() => showDataModal()}
            {...props}
        />
    );
};
