import _ from 'lodash';
import { EdsTableFilterType } from '../../components';
import buildQuery from 'odata-query';
import { getDateRangeFrom, getDateRangeTo, getDateTime } from './date';

export const addFilterQuery = (params, filters, availableFilters) => {
    if (_.isEmpty(filters)) {
        return params;
    }

    let odata = filters
        .map((filter) => {
            let originalFilter = availableFilters.find(
                (f) => f.id === filter.column
            );
            if (_.isFunction(originalFilter?.customFilterFormatter)) {
                return originalFilter.customFilterFormatter(filter.value);
            }

            if (_.isFunction(originalFilter?.customQueryParamsFormatter)) {
                params = originalFilter.customQueryParamsFormatter(
                    params,
                    filter.value
                );
                return;
            }

            switch (filter.type) {
                case EdsTableFilterType.YesNo:
                    return {
                        [filter.column]: {
                            eq: filter.value,
                        },
                    };
                case EdsTableFilterType.Date: {
                    if (filter.operator === 'empty') {
                        return {
                            [filter.column]: {
                                eq: null,
                            },
                        };
                    }

                    let dateFrom = getDateRangeFrom(filter.value);
                    let dateTo = getDateRangeTo(filter.value);

                    return {
                        [filter.column]: {
                            ge: {
                                type: 'raw',
                                value: dateFrom,
                            },
                            le: {
                                type: 'raw',
                                value: dateTo,
                            },
                        },
                    };
                }
                case EdsTableFilterType.DateRange: {
                    let dateFrom = getDateRangeFrom(filter.value?.from);
                    let dateTo = getDateRangeTo(filter.value?.to);

                    return {
                        [filter.column]: {
                            ge: {
                                type: 'raw',
                                value: dateFrom,
                            },
                            le: {
                                type: 'raw',
                                value: dateTo,
                            },
                        },
                    };
                }
                case EdsTableFilterType.DateTimeRange: {
                    let dateFrom = getDateTime(filter.value?.from);
                    let dateTo = getDateTime(filter.value?.to);

                    return {
                        [filter.column]: {
                            ge: {
                                type: 'raw',
                                value: dateFrom,
                            },
                            le: {
                                type: 'raw',
                                value: dateTo,
                            },
                        },
                    };
                }
                case EdsTableFilterType.Text:
                case EdsTableFilterType.Email:
                case EdsTableFilterType.PhoneNumber:
                case EdsTableFilterType.Number: {
                    if (filter.operator === 'empty') {
                        return {
                            [filter.column]: {
                                eq: null,
                            },
                        };
                    }
                    let column = filter.column;
                    let value = filter.value;
                    if (
                        !_.isUndefined(originalFilter.caseSensitive) &&
                        !originalFilter.caseSensitive
                    ) {
                        column = `tolower(${filter.column})`;
                        value = _.toLower(filter.value);
                    }

                    return {
                        [column]: {
                            [filter.operator === 'eq' ? 'eq' : 'ne']: value,
                        },
                    };
                }
                case EdsTableFilterType.Dropdown:
                    if (filter.operator === 'empty') {
                        return {
                            [filter.column]: {
                                eq: null,
                            },
                        };
                    }
                    return {
                        [filter.column]: {
                            [filter.operator === 'eq' ? 'eq' : 'ne']:
                                filter.value,
                        },
                    };
                case EdsTableFilterType.MultiSelect:
                    return {
                        [filter.column]: {
                            in: filter.value,
                        },
                    };
                case EdsTableFilterType.Range:
                    return {
                        [filter.column]: {
                            ge: filter.value.from,
                            le: filter.value.to,
                        },
                    };
                case EdsTableFilterType.Location: {
                    let column = filter.column;
                    let value;
                    if (filter.value.district) {
                        column += 'districtId';
                        value = filter.value.district;
                    } else if (filter.value.city) {
                        column += 'cityId';
                        value = filter.value.city;
                    } else if (filter.value.region) {
                        column += 'regionId';
                        value = filter.value.region;
                    } else if (filter.value.country) {
                        column += 'countryId';
                        value = filter.value.country;
                    } else {
                        return;
                    }
                    return {
                        [column]: {
                            ['eq']: value,
                        },
                    };
                }
                case EdsTableFilterType.Group: {
                    let column = filter.column;
                    let value;
                    if (filter.value.subproject) {
                        column += 'subprojectId';
                        value = filter.value.subproject;
                    } else if (filter.value.project) {
                        column += 'projectId';
                        value = filter.value.project;
                    } else if (filter.value.subgroup) {
                        column += 'subgroupId';
                        value = filter.value.subgroup;
                    } else if (filter.value.group) {
                        column += 'groupId';
                        value = filter.value.group;
                    } else {
                        return;
                    }
                    return {
                        [column]: {
                            ['eq']: value,
                        },
                    };
                }
                case EdsTableFilterType.AlarmCriteria: {
                    let filters = [];
                    if (filter.value.alarm) {
                        filters.push({
                            criterion: { ge: 100, le: 199 },
                        });
                    }
                    if (filter.value.technicalAlarm) {
                        filters.push({
                            criterion: { ge: 200, le: 899 },
                        });
                    }
                    if (!_.isEmpty(filter.value.custom)) {
                        filter.value.custom.map((range) => {
                            filters.push({
                                criterion: {
                                    ge: Number(range.from),
                                    le: Number(range.to),
                                },
                            });
                        });
                    }
                    if (!_.isEmpty(filter.value.specific)) {
                        filters.push({
                            criterion: { in: filter.value.specific },
                        });
                    }
                    return {
                        or: filters,
                    };
                }
            }
        })
        .filter((item) => item !== undefined);
    let query = buildQuery({ filter: { and: odata } }).replace('?$filter=', '');
    if (!_.isEmpty(query)) {
        return { ...params, $filter: query };
    }
    return params;
};
