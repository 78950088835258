import { forwardRef } from 'react';
import { EdsContainer } from '../eds-container/eds-container';
import { EdsTable } from '../eds-table/eds-table';

export const EdsContainerTable = forwardRef(
    (
        {
            enableContainer = true,
            children,
            customContainerButtons = [],
            ...props
        },
        ref
    ) => {
        if (enableContainer) {
            const containerizedProps = {
                ...props,
                size: 'md',
                emptyWithImage: false,
                enableSearchParams: false,
            };

            return (
                <EdsContainer
                    withPadding={false}
                    {...props}
                    customContainerButtons={customContainerButtons}
                >
                    <EdsTable ref={ref} {...containerizedProps}>
                        {children}
                    </EdsTable>
                </EdsContainer>
            );
        } else {
            return (
                <EdsTable ref={ref} {...props}>
                    {children}
                </EdsTable>
            );
        }
    }
);
EdsContainerTable.displayName = 'EdsContainerTable';
