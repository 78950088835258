import { Outlet } from 'react-router-dom';
import { AlarmReadPermissionSet, Restricted } from '../../../features';
import QueuePage from './queue-page';

const QueuePageRoutes = {
    path: 'queue',
    element: (
        <Restricted toAnyAccount={AlarmReadPermissionSet} redirect={true}>
            <Outlet />
        </Restricted>
    ),
    children: [
        {
            index: true,
            element: <QueuePage />,
        },
    ],
};

export default QueuePageRoutes;
