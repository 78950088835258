import { getAlarmReasonCategory } from '../../../../../features';
import { redirectPageLoaderError } from '../../../../../features/utils/loader';

export const pageLoader = async ({ params }) => {
    try {
        return await getAlarmReasonCategory(params.alarmReasonCategoryId);
    } catch (ex) {
        return redirectPageLoaderError(ex);
    }
};
