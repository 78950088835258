import { Link, useMatches } from 'react-router-dom';
import './eds-breadcrumb.scss';
import { Breadcrumb, BreadcrumbItem } from '@carbon/react';
import { useTranslation } from 'react-i18next';

export function EdsBreadcrumb() {
    const { t } = useTranslation();
    const matches = useMatches();

    const crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => {
            return {
                title: match.handle.crumb(t, match.data),
                pathname: match.pathname,
                routable: match?.handle?.routable ?? true,
            };
        });

    return (
        <div className="eds-breadcrumb">
            <Breadcrumb noTrailingSlash={true}>
                {crumbs.map((crumb, i) => {
                    const title = `${crumb.title}`; //title must be a string
                    if (i >= crumbs.length - 1) {
                        return (
                            <BreadcrumbItem key={i} isCurrentPage={true}>
                                {title}
                            </BreadcrumbItem>
                        );
                    }

                    return (
                        <BreadcrumbItem key={i} isCurrentPage={!crumb.routable}>
                            {crumb.routable ? (
                                <Link to={crumb.pathname}>{title}</Link>
                            ) : (
                                title
                            )}
                        </BreadcrumbItem>
                    );
                })}
            </Breadcrumb>
        </div>
    );
}
