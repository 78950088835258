import { getRequest } from '../api';

const basePath = '/alarm-locations';

export const getAlarmLocations = async () => {
    return getRequest(basePath);
};

export const getAlarmLocation = async (id) => {
    return getRequest(`${basePath}/${id}`);
};

export const getAlarmLocationByNumber = async (number) => {
    return getRequest(`${basePath}/by-number/${number}`);
};
