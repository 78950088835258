import { useTranslation } from 'react-i18next';
import {
    CareProviderCreatePermissionSet,
    getCareTeamSearch,
} from '../../../../features';
import {
    EdsOptionalLink,
    EdsSortDirection,
    EdsTable,
    EdsTableCheckmark,
    EdsTableFilterType,
} from '../../../eds';
import _ from 'lodash';
import { searchFilterStore } from '../../../../features/uds/care-team';

export const UmoSearchCareTeamTable = ({ baseFilter, ...props }) => {
    const { t } = useTranslation();

    const addBaseFilter = (filter) => {
        const filterParts = [];

        if (!_.isUndefined(filter)) {
            filterParts.push(filter);
        }

        if (baseFilter) {
            filterParts.push(baseFilter);
        }

        if (_.isEmpty(filterParts)) {
            return undefined;
        }

        return filterParts.join(' and ');
    };

    const getDataCallback = async ({ $filter, ...params }) => {
        return getCareTeamSearch({
            ...params,
            $filter: addBaseFilter($filter),
        });
    };

    const mappingCallback = (data) => {
        return {
            id: data.id,
            name: (
                <EdsOptionalLink
                    id={data.id}
                    to={`/crm/care-team/${data.id}`}
                    label={data.name}
                />
            ),
            address: data.combinedAddressField,
            email: data.emailAddress,
            remarks: data.remarks,
            isDisabled: <EdsTableCheckmark value={data.disabled} />,
        };
    };

    const headers = [
        {
            key: 'name',
            header: t('847cd922be17fd9b49c82ac7153aedb0', 'Care team name'),
            sort: 'name',
            isDefaultSort: EdsSortDirection.Ascending,
        },
        {
            key: 'address',
            header: t('884d9804999fc47a3c2694e49ad2536a', 'Address'),
            sort: 'address/streetName,address/houseNumber,address/city/name,address/postalCode',
        },
        {
            key: 'email',
            header: t('0c83f57c786a0b4a39efab23731c7ebc', 'Email'),
            sort: 'emailAddress',
        },
        {
            key: 'remarks',
            header: t('911c185c8f3c475cdca2ef9cf12166da', 'Remark'),
            sort: 'remarks',
        },
        {
            key: 'isDisabled',
            header: t('0d111f56964985f562aaaaf9d2980787', 'Archived'),
            sort: 'disabled',
        },
    ];

    return (
        <EdsTable
            searchFilterStore={searchFilterStore}
            searchTooltipKeywords={[
                t('847cd922be17fd9b49c82ac7153aedb0', 'Care team name'),
                t('9b88e58612797d6b989681a16621ad63', 'Phone number'),
            ]}
            availableFilters={[
                {
                    id: 'name',
                    name: t('b068931cc450442b63f5b3d276ea4297', 'Name'),
                    type: EdsTableFilterType.Text,
                    caseSensitive: false,
                },
                {
                    id: 'emailAddress',
                    name: t('0c83f57c786a0b4a39efab23731c7ebc', 'Email'),
                    type: EdsTableFilterType.Email,
                    caseSensitive: false,
                },
                {
                    id: 'disabled',
                    name: t('0d111f56964985f562aaaaf9d2980787', 'Archived'),
                    type: EdsTableFilterType.YesNo,
                },
                {
                    id: 'address/',
                    name: t('d5189de027922f81005951e6efe0efd5', 'Location'),
                    type: EdsTableFilterType.Location,
                },
                {
                    id: 'address/streetName',
                    name: t('1f4a9685e37e4f940d07a9f6b43dc83c', 'Street'),
                    type: EdsTableFilterType.Text,
                    caseSensitive: false,
                },
                {
                    id: 'address/houseNumber',
                    name: t('61a0a33cf99a58d9cad0d5ed6deae269', 'House number'),
                    type: EdsTableFilterType.Text,
                },
                {
                    id: 'address/postalCode',
                    name: t('c8a03a9875d7fc4c023e1bebb23d07af', 'Postal code'),
                    type: EdsTableFilterType.Text,
                },
                // TODO UMO-607 Tags filter
            ]}
            headers={headers}
            searchClientSide={false}
            getDataCallback={getDataCallback}
            mappingCallback={mappingCallback}
            permissions={{
                new: CareProviderCreatePermissionSet,
            }}
            {...props}
        ></EdsTable>
    );
};
