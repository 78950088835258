import { DismissibleTag } from '@carbon/react';
import './eds-filter-tag.scss';

export function EdsFilterTag({ onClick, ...props }) {
    return (
        <span onClick={onClick}>
            <DismissibleTag
                {...props}
                className="eds-filter-tag"
                type="blue"
                title={props.text ?? ''}
            />
        </span>
    );
}
