import { RadioButton } from '@carbon/react';
import { useForm, useFormGroup } from '../eds-form';
import _ from 'lodash';

export function EdsRadioButton(props) {
    const { name, value, labelText, disabled } = props;
    const { handleFormChange, getFormValue } = useForm();
    const { prefix, usePrefix } = useFormGroup();
    let prefixedName = usePrefix(name) ?? name;

    const getValue = () => {
        let defaultReturnValue = false;
        let returnValue = defaultReturnValue;
        const formValue = getFormValue(name, prefix);

        if (!_.isUndefined(formValue)) {
            if (_.isPlainObject(formValue) && 'value' in formValue) {
                returnValue = formValue === value[value];
            } else {
                returnValue = formValue === value;
            }

            if (!_.isBoolean(returnValue)) {
                returnValue = defaultReturnValue;
            }
        }
        return returnValue;
    };

    const handleOnChange = (value, name, event) => {
        return handleFormChange(event);
    };

    return (
        <RadioButton
            className="eds-radio-button"
            id={value}
            name={prefixedName}
            checked={getValue(name, prefix)}
            value={value}
            labelText={labelText}
            onChange={handleOnChange}
            disabled={disabled ?? false}
        ></RadioButton>
    );
}
