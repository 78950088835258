import { forwardRef } from 'react';
import {
    EdsDatePicker,
    EdsDatePickerType,
    EdsDropdown,
    EdsForm,
    EdsFormColumn,
    EdsFormGroup,
    EdsTextArea,
} from '../../../eds';
import { useTranslation } from 'react-i18next';
import {
    getDate,
    useNotifications,
    getAbsenceReasons,
    putHolidayLink,
} from '../../../../features';
import { Row } from '@carbon/react';

const UmoHolidayLinksForm = forwardRef((props, ref) => {
    const { showSuccess, showError } = useNotifications();
    const { t } = useTranslation();

    const formDefinition = {
        fromDate: {
            value: getDate(),
            validation: {
                type: 'datepicker',
                required: true,
            },
        },
        toDate: {
            value: getDate({ plusDays: 1 }),
            validation: {
                type: 'datepicker',
                required: true,
            },
        },
        absenceReasonId: {
            validation: {
                type: 'dropdown',
                required: true,
            },
            mapping: 'id',
        },
        description: {
            validation: {
                required: false,
            },
        },
    };

    const saveData = async (form) => {
        if (!form.id) {
            return await props.postDataCallback(form);
        } else {
            return await putHolidayLink(form);
        }
    };

    const onSubmit = async (_event, form, isValid) => {
        if (!isValid) {
            throw false;
        }
        try {
            const response = await saveData(form);
            showSuccess({
                title: t('536a056b710e94b16169efd17a4a657b', 'Saved'),
                content: t(
                    '281aef713c8dc9007cabb1e65448917d',
                    "{{entity}} '{{name}}' is saved",
                    {
                        entity: t(
                            '351be24aa3154abeb916761294b6f8ad',
                            'Absence'
                        ),
                        name: response?.absenceReason?.name,
                    }
                ),
            });

            return response;
        } catch (error) {
            showError({
                title: t('cb5e100e5a9a3e7f6d1fd97512215282', 'Error'),
                content: t(
                    'df0a9d46baf7315909e4389a04786e3d',
                    'Oops something went wrong'
                ),
            });

            throw error;
        }
    };

    return (
        <EdsForm
            hideDefaultSubmit={props.hideDefaultSubmit}
            initValues={props.initValues}
            formDefinition={formDefinition}
            onSubmit={onSubmit}
            ref={ref}
        >
            <EdsFormGroup>
                <Row>
                    <EdsFormColumn>
                        <EdsDropdown
                            name="absenceReasonId"
                            label={t(
                                '40bea8d637cdf2c1b07fcf0630482b73',
                                'Reason'
                            )}
                            getDataCallback={() =>
                                getAbsenceReasons({
                                    isActive: true,
                                })
                            }
                        />
                    </EdsFormColumn>
                </Row>

                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsDatePicker
                            type={EdsDatePickerType.DateRange}
                            fromName="fromDate"
                            fromLabel={t(
                                'd98a07f84921b24ee30f86fd8cd85c3c',
                                'From'
                            )}
                            toName="toDate"
                            toLabel={t(
                                '01b6e20344b68835c5ed1ddedf20d531',
                                'To'
                            )}
                        />
                    </EdsFormColumn>
                </Row>

                <Row>
                    <EdsFormColumn fullWidth>
                        <EdsTextArea
                            name="description"
                            label={t(
                                '67daf92c833c41c95db874e18fcb2786',
                                'Description'
                            )}
                            rows={10}
                        ></EdsTextArea>
                    </EdsFormColumn>
                </Row>
            </EdsFormGroup>
        </EdsForm>
    );
});
UmoHolidayLinksForm.displayName = 'HolidayLinksForm';

export default UmoHolidayLinksForm;
