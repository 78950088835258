import { Navigate } from 'react-router-dom';
import { getLogger, useAuth } from '../features';
import { getRefererLoginUrl } from '../features/utils/referer-redirect';

const logger = getLogger('ProtectedRoute');

export const ProtectedRoute = ({ children }) => {
    const { isAuthenticated, isTenantSelected } = useAuth();
    logger.log('isAuthenticated', isAuthenticated);
    if (!isAuthenticated) {
        // user is not authenticated
        return <Navigate to={getRefererLoginUrl()} />;
    }
    if (!isTenantSelected) {
        return <Navigate to="/auth/select-tenant" />;
    }
    return children;
};

export default ProtectedRoute;
