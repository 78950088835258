import axios from '../utils/axios';
import { estimatedRemainingUploadTime, getLogger } from '../utils';
import { flattenObjectWithDivider } from '../../features';

const logger = getLogger('UDS');

const createPath = (path) => {
    if (path.startsWith('/api')) {
        return path;
    }
    return `/api${path.startsWith('/') ? path : `/${path}`}`;
};

const formatPatchRequest = (data) => {
    const flattendValues = flattenObjectWithDivider(data);
    const returnData = Object.keys(flattendValues).map((key) => {
        return {
            op: 'replace',
            path: key,
            value: flattendValues[key],
        };
    });
    return { data: returnData };
};

export const patchRequest = async (path, data) => {
    path = createPath(path);

    const patchData = formatPatchRequest(data);
    logger.log('PATCH Request:', path, patchData);
    try {
        let response = await axios.patch(path, patchData);
        return handleResponse(response);
    } catch (ex) {
        return handleError(ex);
    }
};

export const putRequest = async (path, data) => {
    path = createPath(path);
    logger.log('PUT Request:', path, data);

    try {
        let response = await axios.put(path, data);
        return handleResponse(response);
    } catch (ex) {
        return handleError(ex);
    }
};

export const postRequest = async (path, data, params = undefined) => {
    path = createPath(path);
    logger.log('POST Request:', path, data, params);

    try {
        let response = await axios.post(path, data, { params: params });
        return handleResponse(response);
    } catch (ex) {
        return handleError(ex);
    }
};

export const getRequest = async (path, params) => {
    path = createPath(path);
    logger.log('GET Request:', path, params);

    try {
        let response = await axios.get(path, {
            params: params,
        });
        return handleResponse(response);
    } catch (ex) {
        return handleError(ex);
    }
};

export const deleteRequest = async (path, params = {}) => {
    path = createPath(path);
    logger.log('DELETE Request:', path, params);

    try {
        await axios.delete(path, {
            params: params,
        });
        return;
    } catch (ex) {
        return handleError(ex);
    }
};

export const multipartPostRequest = async (
    path,
    form,
    customHeaders,
    cbProgress,
    abortController
) => {
    path = createPath(path);
    logger.log('MULTIPART POST Request:', path);

    const startTime = new Date().valueOf();

    const config = {
        signal: abortController.signal,
        timeout: 1800000, //30mins
        onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
            );
            const remainingTime = estimatedRemainingUploadTime(
                startTime,
                progressEvent.loaded,
                progressEvent.total
            );
            cbProgress(percentCompleted, remainingTime);
        }.bind(this),
        headers: {
            ...customHeaders,
        },
    };

    try {
        let response = await axios.post(path, form, config);
        return handleResponse(response);
    } catch (ex) {
        return handleError(ex);
    }
};

const handleResponse = (response) => {
    let body = response.data;
    logger.log('Response:', body);
    return body;
};

const handleError = (error) => {
    logger.log('Exception:', error);
    logger.log('response', error?.response?.status);
    throw error;
};

export const createStateManagementFunctions = (path, name) => {
    path = `${path}/saved-state`;
    return {
        name: () => name,
        save: async (state) => {
            return postRequest(path, state);
        },
        clear: async () => {
            return deleteRequest(path);
        },
        get: async () => {
            return getRequest(path);
        },
    };
};

export const createSearchFilterStoreFunctions = (path, name) => {
    path = `${path}/search-filters`;
    return {
        name: () => name,
        save: async (state) => {
            return postRequest(path, state);
        },
        clear: async (filter_id) => {
            return deleteRequest(path, { id: filter_id });
        },
        get: async () => {
            return getRequest(path);
        },
    };
};
