import { ProgressIndicator, ProgressStep, Column, Grid } from '@carbon/react';
import './eds-wizard.scss';

import { EdsButton, EdsButtonSet, EdsForm, useWizard } from '../';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../../features';

import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import EdsWizardProvider from './eds-wizard-provider';
import _ from 'lodash';

export const EdsWizardOrientation = Object.freeze({
    Vertical: 'vertical',
    Horizontal: 'horizontal',
});

const EdsWizardContent = forwardRef(
    ({ orientation = EdsWizardOrientation.Vertical, ...props }, ref) => {
        const { formRef, wizardRef } = ref;
        const formChangeTimeoutId = useRef(null);
        const {
            selectedIndex,
            steps,
            numberOfSteps,
            changeIndex,
            previousStep,
            nextStep,
            stepDisabledState,
            addSteps,
            saveState,
            cancelWizard,
            hasStatemanagement,
        } = useWizard();

        useEffect(() => {
            addSteps(props.steps);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [props.steps]);

        useImperativeHandle(wizardRef, () => ({}));

        const getProgressIndicator = () => {
            return (
                <ProgressIndicator
                    vertical={orientation === EdsWizardOrientation.Vertical}
                    currentIndex={selectedIndex}
                    spaceEqually={true}
                    onChange={changeIndex}
                >
                    {steps.map((step, index) => (
                        <ProgressStep
                            label={step.label}
                            key={index}
                            secondaryLabel={step.secondaryLabel}
                            disabled={stepDisabledState(index)}
                        />
                    ))}
                </ProgressIndicator>
            );
        };

        const getWidthColumns = () => {
            if (orientation === EdsWizardOrientation.Vertical) {
                return {
                    sm: 3,
                    md: 5,
                    lg: 12,
                };
            } else {
                return {
                    sm: 4,
                    md: 8,
                    lg: 16,
                };
            }
        };

        const getStyle = () => {
            let classes = ['eds-wizard'];

            classes.push(orientation);

            return classes.join(' ');
        };

        return (
            <div className={getStyle()}>
                <Grid fullWidth>
                    <Column className="content" {...getWidthColumns()}>
                        <>
                            {orientation === EdsWizardOrientation.Horizontal &&
                                numberOfSteps() > 1 &&
                                getProgressIndicator()}
                            <EdsForm
                                hideDefaultSubmit={true}
                                initValues={props.initValues}
                                ref={formRef}
                                onSubmit={props.onSubmit}
                                onFormChange={() =>
                                    debounce(formChangeTimeoutId, saveState, {
                                        trailing: true,
                                    })
                                }
                            >
                                {props.children}
                                <EdsWizardButtons
                                    maxSteps={numberOfSteps}
                                    onCancelClick={() => {
                                        if (hasStatemanagement()) {
                                            cancelWizard();
                                            return;
                                        }

                                        if (_.isFunction(props.onCancelClick)) {
                                            props.onCancelClick();
                                        }
                                    }}
                                    onPrevClick={previousStep}
                                    onNextClick={nextStep}
                                />
                            </EdsForm>
                        </>
                    </Column>

                    {orientation === EdsWizardOrientation.Vertical && (
                        <Column className="content" sm={1} md={3} lg={4}>
                            {getProgressIndicator()}
                        </Column>
                    )}
                </Grid>
            </div>
        );
    }
);
EdsWizardContent.displayName = 'EdsWizardContent';

export const EdsWizard = forwardRef((props, ref) => {
    return (
        <EdsWizardProvider {...props} ref={ref}>
            <EdsWizardContent {...props} ref={ref} />
        </EdsWizardProvider>
    );
});
EdsWizard.displayName = 'EdsWizard';

function EdsWizardButtons(props) {
    const { t } = useTranslation();
    const { selectedIndex, numberOfSteps } = useWizard();
    const maxSteps = numberOfSteps();
    const currentStep = selectedIndex + 1;

    return (
        <div className="eds-wizard-buttons">
            <EdsButtonSet>
                <EdsButton kind="ghost" onClick={props.onCancelClick}>
                    {t('10aec35353f9c4096a71c38654c3d402', 'Cancel')}
                </EdsButton>
                <EdsButtonSet>
                    {currentStep > 1 && (
                        <EdsButton kind="secondary" onClick={props.onPrevClick}>
                            {t('2327346e833efcd6b2e7b3f0a4df8ebb', 'Previous')}
                        </EdsButton>
                    )}
                    <EdsButton kind="primary" onClick={props.onNextClick}>
                        {currentStep >= maxSteps &&
                            t('43781db5c40ecc39fd718685594f0956', 'Save')}
                        {currentStep < maxSteps &&
                            t('d0cab90d8d20d57e2f2b9be52f7dd25d', 'Next')}
                    </EdsButton>
                </EdsButtonSet>
            </EdsButtonSet>
        </div>
    );
}
